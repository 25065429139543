/**********************************************************************************************/
/***** GLOBAL *********************************************************************************/
/**********************************************************************************************/


/* GRID ACCESSORIES */
.grid {
    > div {
        
        &.flush_right {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-right:(-$gutter);
            }
        }
        
        &.flush_left {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-left:(-$gutter);
            }
        }
        
        &.flush_left_right {
            padding-right:0;
            > .cont {
                width:calc(100% + #{$gutter} + #{$gutter});
                margin-left:(-$gutter);
                margin-right:(-$gutter);
            }
        }
        
        > .cont {
            
        }
    }
}

/*@media screen and (min-width: #{$bp_full}) {
    .grid > div.flush_right > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-right: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
    .grid > div.flush_left > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-left: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
}*/

/**********************************************************************************************/
/***** HOME PAGE *******************************************************************************/
/**********************************************************************************************/


/**********************************************************************************************/
/***** ABOUT PAGE *******************************************************************************/
/**********************************************************************************************/


@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}

/**********************************************************************************************/
/***** SERVICES (LANDING, DETAIL) *************************************************************/
/**********************************************************************************************/


/**********************************************************************************************/
/***** INSIGHTS LANDING ***********************************************************************/
/**********************************************************************************************/
#FeaturedInsights {
    position:relative;
    
    .content_section_inner {
        position:relative;
        
        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:50%;
            background:$leafy_green;
            content:'';
        }
    }
    
    .featured {
        display:block;
        position:relative;
        width:100%;
        z-index:10;
        overflow:hidden;
        
        &:last-of-type {
            margin:5em 0 0;
        }
        
        .bg_img {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background-color:$whipped_cream_200;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            transition:all 0.3s;
            z-index:-2;
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            @include background-opacity($leafy_green_400, 0.8);
            z-index:-2;
        }
        
        .inner_content {
            padding:7em 8.705% 4em;
        }
        
        .pixels_wrap {
            z-index:-1;
        }
        
        .eyebrow {
            @extend h6;
            margin:0 0 1.3em;
            color:$k_kelly;
        }
        
        h4 {
            transition:all 0.3s;
        }
        
        .summary {
            color:$whipped_cream;
        }
        
        span.btn {
            &:hover {
                background:$k_kelly !important;
            }
        }
        
        &:hover {
                
            .bg_img {
                position:absolute;
                top:-32px;
                right:-32px;
                bottom:-32px;
                left:-32px;
            }
            
            h4 {
                color:$k_kelly;
            }
            
            span.btn {
                background:$lemon_lime !important;
                
                &:hover {
                    background:$lemon_lime !important;
                }
            }
        }
    }
}

#Insights {
    
    .eyebrow {
        @extend h6;
        margin:0 0 1.3em;
        color:$k_kelly;
    }
    
    .content_section_inner {
        padding-top:30px;
    }
}


@media only screen and (max-width: 540px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    #Insights {
        background: $leafy_green;
    
        .content_section_inner {
            padding-top:60px;
        }
        
        .intro {
            
            h2 {
                margin-top:0;
            }
        }
    }
    
    #FeaturedInsights {
    
        .content_section_inner {
        
            &:after {
                left:-32px;
                height:48%;
                width:calc(100% + 64px);
            }
        }
        
        .featured {
            width:calc(50% - 16px);
        
            &:last-of-type {
                margin:6em 0 0 32px;
            }
        
            .inner_content {
                padding:7em 17.41% 4em;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    #Insights {
        background:none;
    }
    
    #FeaturedInsights {
    
        .content_section_inner {
        
            &:after {
                left:0;
                width:100%;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}


/**********************************************************************************************/
/***** INSIGHTS DETAIL ************************************************************************/
/**********************************************************************************************/
.single-post,
.single-resources,
.single-project,
.single-award,
.single-callout,
.single-whitepaper,
.single-webinar,
.single-podcast,
.single-ebook {

    
    
    
    .featured_img {
        margin:1em 0 0;
        background:$leafy_green_400;
        
        .content_section_inner {
            position:relative;
            padding:0;
        }
        
        .img_wrap {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            height:100%;
            padding:0;
        }
    
        .img {
            position:relative;
            flex:0 0 100%;
            padding:12em 0;
            align-self:stretch;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
        }
        
        .pixels_wrap {
            //z-index:-1;
        }
    }
    
    .byline_block {
        display:flex;
        justify-content:flex-start;
        align-items:flex-end;
        margin-bottom:5em;
        
        .headshot {
            flex:0 0 140px;
            display:block;
            //width:140px;
            //height:140px;
            overflow:hidden;
            margin:0 20px 0 0;
            
            img {
                display:block;
                width:100%;
            }
        }
        
        info {
            flex:0 0 auto;
        }
        
        p {
            margin:0 0 1em;
            
            &.name {
                margin:0 0 0.25em;
                font-size:1em;
            }
            
            &.job_title {
                font-size:0.7em;
                opacity:0.7;
            }
            
            &.desc {
                font-size:0.8em;
                font-weight:$light;
                line-height:1.375em;
                opacity:0.7;
            }
            
            &.date {
                margin:0;
                font-size:0.7em;
            }
            
            > a {
                color:$leafy_green;
                text-decoration:none;
                
                &:hover {
                    color:$k_kelly;
                }
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .single-post,
    .single-resources,
    .single-project,
    .single-award,
    .single-callout,
    .single-whitepaper,
    .single-webinar,
    .single-podcast,
    .single-ebook {
        
        .byline_block {
            display:block;
            margin-bottom:0;
            padding-right:25%;
            padding-left:32px;
            
            .headshot {
                width:68px;
                //height:68px;
                height:auto;
                margin:0 0 0.5em;
            }
            
            .info {
                display:block;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}


/**********************************************************************************************/
/***** WORK/PORTFOLIO LANDING *****************************************************************/
/**********************************************************************************************/
#SuccessStories {
    
    .eyebrow {
        @extend h6;
        margin:0 0 1.3em;
        color:$k_kelly;
    }
    
    .intro {
        
        h2 {
            margin-top:0;
        }
    }
    
    .content_section_inner {
        padding-top:4em;
    }
}

/**********************************************************************************************/
/***** CASE STUDY DETAIL **********************************************************************/
/**********************************************************************************************/
.single-case-study {
    
    
    .featured_img {
        margin:1em 0 0;
        
        .content_section_inner {
            position:relative;
            padding:0;
            z-index:10;
            
            &:before {
                position:absolute;
                left:0;
                right:0;
                bottom:0;
                width:100%;
                height:4em;
                background:$whipped_cream_200;
                content:'';
                z-index:-1;
            }
        }
        
        .bg_cont {
            position:absolute;
            top:15%;
            right:0;
            bottom:0;
            left:0;
            width:87.16% !important; //MATCH PIXEL WIDTH
            z-index:-2;
            
            &:before {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                //width:100%;
                //height:40%;
                background:$leafy_green;
                content:'';
            }
        }
        
        .img_cont {
            padding-top:6em;
            z-index:13;
            
            img {
                display:block;
                width:100%;
            }
        }
        
        .pixels_wrap {
            top:15%;
            bottom:4em;
            z-index:-1;
        }
    }
}

@media only screen and (max-width: 540px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .single-case-study {
    
    
        .featured_img {
            
            .img_cont {
                padding-top:0;
            }
            
            .bg_cont {
                top:30%;
                width:91.49% !important; //MATCH PIXEL WIDTH
            }
        
            .pixels_wrap {
                top:30%;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}


/**********************************************************************************************/
/***** CAREERS PAGE ***************************************************************************/
/**********************************************************************************************/
.page-template-page-careers {
    
    
    #Benefits {
        
        
        .eyebrow {
            color:$k_kelly;
        }
        
        h2 {
            margin:0;
        }
    }
    
    #JoinUs {
        
        
        .content_section_inner {
            padding:3em $mobile_lr_padding 0;
        }
        
        .eyebrow {
            color:$k_kelly;
        }
        
        .grid {
            display:block;
            align-items:flex-end;
            flex-direction:column;
        }
        
        .img {
            /*align-self:stretch;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:left bottom;
            background-size:100% auto;*/
        }
    }
    
    #OpenRoles {
        
        .roles_h {
            margin-bottom:4em;
            padding:0;
            
            h2 {
                margin-top:0;
                line-height:1.9em;
                background:none;
            }
        }
        
        .left_cont {
            padding:0 8.333% 0 0;
        }
        
        .jobs_list {
            margin:0 0 6em;
            padding:0;
            list-style:none;
            
            > li {
                display:flex;
                justify-content:space-between;
                align-items:center;
                padding:2em 0;
                list-style-type:none;
                border-bottom:1px solid rgba(0,0,0,0.8);
                
                &:first-of-type {
                    padding-top:0;
                }
            }
            
            h4 {
                flex:0 0 calc(100% - 120px);
                margin:0;
            }
            
            .apply {
                flex:0 0 auto;
            }
            
            .btn {
                margin:0;
            }
        }
    }
    
    #Inclusion {
        
        .content_section_inner {
            position:relative;
            padding-bottom:0;
        }
        
        .cont_wrap {
            display:block;
            margin-top:3em;
        }
        
        .cont {
            margin-bottom:4em;
            padding:0;
            
            h2,
            h3,
            h4 {
                margin-top:0;
            }
        }
        
        .img {
            position:relative;
            margin-right:-26px;
            margin-left:-26px;
        
            img {
                display:block;
                width:100%;
            }
        }
    
        .pixels_wrap {
            z-index:10;
            
            .pixel.one {
                z-index:11;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .page-template-page-careers {
        
        #JoinUs {
            
            .content_section_inner {
                padding:3em 0 0;
            }
        
            .grid {
                display:flex;
                flex-direction:row;
            }
        }
        
        #OpenRoles {
            
            .content_section_inner {
                padding-bottom:7em;
            }
        
            .roles_h {
                padding:0 6.25% 0 0;
            
                h2 {
                    line-height:1.15em;
                }
            }
        
            .jobs_list {
                margin:0 0 6em;
                padding:0;
                list-style:none;
            
                > li {
                    display:flex;
                    justify-content:space-between;
                    align-items:center;
                    padding:2em 0;
                    list-style-type:none;
                    border-bottom:1px solid rgba(0,0,0,0.8);
                
                    &:first-of-type {
                        padding-top:0;
                    }
                }
            
                h4 {
                    margin:0;
                }
            
                .apply {
                    flex:0 0 auto;
                }
            
                .btn {
                    margin:0;
                }
            }
        }
        
        #Inclusion {
        
            .content_section_inner {
                position:relative;
            }
        
            .cont_wrap {
                display:flex;
                justify-content:flex-start;
                align-items:flex-end;
                margin:0;
            }
        
            .cont {
                margin-bottom:0;
                padding:0 6.25% 1em 0;
            }
        
            .img {
                margin:0;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .page-template-page-careers {
        
        #OpenRoles {
        
            .roles_h {
                padding-right:0;
                
                h2 {
                    background:transparent url('../../images/pixel-arrow.svg') no-repeat right top;
                    background-size: 68px auto;
                    padding-right:90px;
                    background-position-x:calc(100% - 40px);
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_small_plus1}) and (max-width: 1200px) { //very specific breakpoint, for aligning and stacking grid columns on tablet, small desktop
    
    .page-template-page-careers {
        
        #OpenRoles {
        
            .roles_h {
                
                h2 {
                    background-size:40px auto;
                    background-position-y:center;
                }
            }
        }
        
        #JoinUs {
        
            .grid {
                display:block;
            }
        
            .cont {
                width:66.66%;
                margin-left:16.65%;
            }
        
            .img {
                width:66.66%;
                margin-left:16.65%;
            }
        }
        
        #Inclusion {
            
            .cont_wrap {
                display:block;
            }
        
            .cont {
                width:66.66%;
                margin-left:16.65%;
            }
        
            .img {
                width:66.66%;
                margin-left:16.65%;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    .page-template-page-careers {
        
        #OpenRoles {
        
            .roles_h {
                padding-right:6.25%;
                
                h2 {
                    padding-right:0;
                    background-position-x:right;
                }
            }
        }
    }
    
}

/**********************************************************************************************/
/***** SERVICES PAGE **************************************************************************/
/**********************************************************************************************/




/**********************************************************************************************/
/***** NEWFANGLED CONTENT UPGRADES, LANDING PAGES *********************************************/
/**********************************************************************************************/

#content {
    .contentupgrade-wrapper {
        margin:0;
        //background:$whipped_cream_400;
        border:none;
                    
        /*.content_section,
        .content_section_inner,
        .grid,
        .cu_wrap {
            position:static !important;
            float:none !important;
            margin:0 !important;
            padding:0 !important;
            width:auto !important;
        }*/
        
        .content_section_inner {
            padding-top:0;
            
            .grid {
                min-width:0;
            }
        }
    }
    
    .contentupgrade {
        position:relative;
        margin:1.67em 0 0;
        padding:0;
        background:$whipped_cream_400;
        z-index:10;
    
        * {
            color: $leafy_green !important;
        }
    
        .inner_content {
            padding:70px 16%;
            text-align:center;
        }
    
        //MAIL SIGNUP BLOCK
        &.mail_signup {

            .inner_content {
                padding:100px 16% 70px 16%;
                text-align:center;
            }
        }
    
        .contentupgrade-title {
            margin:0 0 0.5em;
            font-family: $ppfragment;
            font-weight:$normal;
            line-height:1.18em;
        }
    
        .contentupgrade-text {
            p {
                margin:0 0 1em;
                font-size:0.8em;
                font-weight:$light;
            }
        }
        
        .contentupgrade-toggle-button,
        .contentupgrade-download-button {
            display:inline-block;
            position:relative;
            margin:0 0 1em 0;
            padding:16px 30px;
            width:auto;
            background:$k_kelly !important;
            font-family: $lores;
            font-size: 0.8em;
            font-weight: $normal;
            font-style:normal;
            line-height:1em !important;
            color: $leafy_green !important;
            border:none;
            text-decoration:none;
            text-transform:uppercase;
            letter-spacing:0.05em;
            box-sizing:border-box;
            text-align:center;
            -webkit-appearance:none;
            transition:all 0.3s;
            cursor:pointer;
            
            &:hover {
                background:$lemon_lime !important;
            }
        }
        
        .gform_wrapper .gform_validation_errors {
            background:$v_error_bg !important;
        }
        
        .gform_confirmation_message {
	        background:$v_confirm_bg !important;
        }
        
        .pixels_wrap {
            z-index:-1;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    #content {
        
        
        .contentupgrade {
            
            .inner_content {
                padding:100px 16%;
            }
    
            &.mail_signup {

                .inner_content {
                    padding:100px 16%;
                }
            }
            
            &.contentupgrade-pre-form-container,
            &.mail_signup {
                
                .inner_content {
                    padding:100px 16% 70px 16%;
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    #content {
        
        
        .contentupgrade {
            
            .inner_content {
                padding:70px 16%;
            }
    
            &.mail_signup {

                .inner_content {
                    padding:70px 16%;
                }
            }
            
            &.contentupgrade-pre-form-container,
            &.mail_signup {
                
                .inner_content {
                    padding:100px 16% 70px 16%;
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}

/**********************************************************************************************/
/***** SEARCH RESULT PAGE *******************************************************************************/
/**********************************************************************************************/
.pb-60{
    padding-bottom: 60px !important;
}
.content_section_min_height {
    min-height: 400px;
}

.content_section_inner{
    &.pb-60{
        padding-bottom: 60px !important;
    }
}

.searchform {
	[class*=col-] {
		padding-right: 10px;
	}
}

.search_result_title{
    h2 {
        span{
            display: block;
            color: #075F25;
        }
    }
}

.search_pagination {
    text-align: center;
}

.search_result_wrapper {
    h3 {
        margin-top: 0;
    }
    p{
        margin-bottom: 0;
    }
    .eyebrow {
        margin-bottom: 0;
        color: #075F25;
    }
    figure {
        img{
            max-width: 100%;
            height: auto;
        }
    }
}

.search_result_title {
	form {
        .input-wrapper{

            input[type="text"] {
                padding: 15px;
                border-radius: 0;
                height: 48px;
                width: 100%;
                box-sizing: border-box;
                font-family: mundial, sans-serif;
                font-weight: 300;
                font-size: 16px;
                border: 1px solid rgba(15, 44, 21, .3);
                -webkit-appearance: none;
                letter-spacing: normal;
                background: 0 0;
                transition: all .3s;
                background: #F8F5EC;
            }

            ::placeholder {
                color: rgba(15, 44, 21, .7);
            }
        }   
        .select-wrapper{
            position: relative;

            select {
                border: none;
                border: 1px solid rgba(15, 44, 21, .3);
                outline: 0;
                margin: 0;
                padding: 12px 30px 12px 15px;
                font-family: mundial, sans-serif;
                font-size: 16px;
                font-weight: 300;
                color: rgba(15, 44, 21, .7);
                width: 100%;
                height: 48px;
                box-sizing: border-box;
                background: #F8F5EC;
                background: 0 0;
                border-radius: 0;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-appearance: none;
                appearance: none;
                white-space: pre-wrap;
                transition: all .3s;
                z-index: 110;
                cursor: pointer;
                background: #F8F5EC;
            }

            &:after {
				position: absolute;
				right: 20px;
				top: 50%;
				content: "";
				text-align: center;
				z-index: -1;
				width: 14px;
				height: 9px;
				background: url('../../images/select-caret.svg') center center no-repeat;
				transform: translateY(-50%);
				opacity: .7;
				transition: all .3s;
                z-index: 999;
			}

			&:hover {
				&:after {
					transform: translateY(-50%) rotate(180deg);
					opacity: 1;
				}
			}
        }
        input[type="submit"] {
            vertical-align: top;
            background: url('../../images/search-black.svg') 24px center / 16px 16px no-repeat !important;
            background-color: #0EB747 !important;
            padding: 14px 24px 14px 48px;
            height: 48px;
            width: initial;
        }
		
	}
}


@media only screen and (max-width: 1024px) {
    
    .search_result_title {
        form {
          .input-wrapper{
            margin-bottom: 10px;

            input[type="text"] {
                width: 100%;
            }
          }
          .select-wrapper {
            margin-bottom: 10px;

            select {
                width: 100%;
            }
          }
        }
    }
    
}

.entry-content {
	ol {
		&.ais-Hits-list {
			margin: 0 auto;
		}
	}
}
.w-100 {
	width: 100% !important;
}
.ais-Hits-item {
	margin: 0;
    padding: 0;
	border: none !important;
    list-style: none;
}
.ais-hits--thumbnail{
    margin: 0 0 0 25px !important;
}
.ais-SearchBox-loadingIndicator {
	display: none!important;
}
.search_wrapper {
	position: relative;
	display: flex;
	.algolia-search-box {
		width: 100%;
        margin-bottom: 0 !important;
		.ais-SearchBox {
			.ais-SearchBox-form {
				display: flex;
				justify-content: space-between;
				.ais-SearchBox-input {
					width: 52.4% !important;
					font-family: mundial, sans-serif;
					font-size: 16px !important;
					height: 48px;
					-webkit-appearance: none;
					transition: all .3s;
					background: #F8F5EC !important;
					box-sizing: border-box;
					padding: 15px !important;
					border-radius: 0;
					font-weight: 300;
					border: 1px solid rgba(15, 44, 21, .3)!important;
					letter-spacing: normal;
					z-index: 9999;
				}
				::placeholder {
					color: rgba(15, 44, 21, 0.7)!important;
				}
				.ais-SearchBox-submit {
					width: 135px;
					font-family: lores-12, sans-serif;
					background: url('../../images/search-black.svg') 24px center / 16px 16px no-repeat #0EB747 !important;
					padding: 14px 24px 14px 48px;
					height: 48px;
					border: none;
					letter-spacing: .05em;
					text-transform: uppercase;
					font-size: .8em;
					z-index: 9999;
					cursor: pointer;
					transition: all .3s;
					&:hover {
						background-color: #DFEB8E !important;
					}
					svg {
						display: none !important;
					}
				}
			}
		}
	}
	.algolia-dropdown-box {
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: end;
		.ais-MenuSelect {
			display: flex;
			position: relative;
			right: 145px;
			width: 28%;
			&:after {
				position: absolute;
				right: 20px;
				top: 50%;
				content: "";
				text-align: center;
				width: 14px;
				height: 9px;
				background: url('../../images/select-caret.svg') center center no-repeat;
				transform: translateY(-50%);
				opacity: .7;
				transition: all .3s;
				z-index: 999;
			}
		}
		.ais-MenuSelect-select {
			width: 100%;
			position: relative;
			font-family: mundial, sans-serif;
            font-size: 16px !important;    
            color: rgba(15, 44, 21, 0.7)!important;
			height: 48px;
			-webkit-appearance: none;
			transition: all .3s;
			background: #F8F5EC;
			box-sizing: border-box;
			border: 1px solid rgba(15, 44, 21, .3);
			outline: 0;
			margin: 0;
			padding: 12px 30px 12px 15px;
			font-weight: 300;
			border-radius: 0;

            ::placeholder{
                color: rgba(15, 44, 21, 0.7)!important;
            }
		}
	}
}
.ais-Hits {
	&.ais-Hits--empty {
		background-color: #fffdf6 !important;
		justify-content: center;
		text-align: center;
		display: flex;
		padding-bottom: 3%;
	}
}
#algolia-hits {
	width: 100%;
	max-width: 1420px;
	margin: 0 auto;
}
ul {
	&.ais-Pagination-list {
		position: relative;
		li {
			a {
				position: relative;
				display: block;
				width: auto;
				height: 30px;
				margin: 0 10px;
				padding: 0;
				box-sizing: border-box;
				font-size: .9em;
				line-height: 30px;
				font-weight: 300;
				border: none;
				text-decoration: none;
				transition: color .3s;
				&:hover {
					color: #0EB747;
				}
			}
			&.ais-Pagination-item--selected {
				a {
					cursor: default;
					color: #0EB747;
					text-decoration: underline;
					-webkit-text-decoration-color: #0EB747;
					text-decoration-color: #0EB747;
					text-decoration-thickness: 2px;
					text-underline-offset: .2em;
				}
			}
		}
	}
}

.mobile-only{
    display: none;
}
.search_image_wrapper_mobile {
        max-width: 200px;
        float: right;
}

@media screen and (max-width: 1440px) {
    .content_section {
        &.csection {
            .content_section_inner {
                &.w-100 {
                    width: calc(100% - 44px) !important;
                }
            }
        }
    }

}


@media screen and (max-width: 1024px) {
    .content_section {
        &.csection {
            .content_section_inner {
                &.w-100 {
                    width: calc(100% - 64px) !important;
                }
            }
        }
    }
    .search_wrapper {
        .algolia-search-box {
            .ais-SearchBox {
                .ais-SearchBox-form {
                    .ais-SearchBox-input {
                        width: 44% !important;
                    }
                    .ais-SearchBox-submit {
                        background: url('../../images/search-black.svg') 12px center / 16px 16px no-repeat #0EB747 !important;
                        padding: 14px 12px 14px 36px;
                        width: 108px;
                    }
                }
            }
        }
        .algolia-dropdown-box {
            .ais-MenuSelect {
                right: 118px;
                width: 32%;
            }
        }
    }

}

@media screen and (max-width: 768px) {
    .pb-60{
        padding-bottom: 32px !important;
    }
    .topper_whipped_cream_200 {
        background:#FFFDF6;
    }
    .search_wrapper {
        .algolia-search-box {
            .ais-SearchBox {
                .ais-SearchBox-form {
                    flex-wrap: wrap;
                    .ais-SearchBox-input {
                        width: 100% !important;
                        margin-bottom: 60px;
                    }
                }
            }
        }
        .algolia-dropdown-box {
            .ais-MenuSelect {
                width: 100%;
                right: initial;
                top: 55px;
            }
        }
    }

    .search_image_wrapper{
        display: none;
    }
    .mobile-only{
        display: inline-block;

        &.search_image_wrapper_mobile{
            display: inline;
            float: right;
            margin: 0 0 10px 10px;
            max-width: 40%;
        }
    }

}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    .content_section_min_height {
        min-height: 900px;
    }
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}