/**********************************************************************************************/
/***** ACCENT PIXELS **************************************************************************/
/**********************************************************************************************/

/*
Pixel widths are designed as a ratio, but will have to be wrangled to work within the 12-col fluid
grid of the site and custom blocks. Pixels are set in percentages, but those percentages have to 
adjust based on the block/location widths.

Full-width blocks (Mobile):
LG: 12.84%
MED: 6.42%

Full-width blocks (Desktop):
LG: 8.51%
MED: 4.255%
*/

$px_fw_block_lg_dsk : 8.51%;
$px_fw_block_med_dsk : 4.255%;

$px_fw_block_lg_mob : 12.84%;
$px_fw_block_med_mob : 6.42%;

/*
Image Section Block - LEFT IMAGE (Desktop):
LG: 20.42%
MED: 10.21%

Image Section Block - RIGHT IMAGE (Desktop):
LG: 15.23%
MED: 7.615%
*/

$px_img_left_lg_dsk : 20.42%;
$px_img_left_med_dsk : 10.21%;

$px_img_right_lg_dsk : 15.23%;
$px_img_right_med_dsk : 7.615%;

/*
Post Grid Blocks (All Breakpoints):
12.5%
*/

$px_post_grid_all : 12.5%;

/*
Insights Grid, CTA blocks (All Breakpoints):
LG: 14.81%
MED: 7.405%
*/

$px_ins_grid_ctas_lg : 14.81%;
$px_ins_grid_ctas_med : 7.405%;

/*
Insights Grid, Featured (Mobile):
LG: 12.84%
MED: 6.42%

Insights Grid, Featured (Desktop):
LG: 17.41%
MED: 8.705%
*/

$px_ins_featured_lg_mob : 12.84%;
$px_ins_featured_med_mob : 6.42%;

$px_ins_featured_lg_dsk : 17.41%;
$px_ins_featured_med_dsk : 8.705%;



.pixels_wrap {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    pointer-events:none;
    
    .pixel {
        position:absolute;
        height:0;
        padding-bottom:100%;
        background:none;
        
        .inner {
            position:absolute;
            left:0;
            width:100%;
            height:100%;
        }
        
        &.lg {
            //width:8.51%;
            //padding-bottom:8.51%;
            width:$px_fw_block_lg_mob;
            padding-bottom:$px_fw_block_lg_mob;
            
            /*.team_grid .cta_img & {
                width:calc(2 * #{$px_fw_block_lg_mob});
                padding-bottom:calc(2 * #{$px_fw_block_lg_mob});
            }*/
        }
        
        &.med {
            width:$px_fw_block_med_mob;
            padding-bottom:$px_fw_block_med_mob;
            
            /*.team_grid .cta_img & {
                width:calc(2 * #{$px_fw_block_med_mob});
                padding-bottom:calc(2 * #{$px_fw_block_med_mob});
            }*/
        }
        
        //Prev/Next block bg colors, for blending page blocks together
        &.whipped_cream_200 {
            .inner {
                background:$whipped_cream_200;
            }
        }
        
        &.whipped_cream_400 {
            .inner {
                background:$whipped_cream_400;
            }
        }
        
        &.leafy_green {
            .inner {
                background:$leafy_green;
            }
        }
        
        &.leafy_green_400 {
            .inner {
                background:$leafy_green_400;
            }
        }
        
        &.one {
            top:0;
            left:0;
            
            .csection & {
                
            }
            
            .c_img_section.one_img & {
                
                .inner {
                    background:$plum_200;
                }
            }
            
            .cta_cont_img & {
                
            }
            
            .hero & {
                left:calc(4 * #{$px_fw_block_lg_mob});
            }
            
            .img_flex_grid & {
                
                .inner {
                    background:$rhubarb;
                }
            }
            
            .testimonial & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    top:-200%;
                    background:$rhubarb;
                }
            }
            
            .team_grid & {
                left:auto;
                right:calc(4 * #{$px_fw_block_med_mob});
                
                .inner {
                    background:$lemon_lime;
                }
            }
            
            .graphic_metrics_grid & {
                left:auto;
                right:$px_fw_block_lg_mob;
            }
            
            .posts_grid.rp4wp-related-posts & {
                
                .inner {
                    background:$whipped_cream_200;
                }
            }
            
            #Inclusion & {
                left:auto;
                right:calc(2 * #{$px_fw_block_med_mob});
                
                .inner {
                    top:100%;
                    background:$lemon_lime;
                }
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(14 * #{$px_fw_block_med_mob});
                
                .inner {
                    background:$lemon_lime;
                }
            }
            
            #footer_form & {
                left:auto;
                right:$px_fw_block_lg_mob;
                
                .inner {
                    background:$pink_salt;
                }
            }
        }
        
        &.two {
            top:0;
            left:0;
            
            .csection & {
                left:auto;
                right:0;
            }
            
            .c_img_section.one_img & {
                left:auto;
                right:0;
                
                .inner {
                    background:$lemon_lime;
                }
            }
            
            .cta_cont_img & {
                left:$px_fw_block_lg_mob;
            }
            
            .hero & {
                left:calc(5 * #{$px_fw_block_lg_mob});
            }
            
            .img_flex_grid & {
                top:auto;
                bottom:0;
            }
            
            .testimonial & {
                top:auto;
                bottom:0;
                
                .inner {
                    background:$plum;
                }
            }
            
            .team_grid & {
                left:auto;
                right:$px_fw_block_lg_mob;
            }
            
            .icon_grid & {
                left: $px_fw_block_lg_mob;
            }
            
            .graphic_metrics_grid & {
                left:auto;
                right:0;
            }
            
            .posts_grid.rp4wp-related-posts & {
                left:calc(2 * #{$px_fw_block_med_mob});
                
                .inner {
                    background:$lemon_lime;
                }
            }
            
            #Inclusion & {
                left:auto;
                right:0;
            }
            
            .archive.author #RecentInsights & {
                left:$px_fw_block_lg_mob;
            }
            
            .archive.author .bio & {
                display:none;
                left:auto;
                right:calc(7 * #{$px_fw_block_lg_mob});
            }
            
            .what_to_expect & {
                left:auto;
                right:0;
            }
            
            #footer_form & {
                left:auto;
                right:0;
                
                .inner {
                    background:$leafy_green_400;
                }
            }
        }
        
        &.three {
            top:0;
            left:0;
            
            .csection & {
                left:auto;
                right:$px_fw_block_med_mob;
                
                .inner {
                    top:200%;
                    background:$lemon_lime;
                }
            }
            
            .c_img_section.one_img & {
                left:auto;
                right:$px_fw_block_med_mob;
                
                .inner {
                    top:200%;
                    background:$whipped_cream_200;
                }
            }
            
            .cta_cont_img & {
                left:calc(2 * #{$px_fw_block_lg_mob});
            }
            
            .hero & {
                left:calc(12 * #{$px_fw_block_med_mob});
                
                .inner {
                    top:0;
                    background:$lemon_lime;
                }
            }
            
            .img_flex_grid & {
                left:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            .testimonial & {
                left:auto;
                right:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            .team_grid & {
                left:auto;
                right:0;
            }
            
            .icon_grid & {
                left:calc(2 * #{$px_fw_block_lg_mob});
            }
            
            .graphic_metrics_grid & {
                left:auto;
                right:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            #Inclusion & {
                top:auto;
                bottom:0;
            }
            
            .archive.author #RecentInsights & {
                left:auto;
                right:0;
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(6 * #{$px_fw_block_lg_mob});
            }
            
            .what_to_expect & {
                left:auto;
                right:$px_fw_block_med_mob;
                
                .inner {
                    top:200%;
                    background:$lemon_lime;
                }
            }
            
            #footer_form & {
                top:auto;
                bottom:0;
                
                .inner {
                    top:0;
                    background:$leafy_green_400;
                }
            }
        }
        
        &.four {
            top:0;
            left:0;
            
            .csection & {
                top:auto;
                bottom:0;
            }
            
            .c_img_section.one_img & {
                top:auto;
                bottom:0;
                
                .inner {
                    
                }
            }
            
            .cta_cont_img & {
                left:auto;
                right:$px_fw_block_med_mob;
            }
            
            .hero & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    top:-200%;
                    background:$pink_salt_200;
                }
            }
            
            .img_flex_grid & {
                left:auto;
                right:calc(2 * #{$px_fw_block_med_mob});
                top:auto;
                bottom:0;
                
                .inner {
                    top:-100%;
                    background:$plum;
                }
            }
            
            .testimonial & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            .icon_grid & {
                left:auto;
                right:$px_fw_block_med_mob;
            }
            
            .graphic_metrics_grid & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            #Inclusion & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    background:$pink_salt;
                }
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(5 * #{$px_fw_block_lg_mob});
            }
            
            .what_to_expect & {
                left:auto;
                right:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            #footer_form & {
                top:auto;
                bottom:0;
                left:$px_fw_block_lg_mob;
                
                .inner {
                    top:0;
                    background:$leafy_green_400;
                }
            }
        }
        
        &.five {
            top:0;
            left:0;
            
            .csection & {
                top:auto;
                bottom:0;
                left:$px_fw_block_lg_mob;
            }
            
            #OpenRoles & {
                left:auto;
                right:$px_fw_block_lg_mob;
            }
            
            .c_img_section.one_img & {
                left:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
                
                .inner {
                    background:$pink_salt_200;
                }
            }
            
            .cta_cont_img & {
                left:auto;
                right:0;
                
                .inner {
                    background:$rhubarb;
                }
            }
            
            .hero & {
                top:auto;
                bottom:0;
            }
            
            .img_flex_grid & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            .team_grid & {
                left:$px_fw_block_med_mob;
                
                .inner {
                    top:100%;
                    background:$plum_200;
                }
            }
            
            .icon_grid & {
                left:auto;
                right:0;
                
                .inner {
                    background:$rhubarb;
                }
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(4 * #{$px_fw_block_lg_mob});
            }
            
            .what_to_expect & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            #footer_form & {
                left:calc(4 * #{$px_fw_block_med_mob});
                top:auto;
                bottom:0;
                
                .inner {
                    top:0;
                    background:$rhubarb;
                }
            }
        }
        
        &.six {
            top:0;
            left:0;
            
            .csection & {
                top:auto;
                bottom:0;
                left:calc(4 * #{$px_fw_block_med_mob});
                background:$plum;
            }
            
            #OpenRoles & {
                left:auto;
                right:0;
            }
            
            .cta_cont_img & {
                
                .inner {
                    top:200%;
                    background:$plum_200;
                }
            }
            
            .hero & {
                left:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            .team_grid & {
                top:auto;
                bottom:0;
                
                .inner {
                    background:$rhubarb;
                }
            }
            
            .icon_grid & {
                
                .inner {
                    top:200%;
                    background:$plum_200;
                }
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(3 * #{$px_fw_block_lg_mob});
            }
            
            #footer_form & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    background:$leafy_green_400;
                }
            }
        }
        
        &.seven {
            top:0;
            left:0;
            
            .cta_cont_img & {
                left:auto;
                right:calc(2 * #{$px_fw_block_med_mob});
                top:auto;
                bottom:0;
                
                .inner {
                    top:-200%;
                    background:$lemon_lime;
                }
            }
            
            .hero & {
                left:calc(4 * #{$px_fw_block_med_mob});
                top:auto;
                bottom:0;
                
                .inner {
                    background:$plum;
                }
            }
            
            .team_grid & {
                top:auto;
                bottom:0;
                left:0;
            }
            
            .icon_grid & {
                top:auto;
                bottom:0;
            }
            
            .archive.author .bio & {
                left:auto;
                right:calc(2 * #{$px_fw_block_lg_mob});
            }
        }
        
        &.eight {
            top:0;
            left:0;
            
            .cta_cont_img & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    top:-100%;
                }
            }
            
            .hero & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            .team_grid & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
            
            .icon_grid & {
                left:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            .archive.author .bio & {
                left:auto;
                right:$px_fw_block_lg_mob;
            }
        }
        
        &.nine {
            top:0;
            left:0;
            
            .cta_cont_img & {
                left:auto;
                right:$px_fw_block_lg_mob;
                top:auto;
                bottom:0;
            }
            
            .icon_grid & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    background:$lemon_lime;
                }
            }
            
            .archive.author .bio & {
                left:auto;
                right:0;
            }
        }
        
        &.ten {
            top:0;
            left:0;
            
            .cta_cont_img & {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
        }
        
        //FEATURED INSIGHTS, INSIGHTS LANDING
        #FeaturedInsights & {
            
            &.lg {
                width:$px_ins_featured_lg_mob;
                padding-bottom:$px_ins_featured_lg_mob;
            }
        
            &.med {
                width:$px_ins_featured_med_mob;
                padding-bottom:$px_ins_featured_med_mob;
            }
        }
        
        //INSIGHTS, INSIGHTS LANDING - CTA BLOCKS
        #insights_list &,
        //.single-post &,
        .contentupgrade & {
            
            &.lg {
                width:$px_ins_grid_ctas_lg;
                padding-bottom:$px_ins_grid_ctas_lg;
            }
        
            &.med {
                width:$px_ins_grid_ctas_med;
                padding-bottom:$px_ins_grid_ctas_med;
            }
        }   
        
        .c_img_section.two_img & {
            
            .img.one {
                
            }
        }
    }
}

.c_img_section.two_img {
            
    .img {
        
        &.one {
            .pixels_wrap .pixel {
            
                &.lg {
                    width:$px_fw_block_lg_mob;
                    padding-bottom:$px_fw_block_lg_mob;
                }
        
                &.med {
                    width:$px_fw_block_med_mob;
                    padding-bottom:$px_fw_block_med_mob;
                }
                
                &.one {
                    top:auto;
                    bottom:0;
                    left:auto;
                    right:0;
                    
                    .inner {
                        top:-200%;
                        background:$lemon_lime;
                    }
                }
                
                &.two {
                    top:auto;
                    bottom:0;
                    left:auto;
                    right:0;
                }
            }
        }
        
        &.two {
            .pixels_wrap .pixel {
            
                &.lg {
                    width:$px_fw_block_lg_mob;
                    padding-bottom:$px_fw_block_lg_mob;
                }
        
                &.med {
                    width:$px_fw_block_med_mob;
                    padding-bottom:$px_fw_block_med_mob;
                }
                
                &.one {
                    left:auto;
                    right:0;
                    
                    .inner {
                        background:$plum;
                    }
                }
                
                &.two {
                    top:auto;
                    bottom:0;
                }
                
                &.three {
                    left:calc(2 * #{$px_fw_block_med_mob});
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$rhubarb;
                    }
                }
            }
        }
    }
}

//INSIGHTS, INSIGHTS LANDING - CTA BLOCKS
#insights_list .contentupgrade,
.single-post .contentupgrade,
.single-resources .contentupgrade,
.single-project .contentupgrade,
.single-award .contentupgrade,
.single-callout .contentupgrade,
.single-whitepaper .contentupgrade,
.single-webinar .contentupgrade,
.single-podcast .contentupgrade,
.single-ebook .contentupgrade {
    
    .pixels_wrap .pixel {
            
        &.one {
            left:auto;
            right:0;
            top:auto;
            bottom:0;
            
            .inner {
                background:$whipped_cream_200;
            }
        }
        
        &.two {
            top:auto;
            bottom:0;
            left:0;
            
            .inner {
                background:$plum;
            }
        }
    
        &.three {
            left:auto;
            right:0;
            top:auto;
            bottom:0;
            
            .inner {
                top:-200%;
                background:$rhubarb;
            }
        }
    }
    
    &.mail_signup {
    
        .pixels_wrap .pixel {
            
            &.one {
                right:auto;
                left:0;
                bottom:auto;
                top:0;
            }
            
            &.two {
                left:auto;
                right:0;
                bottom:auto;
                top:0;
            }
            
            &.three {
                display:none;
            }
        }
    }
}

#insights_list .contentupgrade {
    
    .pixels_wrap .pixel {
            
        &.one {
            
            .inner {
                background:$leafy_green;
            }
        }
    }
}

//SINGLE INSIGHT FEATURED IMAGE BLOCK
.single-post,
.single-resources,
.single-project,
.single-award,
.single-callout,
.single-whitepaper,
.single-webinar,
.single-podcast,
.single-ebook {


    .featured_img {
        
        
        .pixels_wrap {
            .pixel {
                &.lg {
                    width:$px_fw_block_lg_mob;
                    padding-bottom:$px_fw_block_lg_mob;
                }
        
                &.med {
                    width:$px_fw_block_med_mob;
                    padding-bottom:$px_fw_block_med_mob;
                }
                
                &.one {
                    left:calc(4 * #{$px_fw_block_lg_mob});
                
                    .inner {
                        background:$leafy_green_400;
                    }
                }
                
                &.two {
                    left:auto;
                    right:0;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        top:-200%;
                        background:$lemon_lime;
                    }
                }
                
                &.three {
                    left:$px_fw_block_med_mob;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$rhubarb;
                    }
                }
                
                &.four {
                    left:$px_fw_block_lg_mob;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$whipped_cream_200;
                    }
                }
                
                &.five {
                    left:auto;
                    right:$px_fw_block_lg_mob;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$whipped_cream_200;
                    }
                }
                
                &.six {
                    left:auto;
                    right:0;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$whipped_cream_200;
                    }
                }
            }
        }
    }
}

.single-case-study {


    .featured_img {
        
        
        .pixels_wrap {
            .pixel {
                &.lg {
                    width:$px_fw_block_lg_mob;
                    padding-bottom:$px_fw_block_lg_mob;
                }
        
                &.med {
                    width:$px_fw_block_med_mob;
                    padding-bottom:$px_fw_block_med_mob;
                }
                
                &.one {
                    
                    .inner {
                        top:-100%;
                        background:$leafy_green;
                    }
                }
                
                &.two {
                    left:auto;
                    right:$px_fw_block_lg_mob;
                    
                    .inner {
                        background:$leafy_green_400;
                    }
                }
                
                &.three {
                    left:auto;
                    right:calc(2 * #{$px_fw_block_lg_mob});
                    
                    .inner {
                        background:$leafy_green_400;
                    }
                }
                
                &.four {
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        top:-200%;
                        background:$rhubarb;
                    }
                }
                
                &.five {
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$whipped_cream_200;
                    }
                }
                
                &.six {
                    left:auto;
                    right:$px_fw_block_med_mob;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$lemon_lime;
                    }
                }
            }
        }
    }
}

//FEATURED INSIGHTS, PIXEL POSITIONS
#FeaturedInsights {
    .featured {
        
        .pixels_wrap .pixel {
            
            &.one {
                    
                .inner {
                    background:$leafy_green_400;
                }
            }
        
            &.two {
                top:auto;
                bottom:0;
                
                .inner {
                    background:$rhubarb;
                }
            }
        
            &.three {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
                
                .inner {
                    background:$leafy_green;
                }
            }
        }
        
        &:last-of-type {
            
            .pixels_wrap .pixel {
            
                &.one {
                    
                    .inner {
                        background:$leafy_green;
                    }
                }
            
                &.two {
                    bottom:auto;
                    top:0;
                    left:calc(2 * #{$px_ins_featured_med_mob});
                    
                    .inner {
                        background:$lemon_lime;
                    }
                }
            
                &.three {
                    left:auto;
                    right:0;
                    top:auto;
                    bottom:0;
                    
                    .inner {
                        background:$leafy_green;
                    }
                }
            }
        }
    }
}

//POSTS GRID, INSIGHT/CS GRIDS
.px_wrap {
    overflow:hidden;
    
    #insights_list & {
        .pixel {
            .px_inner {
                background:$leafy_green;
            }
        }
    }
    
    .pixel {
        position:absolute;
        height:0;
        width:$px_post_grid_all;
        padding-bottom:$px_post_grid_all;
        transition:all 0.15s;
        
        .px_inner {
            position:absolute;
            left:0;
            width:100%;
            height:100%;
            background:$leafy_green_400;
        }
    }
    
    //'GROUPINGS' OF PIXELS, PSEUDO-RANDOMLY SELECTED IN THE TEMPLATES
    &.group1 {
        .pixel {
            &.one {
                left:0;
                top:0;
            }
        
            &.two {
                left:$px_post_grid_all;
                top:0;
            }
        
            &.three {
                left:auto;
                right:0;
                top:auto;
                bottom:0;
            }
        }
    }
    
    &.group2 {
        .pixel {
            &.one {
                left:auto;
                right:0;
                top:0;
            }
        
            &.two {
                left:auto;
                right:$px_post_grid_all;
                top:0;
            }
        
            &.three {
                left:0;
                top:auto;
                bottom:0;
            }
        }
    }
    
    &.group3 {
        .pixel {
            &.one {
                left:auto;
                right:0;
                top:0;
            }
        
            &.two {
                left:auto;
                right:0;
                top:0;
                
                .px_inner {
                    top:100%;
                }
            }
        
            &.three {
                left:0;
                top:0;
            }
        }
    }
    
    &.group4 {
        .pixel {
            &.one {
                left:0;
                top:0;
            }
        
            &.two {
                left:auto;
                right:0;
                top:0;
            }
        
            &.three {
                left:0;
                top:auto;
                bottom:0;
            }
        }
    }
    
    &.group5 {
        .pixel {
            &.one {
                left:0;
                top:auto;
                bottom:0;
            }
        
            &.two {
                left:0;
                top:auto;
                bottom:0;
                
                .px_inner {
                    top:-100%;
                }
            }
        
            &.three {
                left:auto;
                right:0;
                top:0;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .pixels_wrap {
        
        .pixel {
            
            &.lg {
                width:$px_fw_block_lg_dsk;
                padding-bottom:$px_fw_block_lg_dsk;
                
                .team_grid .cta_img &,
                #Inclusion & {
                    width:calc(2 * #{$px_fw_block_lg_dsk});
                    padding-bottom:calc(2 * #{$px_fw_block_lg_dsk});
                }
            }
        
            &.med {
                width:$px_fw_block_med_dsk;
                padding-bottom:$px_fw_block_med_dsk;
                
                .team_grid .cta_img &,
                #Inclusion & {
                    width:calc(2 * #{$px_fw_block_med_dsk});
                    padding-bottom:calc(2 * #{$px_fw_block_med_dsk});
                }
            }
            
            &.one {
                
                .hero & {
                    left:calc(4 * #{$px_fw_block_lg_dsk});
                }
                
                .team_grid & {
                    right:calc(4 * #{$px_fw_block_med_dsk});
                }
                
                .graphic_metrics_grid & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                }
                
                #Inclusion & {
                    left:auto;
                    right:calc(2 * #{$px_fw_block_med_dsk});
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(16 * #{$px_fw_block_med_dsk});
                }
            
                #footer_form & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                }
            }
            
            &.two {
                
                .cta_cont_img & {
                    left:$px_fw_block_lg_dsk;
                }
            
                .hero & {
                    left:calc(5 * #{$px_fw_block_lg_dsk});
                }
                
                .team_grid & {
                    right:$px_fw_block_lg_dsk;
                }
                
                .icon_grid & {
                    left:$px_fw_block_lg_dsk;
                }
                
                .posts_grid.rp4wp-related-posts & {
                    left:calc(2 * #{$px_fw_block_med_dsk});
                }
                
                .archive.author #RecentInsights & {
                    left:$px_fw_block_lg_dsk;
                }
                
                .archive.author .bio & {
                    display:block;
                    left:auto;
                    right:calc(7 * #{$px_fw_block_lg_dsk});
                }
            }
            
            &.three {
                
                .csection & {
                    right:$px_fw_block_med_dsk;
                }
            
                .c_img_section.one_img & {
                    right:$px_fw_block_med_dsk;
                }
            
                .cta_cont_img & {
                    left:calc(2 * #{$px_fw_block_lg_dsk});
                }
            
                .hero & {
                    left:calc(12 * #{$px_fw_block_med_dsk});
                }
            
                .img_flex_grid & {
                    left:$px_fw_block_lg_dsk;
                }
            
                .testimonial & {
                    right:$px_fw_block_lg_dsk;
                }
                
                .icon_grid & {
                    left:calc(2 * #{$px_fw_block_lg_dsk});
                }
                
                .graphic_metrics_grid & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(6 * #{$px_fw_block_lg_dsk});
                }
                
                .what_to_expect & {
                    left:auto;
                    right:$px_fw_block_med_dsk;
                }
                
                #footer_form & {
                    
                    .inner {
                        top:-200%;
                    }
                }
            }
            
            &.four {
                
                .cta_cont_img & {
                    right:$px_fw_block_med_dsk;
                }
            
                .img_flex_grid & {
                    right:calc(2 * #{$px_fw_block_med_dsk});
                }
                
                .icon_grid & {
                    left:auto;
                    right:$px_fw_block_med_dsk;
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(5 * #{$px_fw_block_lg_dsk});
                }
                
                .what_to_expect & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                    top:auto;
                    bottom:0;
                }
                
                #footer_form & {
                    left:0;
                
                    .inner {
                        top:-100%;
                    }
                }
            }
            
            &.five {
                
                .csection & {
                    left:$px_fw_block_lg_dsk;
                }
                
                #OpenRoles & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                }
            
                .c_img_section.one_img & {
                    left:$px_fw_block_lg_dsk;
                }
                
                /*.team_grid & {
                    right:calc(6 * #{$px_fw_block_lg_dsk});
                }*/
                
                .team_grid & {
                    left:calc(2 * #{$px_fw_block_med_dsk});
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(4 * #{$px_fw_block_lg_dsk});
                }
            
                #footer_form & {
                    left:$px_fw_block_med_dsk;
                
                    .inner {
                        top:-200%;
                    }
                }
            }
            
            &.six {
                
                .csection & {
                    left:calc(4 * #{$px_fw_block_med_dsk});
                }
            
                .hero & {
                    left:$px_fw_block_lg_dsk;
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(3 * #{$px_fw_block_lg_dsk});
                }
            }
            
            &.seven {
                
                .cta_cont_img & {
                    right:calc(2 * #{$px_fw_block_med_dsk});
                }
            
                .hero & {
                    left:calc(4 * #{$px_fw_block_med_dsk});
                }
                
                .team_grid & {
                    left:calc(-2 * #{$px_fw_block_lg_dsk});
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:calc(2 * #{$px_fw_block_lg_dsk});
                }
            }
            
            &.eight {
                
                .icon_grid & {
                    left:$px_fw_block_lg_dsk;
                }
                
                .archive.author .bio & {
                    left:auto;
                    right:$px_fw_block_lg_dsk;
                }
            }
            
            &.nine {
                
                .cta_cont_img & {
                    right:$px_fw_block_lg_dsk;
                }
            }
            
            //FEATURED INSIGHTS, INSIGHTS LANDING
            #FeaturedInsights & {
            
                &.lg {
                    width:$px_ins_featured_lg_dsk;
                    padding-bottom:$px_ins_featured_lg_dsk;
                }
        
                &.med {
                    width:$px_ins_featured_med_dsk;
                    padding-bottom:$px_ins_featured_med_dsk;
                }
            }
        }
    }
    
    .c_img_section.two_img {
            
        .img {
        
            &.one {
                .pixels_wrap .pixel {
            
                    &.lg {
                        width:$px_img_left_lg_dsk;
                        padding-bottom:$px_img_left_lg_dsk;
                    }
        
                    &.med {
                        width:$px_img_left_med_dsk;
                        padding-bottom:$px_img_left_med_dsk;
                    }
                }
            }
        
            &.two {
                .pixels_wrap .pixel {
            
                    &.lg {
                        width:$px_img_right_lg_dsk;
                        padding-bottom:$px_img_right_lg_dsk;
                    }
        
                    &.med {
                        width:$px_img_right_med_dsk;
                        padding-bottom:$px_img_right_med_dsk;
                    }
                
                    &.three {
                        left:calc(2 * #{$px_img_right_med_dsk});
                    }
                }
            }
        }
    }
    
    //SINGLE INSIGHT FEATURED IMAGE BLOCK
    .single-post,
    .single-resources,
    .single-project,
    .single-award,
    .single-callout,
    .single-whitepaper,
    .single-webinar,
    .single-podcast,
    .single-ebook {


        .featured_img {
        
        
            .pixels_wrap {
                .pixel {
                    &.lg {
                        width:$px_fw_block_lg_dsk;
                        padding-bottom:$px_fw_block_lg_dsk;
                    }
        
                    &.med {
                        width:$px_fw_block_med_dsk;
                        padding-bottom:$px_fw_block_med_dsk;
                    }
                
                    &.one {
                        left:calc(4 * #{$px_fw_block_lg_dsk});
                    }
                
                    &.three {
                        left:$px_fw_block_med_dsk;
                    }
                
                    &.four {
                        left:$px_fw_block_lg_dsk;
                    }
                
                    &.five {
                        right:$px_fw_block_lg_dsk;
                    }
                }
            }
        }
    }
    
    .single-case-study {


        .featured_img {
        
        
            .pixels_wrap {
                .pixel {
                    &.lg {
                        width:$px_fw_block_lg_dsk;
                        padding-bottom:$px_fw_block_lg_dsk;
                    }
        
                    &.med {
                        width:$px_fw_block_med_dsk;
                        padding-bottom:$px_fw_block_med_dsk;
                    }
                
                    &.two {
                        left:auto;
                        right:$px_fw_block_lg_dsk;
                    }
                
                    &.three {
                        left:auto;
                        right:calc(2 * #{$px_fw_block_lg_dsk});
                    }
                
                    &.six {
                        left:auto;
                        right:$px_fw_block_med_dsk;
                    }
                }
            }
        }
    }
    
    #FeaturedInsights {
        .featured {
        
            &:last-of-type {
            
                .pixels_wrap .pixel {
            
                    &.one {
                    
                        .inner {
                            background:$leafy_green_400;
                        }
                    }
                    
                    &.two {
                        left:calc(2 * #{$px_ins_featured_med_dsk});
                    }
                }
            }
        }
    }
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}