/**********************************************************************************************/
/***** UTILITY ********************************************************************************/
/**********************************************************************************************/
/* ---------------------------------------------------------------
   HELPER CLASSES
--------------------------------------------------------------- */
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }

//MOBILE BLEED TO EDGES (Should only be applied to .content_section_inner containers)
.mobile_bleed {
    
    > img {
        margin-right:calc(-1 * #{$mobile_lr_padding}) !important;
        margin-left:calc(-1 * #{$mobile_lr_padding}) !important;
        width:calc(100% + (2 * #{$mobile_lr_padding})) !important;
    }
}

/* PATTERNS */
.wp-video {
    width:100% !important;
    margin:0 0 1.2em;
}
.mejs-container {
    width: 100% !important;
    height: auto !important;
    padding-top: 57%;
}
.mejs-overlay, .mejs-poster {
    width: 100% !important;
    height: 100% !important;
}
.mejs-mediaelement video {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100% !important;
    height: 100% !important;
}

.flex_wrap_center {
    display:block;
}

.to_cols_3 {
    column-count:1;
}

.alignleft,
.alignright {
    float:left;
    width:auto;
    max-width:70%;
    height:auto;
    margin:0 1em 1em 0;
}
.alignright {
    float:right;
    margin:0 0 1em 1em;
}
.alignnone,
.aligncenter {
    width:auto;
    max-width:100%;
    height:auto;
    text-align:center;
}

.aligncenter {
    display:block;
    float:none;
    margin-right:auto;
    margin-left:auto;
}

figure {
    display:block;
    width:100%;
    margin:0 auto 1.2em;
    
    &.wp-block-image,
    &.wp-block-embed {
        margin:0 auto 1.2em;
    }
    
    &.wp-caption {
        margin-bottom:10px;
    }
    
    .single-post & {
        width:calc(100% + 80px);
        
        &.wp-block-image,
        &.wp-block-embed {
            margin:0 -40px 1.67em;
        }
        
        &.alignleft {
            width:auto;
            margin-left:-40px;
        }
        
        &.alignright {
            width:auto;
            margin-right:-40px;
        }
        
        &.aligncenter {
            figcaption {
                text-align:center;
            }
        }
    }
}

iframe {
    //max-width:100% !important;
}

.vid_cont {
    position:relative;
    margin:3em 0;
    background-color:transparent;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    z-index:10;
}

.video_container {
    position:relative;
    padding-bottom:56.25%;
    //padding-bottom:62.5%;
    /*padding-top:30px;*/
    margin:0 0 0 0;
    width:100%;
    height:0;
    opacity:1;
    visibility:visible;
    overflow:hidden;
    transition:opacity 0.3s;
    
    &.off {
        opacity:0;
        visibility:hidden;
    }
}
.video_container iframe,
.video_container object,
.video_container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.pos_rel {
    position:relative !important;
}
.float_left {
	float:left !important;
}
.float_right {
	float:right !important;
}
.of_hidden {
    overflow:hidden !important;
}
.nomargin {
    margin:0 !important;
}
.nomargin_top {
    margin-top:0 !important;
}
.nomargin_bottom {
    margin-bottom:0 !important;
}
.large_margin_bottom {
    margin-bottom:60px !important;
}
.tall_pad {
    padding-top:100px !important;
    padding-bottom:100px !important;
}
.short_pad {
    padding-top:30px !important;
    padding-bottom:30px !important;
}
.nopadding {
    padding:0 !important;
}
.nopadding_lr {
    padding-left:0 !important;
    padding-right:0 !important;
}
.nopadding_tb {
    padding-top:0 !important;
    padding-bottom:0 !important;
}
.nopadding_top {
    padding-top:0 !important;
}
.nopadding_bottom {
    padding-bottom:0 !important;
}
.small_padding_bottom {
    padding-bottom:30px !important;
}
.large_padding_bottom {
    padding-bottom:60px !important;
}
.small_padding_top {
    padding-top:30px !important;
}
.large_padding_top {
    padding-top:60px !important;
}
.nobullets {
    list-style:none !important;
}
.upper {
    text-transform:uppercase !important;
}
.lower {
    text-transform:none !important;
}
.centered {
    text-align:center !important;
}
.lefted {
    text-align:left !important;
}
.righted {
    text-align:right !important;
}
.blockit {
    display:block !important;
}
.flex_vert_cent {
    display:flex !important;
    align-items:center !important;
}
.width_cap {
    max-width:1008px;
}

.show_on_mobile {
    display:block !important;
}
.hide_on_mobile {
    display:none !important;
}

.show_on_tablet {
	display:none !important;
}
.hide_on_tablet {
	display:block !important;
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .mobile_bleed {
        
        > img {
            margin-right:0 !important;
            margin-left:0 !important;
            width:100% !important;
        }
    }
    
    .show_on_mobile {
        display:none !important;
    }
    .hide_on_mobile {
        display:block !important;
    }
    
    .show_on_tablet {
        display:block !important;
    }
    .hide_on_tablet {
        display:none !important;
    }
    
    .large_padding_bottom {
        padding-bottom:100px !important;
    }
    .large_padding_top {
        padding-top:100px !important;
    }
    
    .flex_wrap_center {
        display:flex;
        align-items:center;
    }
    
    .to_cols_3 {
        column-count:3;
        column-gap: 74px;
    
        > li {
            list-style-position: inside;
            break-inside: avoid;
        }
    }
}

@media screen and (min-width: #{$bp_small_plus1}) and (max-width: #{$bp_mid_plus1}) {

    .show_on_tablet {
        display:block !important;
    }
    .hide_on_tablet {
        display:none !important;
    }
    
    .nopadding_tablet {
        padding:0 !important;
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .show_on_tablet {
        display:none !important;
    }
    .hide_on_tablet {
        display:block !important;
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}