/**********************************************************************************************/
/***** BLOCK: CTA *****************************************************************************/
/**********************************************************************************************/
.content_section.cta_cont_img {
    position:relative;
    //background:$leafy_green;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    z-index:10;
    
    &.has_bg {
        
    }
    
    .content_section_inner {
        position:relative;
        padding:6em 0 2em;
        z-index:12;
        
        &.has_pixels {
            padding-top:9em;
        }
        
        &:before {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            content:'';
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            filter: grayscale(100%);
            z-index:13;
        }
    }
    
    .overlay {
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        @include background-opacity($leafy_green_400, 0.6);
        z-index:14;
        
        &.light {
            @include background-opacity($leafy_green_400, 0.8);
        }
    }
    
    .grid {
        position:relative;
        z-index:15;
    }
    
    .cont {
        padding:2em 26px 0;
        
        h3,
        h4 {
            margin-top:0;
        }
        
        .btn {
            margin-top:1em;
        }
    }
    
    h3,
    h4 {
        margin-bottom:0.92em;
        margin-top:3.07em;
        font-size: 1.625em; /* 26px */
        line-height:1.154em;
        
        > a {
            color:$whipped_cream;
            
            &:hover {
                color:$k_kelly;
            }
        }
    }
    
    .eyebrow {
        @extend h6;
        margin:0 0 1.3em;
        color:$k_kelly !important;
    }
    
    .img {
        margin-top:3em;
        
        img {
            display:block;
            width:100%;
        }
    }
    
    .pixels_wrap {
        z-index:15;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.cta_cont_img {
        
        .content_section_inner {
            padding:9em 0 3em;
        }
        
        .grid {
            display:flex;
            justify-content:flex-start;
            align-items:center;
        }
        
        .cont {
            padding:2em calc(8.33% - 32px) 0 0;
        
            .btn {
                margin-top:2em;
            }
        }
        
        h3,
        h4 {
            font-size: 1.3em;
        }
        
        .img {
            margin-top:0;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .content_section.cta_cont_img {
        
        
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}