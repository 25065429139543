.whipped_cream_full {
  background: #fffdf6;
}

.services-card-list {
  container-type: inline-size;
  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;
  max-width: 1183.33px;
  margin: 5rem auto;
  padding: 2rem;
}

@media (min-width: 800px) {
  .services-card-list {
    gap: 9rem;
  }
}

@media (min-width: 800px) {
  .services-card {
    display: grid;
    grid-template-areas: "media content content";
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    align-items: start;
  }
}

@media (min-width: 1000px) {
  .services-card {
    grid-template-areas: "media content";
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
  }
}

/**
MEDIA
===============================
*/
.services-card__media {
  position: relative;
  grid-area: media;
}

.services-card__media .services-image {
  display: none;
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .services-card__media .services-image {
    display: block;
  }
}

/**
FIGURE STYLES
===============================
*/
.services-image {
  position: relative;
}

.services-image img {
  width: 100%;
  height: auto;
}

.services-image .graphic {
  position: absolute;
  display: block;
}

.services-image .upper-right {
  top: -1px;
  right: -0.5px;
  width: 2.74875rem;
  height: 4.11731rem;
}

.services-image .lower-left {
  bottom: -1.5px;
  left: 0;
  width: 4.11719rem;
  height: 6.25rem;
}

@media (min-width: 1000px) {
  .services-image .upper-right {
    width: 5.49731rem;
    height: 8.23438rem;
  }

  .services-image .lower-left {
    width: 8.23438rem;
    height: 12.5rem;
  }
}

/**
TEXT CONTENT
===============================
*/
.services-card__text {
  grid-area: content;
}

.services-card__text .services-image {
  float: right;
  width: 45%;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .services-card__text .services-image {
    display: none;
  }
}

@media (max-width: 799px) {
  .services-card__text .services-image {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.services-card__text .eyebrow {
  max-width: 50%;
}

@media (min-width: 768px) {
  .services-card__text .eyebrow {
    max-width: none;
  }
}

.services-card__text h2 {
  margin-top: 1rem;
}

.services-card__text ul {
  list-style: disc;
  padding-left: 2rem;
}

.services-card__text li::marker {
  color: #0b9239;
}

.services-card__text .read-more {
  position: relative;
  display: inline-block;
  font-family: lores-12, sans-serif !important;
  margin-top: 2rem;
  color: #0b9239;
  text-transform: uppercase;
  text-decoration: none;
}

.services-card__text .read-more::before,
.services-card__text .read-more::after {
  position: absolute;
  bottom: -10px;
  height: 3px;
  transition: opacity 0.15s, bottom 0.3s;
  opacity: 0;
}

.services-card__text .read-more:hover::before,
.services-card__text .read-more:hover::after {
  bottom: -5px;
  opacity: 1;
}

.services-card__text .read-more::before {
  left: 0;
  width: 100%;
  background: url(../../images/nav-button-underline.svg);
  background-size: 100% auto;
  content: "";
}
.services-card__text .read-more::after {
  content: "";
  right: 0;
  width: 3px;
  content: "";
  background: #0eb747;
}

@media (min-width: 800px) {
  .services-card:nth-child(even) {
    grid-template-areas: "content content media";
  }
}

@media (min-width: 1000px) {
  .services-card:nth-child(even) {
    grid-template-areas: "content media";
  }
}

@media (min-width: 1200px) {
  .services-card-list .services-card:nth-child(n + 2) .services-card__text {
    padding-left: 85px;
  }
}
