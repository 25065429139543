/**********************************************************************************************/
/***** SITEWIDE TABLE STYLES ******************************************************************/
/**********************************************************************************************/
//GENERAL TABLE STYLES
table {
    position:relative;
    width:100%;
    margin: 1em 0 2em; 
    border: 1px solid $leafy_green; 
    border-left:none;
    border-top: none; 
    table-layout:auto;
    color:$leafy_green;
    font-family:$mundial;
    -webkit-font-smoothing: auto !important;
    
    td,
    th { 
        border-left: 1px solid $leafy_green; 
        padding: 0.75em;
        background: $white;
        text-align:left;
        font-size:16px;
        font-weight:$light;
        vertical-align:top;
    }
    th { 
        background: $th_bg;
        border-left: 1px solid $whipped_cream_200; 
        color:$whipped_cream;
        font-size:14px;
        font-weight:$normal;
        text-transform:uppercase;
        text-align:center;
        
        &:first-child {
            background:none;
            border:none;
        }
    }

    tr {
        border-top: 1px solid $leafy_green; 
        border-left: 1px solid $leafy_green;
        
        &:nth-child(even) td {
            background: $whipped_cream_200;
        }
    }
    
    thead tr {
        border:none;
    }
    
    thead tr th:first-child,
    tbody tr td:first-child {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
        word-break:auto;
        text-align:left;
    }
    
    tbody tr td:first-child {
        vertical-align:top;
    }
    
    tbody tr:first-child td:first-child {
        
    }
    
    /*&:before {
        position:absolute;
        bottom:-55px;
        left:-55px;
        width:55px;
        height:55px;
        background:$k_kelly;
        content:'';
    }
    &:after {
        position:absolute;
        bottom:-67px;
        left:-67px;
        width:12px;
        height:12px;
        background:$k_kelly;
        content:'';
    }*/
    
    &:before,
    &:after {
        display:none;
    }
    
}

//INLINE WYSIWYG TABLE STYLES (ADDED VIA THE CLASSIC MCE EDITOR)
/*.wysiwyg {
    
    table {
        border: 1px solid $k_kelly; 
        border-top: none; 
        table-layout:auto;
        
        td,
        th { 
            border-left: 1px solid $k_kelly; 
            padding: 1em;
            background: $k_kelly;
            
            &:first-of-type {
                width:auto !important;
            }
        }
        
        tr {
            border-top: 1px solid $k_kelly; 
            border-left: 1px solid $k_kelly;
            
            &:nth-child(even) td {
                background: $k_kelly;
            }
            
            &:first-of-type {
                
                td {
                    background:$k_kelly;
                    font-weight:$bold;
                    text-transform:uppercase;
                }
            }
        }
    }
}*/

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}