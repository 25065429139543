/**********************************************************************************************/
/***** RESET **********************************************************************************/
/**********************************************************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**********************************************************************************************/
/***** STRUCTURAL *****************************************************************************/
/**********************************************************************************************/
html {
    height:100%;
}
body {
    height:auto !important;
    min-height:100%;
    //min-height:1800px; //FOR DEV ONLY
    //padding-top:1px;
    box-sizing:border-box;
    font-family: $mundial;
    font-weight: $light;
    font-size: 16px; //20px desktop
	line-height: 1.4em;
    color: $leafy_green;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: $leafy_green_400;
}

#page {
	width:100%;
	height:100%;
	margin:0 auto;
}

#main {
    width:100%;
    margin-top:0;
        
    &.sticky {
        margin:$header_h 0 0;
    }
    
    .landingpage-noheader & {
        margin:0 !important;
    }
}

#primary {
    /*float:none;
    width:100%;*/
    box-sizing:border-box;
}

#secondary {
    /*float:none;
    width:100%;*/
    
    .body-gatedcontent-preform & {
        display:none !important;
    }
}

#content {
    position:relative;
    width:100%;
    //min-height:1800px; /****************** TEMP FOR DEV *****/
    margin:0 auto;
    background:none;
    box-sizing:border-box;
    z-index:10;
    overflow:hidden;
    
    > article {
        //background: $whipped_cream_200;
        
        .single-post & {
            //background: $whipped_cream_200;
        }
                
        > .gform_wrapper {
            padding-top:0;
            width:calc(100% - 80px);
            max-width:1206px;
            margin:0 auto 60px;
        }
    }
}

.content_section {
    position:relative;
    width:100%;
    /*overflow:hidden;*/
    text-align:left;
    background: none;
    
    &.content_wrapper {
        z-index:10;
    }
    
    &.leafy_green_400 {
        //background: $leafy_green_400;
        .content_section_inner {
            background: $leafy_green_400;
        }
    }
    
    &.leafy_green {
        //background: $leafy_green;
        .content_section_inner {
            background: $leafy_green;
        }
    }
    
    &.leafy_green_200 {
        //background: $leafy_green_200;
        .content_section_inner {
            background: $leafy_green_200;
        }
    }
    
    &.whipped_cream_400 {
        //background: $whipped_cream_400;
        .content_section_inner {
            background: $whipped_cream_400;
        }
    }
    
    &.whipped_cream_200 {
        //background: $whipped_cream_200;
        .content_section_inner {
            background: $whipped_cream_200;
        }
    }
    
    &.whipped_cream {
        //background: $whipped_cream;
        .content_section_inner {
            background: $whipped_cream;
        }
    }
    
    &.leafy_green_400,
    &.leafy_green_200,
    &.leafy_green,
    &.k_kelly {
        > * {
            color: $whipped_cream;
        }
        
        h1,h2,h3,h4,h5,h6 {
            color:$whipped_cream;
        }
        
        .wysiwyg {
            
            a {
                color: $whipped_cream;
                text-decoration-color: $whipped_cream;
                
                &:hover {
                    text-decoration-color:$k_kelly;
                }
            }
        }
    }
    
    &.flex_layouts_wrap {
        padding-bottom:60px;
    }
    
    //DARK INVERSION
    .dark & {
        background:$leafy_green_400 !important;
        
        .entry-content {
            
            > h1,
            > h2,
            > h3,
            > h4,
            > h5,
            > h6,
            > p,
            > ol,
            > ul,
            > hr,
            > span,
            > a,
            > p a,
            > span a,
            > .wp-block-quote,
            > dl,
            > .wp-block-table,
            > .wp-block-preformatted {
                color: $whipped_cream;
            }
            
            > a:hover,
            > p a:hover,
            > span a:hover {
                text-decoration-color:$k_kelly;
            }
        }
    }
    
    .is_legacy {
        
    }
    
    .is_legacy.dark & {
        
        background:$leafy_green_400 !important;
        
        .content_section_inner {
            background:none !important;
        }
    }
}

.content_section_inner {
    width:100%;
    max-width:1420px;
    margin:0 auto;
    padding:60px $mobile_lr_padding;
    text-align:left;
    box-sizing:border-box;
    //background-color: transparent;
    
    &.full {
        width:100%;
        max-width:none;
    }
    
    &.narrow,
    &.narrower {
        width:100%;
        max-width:none;
        padding-left:$mobile_lr_padding;
        padding-right:$mobile_lr_padding;
    }
    
    .flex_layouts {
        padding-bottom:0;
    }
}

.wysiwyg {
    text-align:left;
    
    &.narrow,
    &.narrower {
        width:80%;
        margin:0 auto;
    }
}

/* SPECIFIC FOR BRINGING IN MOBILE NAV AND HAMBURGER */
@media screen and (max-width: #{$bp_mid}) {
    
    body.mobile_nav_open {
        overflow:hidden;
    }
    
    #site-inner {
        position:relative;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    body {
        font-size:20px;
    }
    
    #main {
        margin:0;
    }
    
    .content_section_inner {
        width:calc(100% - 64px);
        padding:60px 0;
        
        .is_legacy & {
            width:100%;
        }
        
        &.narrow {
            width:75%;
            max-width:calc(1420px * 0.75);
            padding-left:0;
            padding-right:0;
        }
        &.narrower {
            width:65%;
            max-width:calc(1420px * 0.65);
            padding-left:0;
            padding-right:0;
        }
    }
    
    .wysiwyg {
    
        &.narrow {
            width:80%;
            margin:0 auto;
        }
        &.narrower {
            width:65%;
            margin:0 auto;
        }
    }

    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}