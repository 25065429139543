/**********************************************************************************************/
/***** PRIMARY NAV ****************************************************************************/
/**********************************************************************************************/
#primary-navigation {
    display:none;
    flex:1 1 auto;
    position:relative;
    margin:0;
    padding:0;
    box-sizing:border-box;
    transition:opacity 0.5s;
    
    .nav-menu {
        position:relative;
        margin:0;
        padding:0;
        list-style:none;
        text-align:left;
        
        > li {
            position:relative;
            display:inline-block;
            margin:0 0 0 -4px;
            transition: all 0.3s ease 0s;
            vertical-align:top;
            text-align:left;
            
            &.btn {
                float:right;
                
                a {
                    display:inline-block;
                    margin:0 0 0 0 !important;
                    padding:16px 30px !important;
                    background:$leafy_green_400 !important;
                    color: $whipped_cream !important;
                    border:1px solid $whipped_cream;
                    cursor:pointer;
                    
                    &:before,
                    &:after {
                        display:none;
                    }
    
                    &:hover,
                    &:focus {
                        background:$lemon_lime !important;
                        color:$leafy_green !important;
                        border:1px solid $lemon_lime;
                    }
                }
            }

            &.search-button {
                
                a {
                    text-indent: -9999px;
                    width: 17px;
                    height: 17px;
                    padding: 0;
                    margin: 17px 24px 0 24px;
                    display: inline-block;
                    background: transparent url('../../images/search.svg');

                    &:after {
                        display:none;
                    }

                    &:before{
                        content: " ";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 17px;
                        height: 17px;
                        display: inline-block;
                        background: transparent url('../../images/search.svg');
                        filter: brightness(0) saturate(100%) invert(48%) sepia(90%) saturate(563%) hue-rotate(88deg) brightness(95%) contrast(88%);
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out; /* Smooth transition */
                    }

                    &:hover:before {
                        opacity: 1;
                    }
                }

            }
            
            a {
                display:block;
                position:relative;
                padding:18px 14px;
                line-height:1em;
                font-family: $lores;
                font-size:0.70em;
                font-weight: $normal;
                color: $whipped_cream;
                text-transform:uppercase;
                text-align:center;
                letter-spacing:0.05em;
                transition:all 0.3s;  
                text-decoration:none;
                border-radius: 0;
                opacity:1;
                
                
                &:before {
                    position:absolute;
                    left:14px;
                    bottom:3px;
                    width:calc(100% - 28px);
                    height:3px;
                    background:transparent url('../../images/nav-button-underline.svg');
                    background-size:100% auto;
                    content:'';
                    transition:opacity 0.15s, bottom 0.3s;
                    opacity:0;
                }
        
                &:after {
                    position:absolute;
                    right:14px;
                    bottom:3px;
                    width:3px;
                    height:3px;
                    content:'';
                    background:$k_kelly;
                    transition:opacity 0.15s, bottom 0.3s;
                    opacity:0;
                }
                
            }
            
            &:hover > a,
            &:focus > a,
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                
                &:before,
                &:after {
                    bottom:10px;
                    opacity:1;
                }
            }
            
            
            &:hover .sub-menu,
            &:focus .sub-menu {
                left:0;
                margin-top:0;
            }
        
            &.flip:hover .sub-menu,
            &.flip:focus .sub-menu {
                left:auto;
                right:0;
            }
        }
        
        ul {
            position: absolute;
            top:100%;
            left: -999em;
            //left:0;
            width:340px;
            min-width:100%;
            margin:0;
            background:$k_kelly;
            padding:0 0 20px;
            z-index:1000;
            box-sizing:border-box;            
            
            li {
                position:relative;
                display:block;
                margin:0;
                background:none;
                overflow:hidden;
                
                a {
                    display:block;
                    width:100%;
                    box-sizing:border-box;
                    vertical-align:middle;
                    line-height:1.3em;
                    font-family:$lores;
                    font-weight:$normal;
                    height:auto;
                    padding:12px 24px;
                    color:$whipped_cream;
                    transition:all 0.3s;
                    text-align:left;
                    border-radius:0;
                    text-decoration:none;
                    
                    .home & {
                        padding:12px 24px;
                    }
                    
                    .scrolling & {
                        
                    }
                    
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $k_kelly;
                    }
                    
                }
                
                &.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current-page-ancestor a {
                    color: $k_kelly;
                    background:$k_kelly !important;
                                                        
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $k_kelly;
                        background:$k_kelly !important;
                    }
                }
                
                &:first-of-type {
                    
                    a {
                        //border:none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: 1200px) {
    
    #primary-navigation {
        
        .nav-menu {
            margin:0 0 0 -10px;
        
            > li {
            
                &.btn {
                    
                    a {
                        margin:0 0 0 30px !important;
                    }
                }
            
                a {
                    padding:18px 18px;
                    font-size:0.77em;
                    
                    &:before {
                        left:18px;
                        width:calc(100% - 36px);
                    }
        
                    &:after {
                        right:18px;
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    #primary-navigation {
        
        .nav-menu {
            margin:0 0 0 -20px;
        
            > li {
            
                &.btn {
                    
                    a {
                        margin:0 0 0 100px !important;
                    }
                }
            
                a {
                    padding:18px 24px;
                    
                    &:before {
                        left:24px;
                        width:calc(100% - 48px);
                    }
        
                    &:after {
                        right:24px;
                    }
                }
            }
        }
    }
    
}

/* MOBILE NAV */
#primary-navigation-mobile {
    display:block;
    width:100%;
    padding:0;
    
    .nav-menu {
        list-style:none;
        margin:0;
        padding:0;
                
        > .menu-item-has-children {
            
            > .toggle {
                display:none;
                border:none;
                background:none;
            }
        }
        
        > li {
            margin:0;
            border:none;
            
            &.btn {
                /*position:absolute;
                left:26px;
                bottom:26px;*/
                margin-top:1.5em;
                width:100%;
                
                a {
                    display:block;
                    margin:0;
                    padding:22px 30px !important;
                    background:$k_kelly !important;
                    color: $leafy_green !important;
                    border:1px solid $k_kelly;
                    cursor:pointer;
                    
                    &:before,
                    &:after {
                        display:none;
                    }
    
                    &:focus {
                        background:$lemon_lime;
                    }
                }
            }
            
            > a {
                position:relative;
                display:inline-block;
                vertical-align:top;
                width:auto;
                font-size:0.9em;
                line-height:1em;
                font-family: $lores;
                font-weight: $normal;
                border:none;
                padding:22px 0;
                color: $whipped_cream;
                text-decoration:none;
                text-transform:uppercase;
                letter-spacing:0.05em;
                transition:all 0.3s;
                
                &:before {
                    position:absolute;
                    left:0;
                    bottom:3px;
                    width:100%;
                    height:3px;
                    background:transparent url('../../images/nav-button-underline.svg');
                    background-size:100% auto;
                    content:'';
                    transition:opacity 0.15s, bottom 0.3s;
                    opacity:0;
                }
        
                &:after {
                    position:absolute;
                    right:0;
                    bottom:3px;
                    width:3px;
                    height:3px;
                    content:'';
                    background:$k_kelly;
                    transition:opacity 0.15s, bottom 0.3s;
                    opacity:0;
                }
            }
            
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                
                &:before,
                &:after {
                    bottom:10px;
                    opacity:1;
                }
            }
        }
        
        ul {
            display:none;
            list-style:none;
            width:100%;
            margin:0;
            padding:6px 30px 6px 0;
            background:none;
            border-top:none;
            
            li {
                
                
                a {
                    display:block;
                    font-weight:$normal;
                    font-size:0.833em;
                    color: $k_kelly;
                    font-weight:$normal;
                    text-transform:none;
                    text-decoration:none;
                    margin:0;
                    padding:5px 0;
                    transition: all 0.3s ease 0s;
                    
                    &:hover {
                        color: $k_kelly;
                    }
                }
                
                /*&.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current_page_parent a,
                &.current_page_ancestor a,
                &.current-page-ancestor a {
                    
                    
                }*/
                
                > .toggle {
                    display:none;
                }
            }
        }
        
        .current-menu-item,
        .current-menu-ancestor,
        .current-post-ancestor,
        .current_page_parent,
        .current_page_ancestor,
        .current-page-ancestor {
            
            .sub-menu {
                display:none;
            }
        }
        
        .current-menu-item > a {
            
        }
    }
}

.menu-search-form{
    display: block !important;
}
.search-button{
    display: none !important;
}

.menu-search-form {
	margin-top: 1.5em !important;

    .searchform{
        display: flex;
    }

	.input-wrapper {
        width: initial;
        display: flex;
        flex-grow: 1;

		input[type="text"] {
			width: 100%;
			padding: 15px;
			border-radius: 0;
			height: 48px;
			box-sizing: border-box;
			font-family: mundial, sans-serif;
			font-weight: 300;
			font-size: 16px;
			border: 1px solid rgba(248, 245, 236, 0.5);
			-webkit-appearance: none;
			transition: all .3s;
			background: transparent;
			color: #fff;
		}
		input[type="text"]:focus {
			outline: none;
		}
		::placeholder {
			color: rgba(248, 245, 236, 0.5);
		}
	}
	.select-wrapper {
		display: none;
	}

	input[type="submit"] {
		vertical-align: top;
		background: url('../../images/search-black.svg') 24px center / 16px 16px no-repeat !important;
		background-color: #0EB747 !important;
		padding: 14px 24px 14px 48px;
		height: 48px;
	}

    .button-wrapper {
        width: 130px;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    #primary-navigation {
        display:block;
    }
    #primary-navigation-mobile {
        display:none;
    }
    .menu-search-form{
        display: none !important;
    }
    .search-button{
        display: inline-block !important;
    }
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}
