/**********************************************************************************************/
/***** BLOCK: WHAT TO EXPECT ******************************************************************/
/**********************************************************************************************/
.content_section.what_to_expect {
    
    &.has_pixels {
        
        .content_section_inner {
            padding-top:9em !important;
            padding-bottom:7em !important;
        }
    }
    
    .content_section_inner {
        position:relative;
        padding-top:9em;
        padding-bottom:7em;
        z-index:10;
    }
    
    .left_cont {
        margin-bottom:4em;
        padding:0;
    
        > :first-child {
            margin-top:0;
        }
    }
    
    .right_cont {
        padding:0;
    }
    
    .cont {
        padding:0;
        
        > :first-child {
            margin-top:0;
        }
    }
    
    .grid_wrap {
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: auto;
        grid-column-gap: 0;
        grid-row-gap: 48px;
        width:100%;
        max-width:none;
        margin:0 auto;
        padding:0;
        z-index:12;
    }
    
    .gridblock {
        position:relative;
        display:block;
        border:none;
        background:none;
        text-decoration:none;
        
        .inner {
            width:100%;
            height:100%;
            padding:0;
            box-sizing:border-box;
        }
        
        h4 {
            margin:0 0 0.5em;
        }
        
        p {
            margin:0;
            font-size:0.8em;
        }
    }
    
    .icon {
        margin:0 0 30px;
        height:80px;
        
        svg {
            height:100%;
            width:auto;
            
            .pixel_icon {
                transition:all 0.3s;
                
                > path {
                    transition:all 0.3s;
                }
            }
        }
        
        &.k_kelly {
            
            svg .pixel_icon {
                fill:$k_kelly;
                
                > path {
                    fill:$k_kelly;
                }
            }
        }
        
        &.leafy_green {
            
            svg .pixel_icon {
                fill:$leafy_green;
                
                > path {
                    fill:$leafy_green;
                }
            }
        }
        
        &.rhubarb {
            
            svg .pixel_icon {
                fill:$rhubarb;
                
                > path {
                    fill:$rhubarb;
                }
            }
        }
        
        &.rhubarb_200 {
            
            svg .pixel_icon {
                fill:$rhubarb_200;
                
                > path {
                    fill:$rhubarb_200;
                }
            }
        }
        
        &.rhubarb_400 {
            
            svg .pixel_icon {
                fill:$rhubarb_400;
                
                > path {
                    fill:$rhubarb_400;
                }
            }
        }
        
        &.lemon_lime {
            
            svg .pixel_icon {
                fill:$lemon_lime;
                
                > path {
                    fill:$lemon_lime;
                }
            }
        }
        
        &.lemon_lime_200 {
            
            svg .pixel_icon {
                fill:$lemon_lime_200;
                
                > path {
                    fill:$lemon_lime_200;
                }
            }
        }
        
        &.lemon_lime_400 {
            
            svg .pixel_icon {
                fill:$lemon_lime_400;
                
                > path {
                    fill:$lemon_lime_400;
                }
            }
        }
        
        &.plum {
            
            svg .pixel_icon {
                fill:$plum;
                
                > path {
                    fill:$plum;
                }
            }
        }
        
        &.plum_200 {
            
            svg .pixel_icon {
                fill:$plum_200;
                
                > path {
                    fill:$plum_200;
                }
            }
        }
        
        &.plum_400 {
            
            svg .pixel_icon {
                fill:$plum_400;
                
                > path {
                    fill:$plum_400;
                }
            }
        }
        
        &.pink_salt {
            
            svg .pixel_icon {
                fill:$pink_salt;
                
                > path {
                    fill:$pink_salt;
                }
            }
        }
        
        &.pink_salt_200 {
            
            svg .pixel_icon {
                fill:$pink_salt_200;
                
                > path {
                    fill:$pink_salt_200;
                }
            }
        }
        
        &.pink_salt_400 {
            
            svg .pixel_icon {
                fill:$pink_salt_400;
                
                > path {
                    fill:$pink_salt_400;
                }
            }
        }
        
        &.leafy_green_200 {
            
            svg .pixel_icon {
                fill:$leafy_green_200;
                
                > path {
                    fill:$leafy_green_200;
                }
            }
        }
        
        &.leafy_green_400 {
            
            svg .pixel_icon {
                fill:$leafy_green_400;
                
                > path {
                    fill:$leafy_green_400;
                }
            }
        }
    }
    
    .post_grid_cta {
        
        .btn {
            margin:3em 0 0;
        }
    }
    
    .pixels_wrap {
        z-index:-1;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.what_to_expect {
        
        .left_cont {
            margin-bottom:0;
        }
        
        .right_cont {
            padding-left:32px;
        }
        
        .grid_wrap {
            display: block;
        }
        
        .gridblock {
            margin-bottom:2em;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .content_section.what_to_expect {
        
        .grid_wrap {
            display:grid;
            grid-template-columns: repeat(2, calc(50% - 24px));
            grid-column-gap: 48px;
            max-width:none;
        }
        
        .gridblock {
            margin-bottom:0;
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}