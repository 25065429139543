.service__list .content_section_inner {
  position: relative;
}

.service__list .pixels_wrap .two {
  right: 0;
  left: auto;
}

.service__list .pixels_wrap .three {
  left: auto;
  right: 0;
  top: 35%;
}

.service__list .pixels_wrap .four {
  left: auto;
  right: 8.51%;
  top: 38.4%;
}

.service__list .pixels_wrap .five {
  top: auto;
  bottom: 0;
}

@media (max-width: 1399px) {
  .service__list .pixels_wrap .three,
  .service__list .pixels_wrap .four {
    display: none;
  }
}

.service__list .cont h3 {
  font-family: mundial, sans-serif;
  text-transform: uppercase;
  color: #065f25;
}

@media screen and (max-width: 767px) {
  .service__list .cont h3 {
    margin-top: 1rem;
  }
}

.services-callout {
  max-width: 1183.33px;
  margin: 2rem auto;
  padding: 2rem;
}

@media (min-width: 1000px) {
  .services-callout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .services-callout {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.services-callout section {
  max-width: 475px;
  margin-bottom: 3rem;
}

@media (min-width: 1000px) {
  .services-callout section {
    width: 50%;
  }

  .services-callout section:nth-child(3n + 2) {
    margin-top: 20%;
  }

  .services-callout section:nth-child(3n + 3) {
    margin-left: 25%;
  }

  .services-callout section p {
    padding-left: calc(60px + 0.75rem);
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .services-callout section:nth-child(2n + 2) {
    margin-left: 30%;
  }
}

.services-callout .icon {
  width: 40px;
  position: relative;
  top: -7px;
}

.services-callout h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .services-callout .icon {
    width: 60px;
  }
}
