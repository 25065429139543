/**********************************************************************************************/
/***** AUTHOR DETAIL **************************************************************************/
/**********************************************************************************************/
.archive.author {
    
    .bio {
        
        .content_section_inner {
            position:relative;
            padding-top:7em;
        }
        
        .info {
            display:block;
            position:relative;
            margin:0 0 5em;
            max-width:400px;
            padding:0;
            z-index:10;
        }
          
        .icon {
            position:absolute;
            right:0;
            top:3em;
            width:40%;
            transition:all 0.3s;
            z-index:-1;
            
            svg {
                display:block;
                width:100%;
                height:auto;
                .pixel_icon {
                    fill:$rhubarb;
                    transition:all 0.3s;
                    
                    > path {
                        fill:$rhubarb;
                        transition:all 0.3s;
                    }
                }
            }
            
            &.k_kelly {
            
                svg .pixel_icon {
                    fill:$k_kelly;
                    
                    > path {
                        fill:$k_kelly;
                    }
                }
            }
    
            &.leafy_green {
        
                svg .pixel_icon {
                    fill:$leafy_green;
                    
                    > path {
                        fill:$leafy_green;
                    }
                }
            }
    
            &.rhubarb {
        
                svg .pixel_icon {
                    fill:$rhubarb;
                    
                    > path {
                        fill:$rhubarb;
                    }
                }
            }
    
            &.rhubarb_200 {
        
                svg .pixel_icon {
                    fill:$rhubarb_200;
                    
                    > path {
                        fill:$rhubarb_200;
                    }
                }
            }
    
            &.rhubarb_400 {
        
                svg .pixel_icon {
                    fill:$rhubarb_400;
                    
                    > path {
                        fill:$rhubarb_400;
                    }
                }
            }
    
            &.lemon_lime {
        
                svg .pixel_icon {
                    fill:$lemon_lime;
                    
                    > path {
                        fill:$lemon_lime;
                    }
                }
            }
    
            &.lemon_lime_200 {
        
                svg .pixel_icon {
                    fill:$lemon_lime_200;
                    
                    > path {
                        fill:$lemon_lime_200;
                    }
                }
            }
    
            &.lemon_lime_400 {
        
                svg .pixel_icon {
                    fill:$lemon_lime_400;
                    
                    > path {
                        fill:$lemon_lime_400;
                    }
                }
            }
    
            &.plum {
        
                svg .pixel_icon {
                    fill:$plum;
                    
                    > path {
                        fill:$plum;
                    }
                }
            }
    
            &.plum_200 {
        
                svg .pixel_icon {
                    fill:$plum_200;
                    
                    > path {
                        fill:$plum_200;
                    }
                }
            }
    
            &.plum_400 {
        
                svg .pixel_icon {
                    fill:$plum_400;
                    
                    > path {
                        fill:$plum_400;
                    }
                }
            }
    
            &.pink_salt {
        
                svg .pixel_icon {
                    fill:$pink_salt;
                    
                    > path {
                        fill:$pink_salt;
                    }
                }
            }
    
            &.pink_salt_200 {
        
                svg .pixel_icon {
                    fill:$pink_salt_200;
                    
                    > path {
                        fill:$pink_salt_200;
                    }
                }
            }
    
            &.pink_salt_400 {
        
                svg .pixel_icon {
                    fill:$pink_salt_400;
                    
                    > path {
                        fill:$pink_salt_400;
                    }
                }
            }
    
            &.leafy_green_200 {
        
                svg .pixel_icon {
                    fill:$leafy_green_200;
                    
                    > path {
                        fill:$leafy_green_200;
                    }
                }
            }
    
            &.leafy_green_400 {
        
                svg .pixel_icon {
                    fill:$leafy_green_400;
                    
                    > path {
                        fill:$leafy_green_400;
                    }
                }
            }
        }
        
        .headshot {
            position:relative;
            padding-right:32px;
            padding-bottom:115%;
            height:0;
            margin:0 0 1.25em;
            transition:all 0.3s;
            
            img {
                display:block;
                position:absolute;
                left:0;
                bottom:0;
                width:85%;
            }
        }
        
        .loc {
            margin:0 0 1em;
        }
        
        .cont {
            padding:0;
        }
    }
    
    .team_social {
        display:inline-block;
        margin:0;
        vertical-align:middle;
    }
        
    .social {
        display:inline-block;
        position:relative;
        width:22px;
        height:22px;
        margin:0 24px 0 0;
        background: none;
        text-indent:-9999px;
        vertical-align:middle;
        transition:all 0.3s;
        color: $k_kelly;
    
        &.twitter {
            width:22px;
        }
    
        &.linkedin {
            width:22px;
        }
        
        &.instagram {
            width:22px;
        }
    
        &.youtube {
            width:20px;
        }
    
        &.github {
            width:21px;
        }
    
        &.drupal {
            width:20px;
        }
    
        span {
            display:block;
            text-align:left;
        }
    
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:50%;
            width:100%;
            height:auto;
            margin:0;
            transition: all 0.3s;
            transform:translate(-50%,-50%);
        
            .shape {
                transition: fill 0.3s;
                fill:$k_kelly;
            }
        }
    
        &:hover {
            svg {
                .shape {
                    fill:$leafy_green;
                }
            }
        }
    }
    
    .content_section.posts_grid {
    
        .content_section_inner {
            position:relative;
            padding:6em 0 4em;
        }
            
        .intro {
            margin-bottom: 4em;
            text-align:center;
        }
    
        h2 {
            margin:0 0 0.25em;
        }
    
        .byline {
            font-weight:$light;
        }
    
        .posts_grid_wrap {
            display: block;
            justify-content:center;
            align-items:flex-start;
            margin:0;
            padding:0 26px;
        }
    
        .gridblock {
            text-align:left;
            
            &:first-of-type {
                margin-bottom:5em;
            }
        }
        
        .btn.see_all {
            margin:4em auto 0;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .archive.author {
    
        .bio {
            
            .info {
                margin:0;
                padding:0 0 0 32px;
            }
        
            .cont {
                padding:5em 0 0 32px;
            }
        }
        
        .content_section.posts_grid {
            
            .intro {
                margin-bottom: 2em;
            }
            
            .posts_grid_wrap {
                display: flex;
            }
    
            .gridblock {
                flex:0 0 calc(50% - 20px);
            }
        }
    }
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}