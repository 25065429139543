/**********************************************************************************************/
/***** BLOCK: CONTENT SECTION *****************************************************************/
/**********************************************************************************************/
.content_section.csection {
    
    &.legacy {
        
        .entry-content {
            
            > :first-child {
                margin-top:0;
            }
        }
        
        &.has_img {
            
            > .content_section_inner > .pixels_wrap {
                .pixel.one,
                .pixel.two,
                .pixel.three {
                    display:none;
                }
            }
        }
    }
    
    
    .content_section_inner {
        position:relative;
        padding-top:9em;
        padding-bottom:7em;
        z-index:10;
        
        &.small_p_top {
            padding-top:2em;
        }
        
        &.small_p_btm {
            padding-bottom:2em;
        }
        
        &.has_pixels {
            padding-top:9em !important;
            padding-bottom:7em !important;
        }
    }
    
    .left_cont {
    
        > :first-child {
            margin-top:0;
        }
    }
    
    .cont {
        padding:0;
        
        &.centered {
            padding:0;
        }
        
        > :first-child {
            margin-top:0;
        }
        
        &.single_col {
            padding:0 8.333%;
        }
    }
    
    .two_col_wrap {
        padding:0;
        
        .cont1 {
            margin:0 0 1.67em;
            padding:0;
        }
        
        .cont2 {
            padding:0;
        }
    }
    
    //this scenario should not exist, but in case it does:
    .contentupgrade-wrapper {
        margin:0;
        //background:$whipped_cream_400;
        border:none;
        
        .content_section,
        .content_section_inner,
        .grid,
        .cu_wrap {
            position:static !important;
            float:none !important;
            margin:0 !important;
            padding:0 !important;
            width:auto !important;
        }
    }
    
    .pixels_wrap {
        z-index:-1;
    }
}

@media only screen and (max-width: 480px) {
    
    .content_section.csection {
    
        .cont {
            
            &.single_col {
                padding:0;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.csection {
        
        &.legacy {
            
            .content_section_inner {
                
                &.small_p_top {
                    padding-top:1em;
                }
        
                &.small_p_btm {
                    padding-bottom:1em;
                }
            
                &.has_pixels {
                    padding-top:5em !important;
                }
            }
        }
    
    
        .content_section_inner {
            padding-top:6em;
            padding-bottom:4em;
        
            &.small_p_top {
                padding-top:1em;
            }
        
            &.small_p_btm {
                padding-bottom:1em;
            }
            
            &.has_pixels {
                padding-top:7em !important;
                padding-bottom:4em !important;
            }
        }
    
        .cont {
            //padding:0 0 0 32px;
            
            &.no_heading {
                padding-left:0;
            }
            
            &.centered {
                padding:0 40px;
            }
            
            &.single_col {
                padding:0 40px;
            }
        }
        
        .two_col_wrap {
                
            .cont1 {
                padding:0 2em 0 0;
            }
        
            .cont2 {
                padding:0 0 0 2em;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}