/**********************************************************************************************/
/***** FORM STYLES ****************************************************************************/
/**********************************************************************************************/
#content {
    
    .csection .gform_wrapper {
        margin-top:2em;
    }
    
    .gform_wrapper {
        margin:0;
        padding:0;
    
        * {
            box-sizing:border-box;
        }
    
        .gform_fields {
            margin:0 !important;
            padding:0 !important;
            list-style:none !important;
            grid-column-gap: 20px;
        
            li,
            .gfield {
                position:relative;
                width:100%;
                margin:1.25em 0 0;
                padding:0;
                vertical-align:top;
                text-align:left;
                list-style:none !important;
                z-index:100;
                
                &:first-of-type {
                    //margin-top:0;
                }
                
                &.no_float {
                
                    .gfield_label {
                        position:static;
                    }
                }
            
                &.msg {
                    margin-top:20px;
        
                    label,
                    .gfield_label {
                        left:0;
                        width:100%;
                    }
                }
                
                &.gfield_html {
                    margin-bottom:1.25em;
                    margin-top:0;
                }
                
                &.gfield--type-captcha {
                    margin:0;
                }
                
                &.gfield--type-radio,
                &.gfield--type-checkbox {
                    
                    .gfield_label,
                    .ginput_complex label {
                        margin-bottom:0.5em;
                        height:auto;
                        line-height:1.2em;
                        white-space:normal;
                    }
                }
            
                &:before {
                    display:none !important;
                }
            
                &.gform_hidden {
                    display:none;
                    margin:0 !important;
                }
                
                &.recaptcha_field {
                    margin:0 !important;
                }
                
                &.field_sublabel_below.hidden_label {
                    margin:0 !important;
                }
                
                &.gfield_nfprivacy {
                    margin-top:0 !important;
                }
            
            }
        }
    
        .gfield_label,
        .ginput_complex label {
            display:inline-block;
            position:absolute;
            left:7px;
            top:1px;
            width:auto;
            margin:0;
            padding:0 8px;
            font-size:16px;
            font-weight:$light;
            color: rgba(15,44,21,0.7);
            background:$whipped_cream_200;
            height:44px;
            line-height:44px;
            z-index:106;
            user-select:none;
            pointer-events:none;
            white-space: nowrap;
            transition:all 0.3s;
        
            .gfield_required {
                position: relative;
                display:inline-block;
                top:0;
                margin-left:0;
                //font-size: 12px;
                //color: $k_kelly;
            }
            
            &.gfield_label_before_complex {
                position:static;
                margin:0 0 2em;
                padding:0;
                font-size:16px;
                color: rgba(15,44,21,0.5);
                
                .gfield_required {
                    //color: $k_kelly;
                }
            }
            
            #secondary &,
            .page-template-page-contact & {
                
            }
            
            &.float_label {
                top:-0.5em;
                font-size:14px;
                height:1em;
                line-height:1em;
                color:rgba(15,44,21,0.5);
                
                .gfield_required {
                    //color: $k_kelly;
                }
            
                #secondary & {
                    //left:0;
                    //top:-15px;
                }
            }
        
        }
        
        legend.gfield_label {
            position:relative;
            left:0;
            padding:0;
        }
    
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='email'],
        textarea {
            width:100%;
            padding:15px;
            border:none;
            height:46px;
            border-radius:0;
            border:none;
            font-family:$mundial;
            font-weight:$light;
            font-size:16px;
            line-height:1em;
            border:1px solid rgba(15,44,21,0.3);
            -webkit-appearance:none;
            letter-spacing:normal;
            color:rgba(15,44,21,1);
            background:none;
            transition:all 0.3s;
            
            &:hover {
                border-color:$k_kelly;
            }
                
            &:focus {
                border-color:$leafy_green;
            }
        }
    
        textarea {
            height:7em; //this allows for four lines w/out scrollbar on desktop
            line-height:1.3em;
        }
    
        input[type='hidden'] {
            display:none;
        }
    
        .field_sublabel_below {
        
            .ginput_container label {
                font-size:14px;
                font-style:italic;
                color: $k_kelly;
            }
        }
    
        .instruction {
            padding:0;
            font-size:14px;
            line-height:1.3em;
            font-style:italic;
            color: $k_kelly;
        }
    
        .ginput_container_select,
        .ginput_container_text,
        .ginput_container_email,
        .ginput_container_phone,
        .ginput_container_date,
        .ginput_container_website,
        .ginput_container_number {
            margin:0;
            border:none;
        }
    
        .ginput_container_textarea {
            margin:0;
        }
        
        .ginput_container_select,
        .ginput_complex .address_state {
            position:relative;
            cursor:pointer;
            background:none;
            z-index:105;
            
            &:hover {
                
                &:after {
                    transform:translateY(-50%) rotate(180deg);
                    opacity:1;
                }
            }
        
            &:after {
                position:absolute;
                right:10px;
                top:50%;
                //content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'%3E%3Cg fill='%230F2C15' fill-rule='nonzero' opacity='1.0'%3E%3Cpath d='M7,8.5 C6.7,8.5 6.5,8.4 6.3,8.2 L0.3,2.2 C-0.1,1.8 -0.1,1.1 0.3,0.7 C0.7,0.3 1.4,0.3 1.8,0.7 L7,6 L12.2,0.8 C12.6,0.4 13.3,0.4 13.7,0.8 C14.1,1.2 14.1,1.9 13.7,2.3 L7.7,8.3 C7.5,8.4 7.3,8.5 7,8.5 Z' %3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                content:'';
                text-align:center;
                z-index:-1;
                width: 14px; 
                height: 9px;
                background:transparent url('../../images/select-caret.svg') no-repeat center center;
                transform:translateY(-50%);
                opacity:0.7;
                transition:all 0.3s;
            }
        
            select {
                border: none;
                border:1px solid rgba(15,44,21,0.3);
                outline: 0;
                margin:0;
                padding:14px 30px 14px 15px;
                font-family:$mundial;
                font-size:16px;
                font-weight: $light;
                line-height:1em;
                color:rgba(15,44,21,0.7);
                width:100%;
                background:none;
                border-radius:0;
                -webkit-user-select: none;
                -moz-user-select: -moz-none;
                -ms-user-select: none;
                user-select: none;
                -webkit-appearance: none;
                -moz-appearance: radio-container;
                appearance: none;
                white-space:pre-wrap;
                transition:all 0.3s;
                z-index:110;
                cursor:pointer;
                
                &:hover {
                    border:1px solid $k_kelly;
                    color:rgba(15,44,21,1);
                }
                
                &:focus {
                    border:1px solid rgba(15,44,21,1);
                    color:rgba(15,44,21,1);
                }
            }
        
            select::-ms-expand {
                display: none;
            }
        }
        
        .ginput_container_radio {
            margin-top:0;
        }
        
        .gfield_radio {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                width:auto !important;
                margin:0;
                vertical-align:top;
                font-size:100% !important;
            }
            
            label {            
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0 0 5px;
                padding: 0 25px;
                font-size: 14px !important;
                font-weight:$light !important;
                font-style:normal !important;
                height:1.2em;
                max-width:none !important;
                line-height:1em;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $leafy_green !important;
        
                &:before {
                    position:absolute;
                    left:4px;
                    top:3px;
                    width:10px;
                    height:10px;
                    border-radius:50%;
                    content:'';
                    box-shadow: inset 15px 15px $leafy_green;
                    transition: 0.2s transform ease-in-out;
                    transform: scale(0);
                }
                
                &:after {
                    position:absolute;
                    left:0;
                    top:-1px;
                    width:16px;
                    height:16px;
                    border-radius:50%;
                    border:1px solid $leafy_green;
                    content:'';
                    z-index:-1;
                }
            }
        
            input[type='radio'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
        
                &:checked {
            
                    ~ label:before {
                        transform: scale(1);
                    }
                }
            }
        }
        
        .gfield_checkbox {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                width:auto !important;
                margin:0 !important;
                vertical-align:top;
                font-size:100% !important;
            }
        
            label {
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0;
                padding: 0 20px 0 25px;
                font-size: 14px !important;
                font-weight: $light !important;
                height:1.2em;
                max-width:none !important;
                line-height:1.2em;
                font-style:normal !important;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $leafy_green !important;
            
                #secondary & {
                    margin:0;
                    font-size:0.9em !important;
                
                    &:before {
                        
                    }
                }
                
                &:before {
                    position:absolute;
                    left:0;
                    top:50%;
                    width:15px;
                    height:15px;
                    content:'';
                    border:1px solid $leafy_green;
                    background:none;
                    text-align:center;
                    transform:translateY(-50%);
                }

				&:after {
					content: '';
					display: none;
					width: 3px;
					height: 6px;
					position: absolute;
					left: 6px;
					top: calc(50% - 5px);
					border: solid #FFFDF6;
					border-width: 0px 2px 2px 0;
					-ms-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
            }
        
            input[type='checkbox'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
            
                &:checked {
                
                    ~ label:before {
                        //background-image: url(../../images/checkmark-on.png);
                        background: $leafy_green;
                    }

                    ~ label:after {
                        display: block;
                    }
                }
            }
        
            &:hover {
            
            }
        
        }
        
        .no_label {
            .gfield_label {
                display:none;
            }
            .gfield_checkbox label {
                position:relative;
                font-size:14px;
                color: $k_kelly;
                
                &:after {
                    position:absolute;
                    width:10px;
                    height:10px;
                    display:inline-block;
                    top: -0.25em;
                    font-size: 80%;
                    color: $k_kelly;
                    content:'*';
                }
            }
        }
        
        .gfield_contains_required.gfield_nfprivacy {
            .gfield_required {
                display:none !important;
            }
        }
    
        .nfprivacy-field-description {
            margin:0 0 20px;
            font-size:14px;
            line-height:1.3em;
            font-weight:$light;
            color:$leafy_green;
            
            a {
                color:$leafy_green;
                text-decoration:underline;
                text-decoration-thickness:1px;
                text-underline-offset:0.1em;
                text-decoration-color:$leafy_green;
        
                &:hover {
                    text-decoration-color:$k_kelly;
                }
            }
        }
        
        .charleft,
        .ginput_counter {
            display:none !important;
        }
        
        .gform_description {
            margin:0 0 1em;
            line-height:1.3em;
        }
        
        .gform_validation_errors {
            margin:0 0 30px;
            padding:20px 24px;
            border-radius:3px;
            box-shadow:none;
            border:2px solid $v_error_border;
            background:$v_error_bg;
            text-align:left;
            
            .validation_error {
                margin:0;
                padding:0;
                border:none;
                color:$v_error_text;
                font-weight:$light;
                text-align:left;
            
                strong {
                    font-weight:$normal !important;
                }
            }
        }
        
        .gform_required_legend {
            display:none !important;
            margin:20px 0;
            font-size:14px;
            font-style:italic;
        }
        
        .gfield_required {
            color:inherit !important;
        }
        
        .gfield_error {
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            textarea,
            select {
                border:1px solid $v_field_error !important;
            }
        }
        
        .validation_message {
            background:none;
            border:none;
            color:$v_field_error;
            padding:0;
            margin:5px 0 0;
            font-size:16px;
            font-weight: $normal;
            font-style:normal;
            line-height:1.3em;
            
            &.instruction {
                position:absolute;
                right:32px;
                margin:0;
                padding-left:0;
            }
        }
        
        .grecaptcha-badge {
            visibility:hidden !important;
        }
        
        .gform_footer {
            position:relative;
            margin:1em 0 0;
            padding:0;
            text-align:left;
            
            .gform_button {
                display:inline-block;
                position:relative;
                margin:0;
                padding:16px 30px;
                width:auto;
                background:$k_kelly !important;
                font-family: $lores;
                font-size: 0.8em;
                font-weight: $normal;
                font-style:normal;
                line-height:1em !important;
                color: $leafy_green !important;
                border:none;
                text-decoration:none;
                text-transform:uppercase;
                letter-spacing:0.05em;
                box-sizing:border-box;
                text-align:center;
                -webkit-appearance:none;
                transition:all 0.3s;
                cursor:pointer;
    
                &:hover,
                &:focus {
                    background:$lemon_lime !important;
                }
            }
        }
    }
    
    //VERBOSE, BUT MUST HANDLE DARK BG FORMS
    .leafy_green,
    .leafy_green_400,
    .leafy_green_200 {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                color: rgba(248,245,236,0.7);
            
                &.float_label {
                    color:rgba(248,245,236,0.5);
                }
            }
            
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            input[type='email'],
            textarea {
                border:1px solid rgba(248,245,236,0.3);
                color:rgba(248,245,236,1);
                
                &:focus {
                    border-color:$whipped_cream;
                }
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
        
                &:after {
                    background:transparent url('../../images/select-caret-light.svg') no-repeat center center;
                }
        
                select {
                    border:1px solid rgba(248,245,236,0.3);
                    color:rgba(248,245,236,0.7);
                
                    &:hover {
                        color:rgba(248,245,236,1);
                    }
                
                    &:focus {
                        border:1px solid rgba(248,245,236,1);
                        color:rgba(248,245,236,1);
                    }
                }
            }
            
            .gfield_radio {
                
                label {            
                    color: $whipped_cream !important;
        
                    &:before {
                        box-shadow: inset 15px 15px $whipped_cream;
                    }
                
                    &:after {
                        border:1px solid $whipped_cream;
                    }
                }
            }
            
            .gfield_checkbox {
                
                label {
                    color: $whipped_cream !important;
                
                    &:before {
                        color: transparent;
                        border:1px solid $whipped_cream;
                    }
                }
        
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            color:$leafy_green;
                            background: $whipped_cream;
                        }
                    }
                }
        
            }
            
            .nfprivacy-field-description {
                color:$whipped_cream;
            
                a {
                    color:$whipped_cream;
                    text-decoration-color:$whipped_cream;
        
                    &:hover {
                        text-decoration-color:$k_kelly;
                    }
                }
            }
            
            .gform_validation_errors {
                border:2px solid $v_error_border_dark;
                background:$v_error_bg_dark;
            
                .validation_error {
                    color:$whipped_cream;
                }
            }
            
            .gfield_error {
                input[type='text'],
                input[type='number'],
                input[type='tel'],
                textarea,
                select {
                    border:1px solid $v_field_error_dark !important;
                }
            }
        
            .validation_message {
                color:$v_field_error_dark;
            }
        }
        
        .gform_confirmation_message {
            border:2px solid $v_confirm_border_dark;
            background:$v_confirm_bg_dark;
            color:$whipped_cream;
        }
    }
    
    .whipped_cream {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$whipped_cream;
            }
        }
    }
    
    .whipped_cream_200 {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$whipped_cream_200;
            }
        }
    }
    
    .whipped_cream_400 {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$whipped_cream_400;
            }
        }
    }
    
    .leafy_green {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$leafy_green;
            }
        }
    }
    
    .leafy_green_200 {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$leafy_green_200;
            }
        }
    }
    
    .leafy_green_400 {
        
        .gform_wrapper {
            .gfield_label,
            .ginput_complex label {
                background:$leafy_green_400;
            }
        }
    }
    
    .gform_confirmation_wrapper {
        text-align:left;
    }
    
    .gform_confirmation_message {
        margin:0 0 30px;
        padding:20px 24px;
        border-radius:3px;
        box-shadow:none;
        border:2px solid $v_confirm_border;
        background:$v_confirm_bg;
        color:$v_error_text;
        font-weight:$light;
        text-align:left;
        
        strong {
            font-weight:$normal !important;
        }
    }
    
    //SIDEBAR
    #secondary {
        
        
        .gform_wrapper {
            
            .gform_fields {
                display:block;
                
                li,
                .gfield {
                    
                }
            }
            
            .gform_required_legend {
                margin-top:0;
            }
            
            .gform_title {
                margin:0 0 0.5em;
                font-size:1em;
                color:$k_kelly;
            }
            
            .nfprivacy-field-description {
                margin-top:20px;
            }
            
            .gform_description {
                display:block;
                margin-bottom:10px;
                font-size:14px;
                line-height:22px;
                color: $k_kelly;
            }
            
            .gform_footer {
                margin:2em 0 0;
            
                .gform_button {
                    margin:0;
                    font-size:16px;
                
                    &:hover {
                        
                    }
                }
            }
        }
    }
}

::-webkit-input-placeholder {
  color: rgba(0,0,255,0.7);
}
::-moz-placeholder {
  color: rgba(0,0,255,0.7);
}
:-ms-input-placeholder {
  color: rgba(0,0,255,0.7);
}
:-moz-placeholder {
  color: rgba(0,0,255,0.7);
}

#footer_form,
.inline-cta,
#access_gate,
#secondary .contentupgrade {
    
    h2,
    h3,
    p,
    .gform_description {
        color: $leafy_green;
    }
    
    h2 {
        margin:0;
    }
}

//ALL ACCESS GATES (GATED, CONTENT UPGRADE)
#content {
    #access_gate,
    .contentupgrade {
    
        .gform_wrapper {
            
            .gfield_label,
            .ginput_complex label {
                background:$whipped_cream_400 !important;
            }
    
            input[type='text'],
            input[type='number'],
            input[type='email'],
            input[type='tel'],
            textarea {
                border:1px solid rgba(15,44,21,0.3) !important;
                color:rgba(15,44,21,1) !important;
                
                &:hover {
                    border:1px solid $k_kelly !important;
                }
            
                &:focus {
                    border-color:$leafy_green !important;
                }
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
                
                &:after {
                    background:transparent url('../../images/select-caret.svg') no-repeat center center !important;
                }
        
                select {
                    border:1px solid rgba(15,44,21,0.3) !important;
                    color:rgba(15,44,21,0.7) !important;
                    
                    &:hover {
                        border:1px solid $k_kelly !important;
                        color:rgba(15,44,21,1) !important;
                    }
                
                    &:focus {
                        border:1px solid rgba(15,44,21,1) !important;
                        color:rgba(15,44,21,1) !important;
                    }
                }
            }
            
            .gfield_radio {
                
                label {            
                    color: $leafy_green !important;
        
                    &:before {
                        box-shadow: inset 15px 15px $leafy_green;
                    }
                
                    &:after {
                        border:1px solid $leafy_green;
                    }
                }
            }
            
            .gfield_checkbox {
                
                label {
                    color: $leafy_green !important;
                    
                    
                    &:before {
                        color: $whipped_cream_400 !important;
                        border:1px solid $leafy_green !important;
                        background:none;
                    }
                }
        
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            background: $leafy_green !important;
                        }
                    }
                }
        
            }
            
            .gform_required_legend {
                display:none;
                margin:0;
            }
            
            .gform_footer {
                margin-top:1em;
            }
        }
    }
}

#secondary .gform_wrapper,
.page-template-page-contact .gform_wrapper {
    
    margin:0;
    padding:0;
    
    h3,
    .gform_title {
        margin:0 0 0.5em;
    }
    
    p,
    .gform_description {
        
    }
}

//FOOTER FORM, FLOATING
#content #footer_form {
    padding:0;
    
    .content_section_inner {
        position:relative;
        padding:5em 0 7em;
        background:$whipped_cream_200 !important;
        z-index:10;
    }
    
    .left {
        margin-bottom:2em;
        padding:0 26px;
    }
    
    .right {
        padding:0 26px;
        min-height:400px;
    }
    
    .custom_title {
        background:none;
    }
    
    p,
    .gform_description {
        
    }
    
    .pixels_wrap {
        z-index:-1;
    }
    
    .gform_wrapper {
        z-index:11;
        
        .gfield_label,
        .ginput_complex label {
            background:$whipped_cream_200;
            color: rgba(15,44,21,0.7);
        
            &.float_label {
                
            }
        }
        
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='email'],
        textarea {
            border:1px solid rgba(15,44,21,0.3);
            color:rgba(15,44,21,1);
            
            &:focus {
                border-color:$leafy_green;
            }
        }
        
        .ginput_container_select,
        .ginput_complex .address_state {
    
            &:after {
                background:transparent url('../../images/select-caret.svg') no-repeat center center;
            }
    
            select {
                border:1px solid rgba(15,44,21,0.3);
                color:rgba(15,44,21,0.7);
            
                &:hover {
                    color:rgba(15,44,21,1);
                }
            
                &:focus {
                    border:1px solid rgba(15,44,21,1);
                    color:rgba(15,44,21,1);
                }
            }
        }
        
        .gfield_radio {
            
            label {            
                color: $leafy_green !important;
    
                &:before {
                    box-shadow: inset 15px 15px $leafy_green;
                }
            
                &:after {
                    border:1px solid $leafy_green;
                }
            }
        }
        
        .gfield_checkbox {
            
            label {
                color: $leafy_green !important;
            
                &:before {
                    color: transparent;
                    border:1px solid $leafy_green;
                }
            }
    
            input[type='checkbox'] {
                
                &:checked {
            
                    ~ label:before {
                        color:$whipped_cream_200;
                        background: $leafy_green;
                    }
                }
            }
    
        }
        
        .nfprivacy-field-description {
            color:$leafy_green;
        
            a {
                color:$leafy_green;
                text-decoration-color:$leafy_green;
    
                &:hover {
                    text-decoration-color:$k_kelly;
                }
            }
        }
        
        .gform_validation_errors {
            border:2px solid $v_error_border;
            background:$v_error_bg;
        
            .validation_error {
                color:$v_error_text;
            }
        }
        
        .gfield_error {
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            textarea,
            select {
                border:1px solid $v_field_error !important;
            }
        }
    
        .validation_message {
            color:$v_field_error;
        }
    }
    
    .gform_confirmation_message {
        border:2px solid $v_confirm_border;
        background:$v_confirm_bg;
        color:$v_error_text;
    }
    
    .footnote {
        margin:0;
    }
}

//CUSTOMIZE THE LOADING SPINNER
.nf-ajax-loading {
    position:absolute;
    left:50%;
    top:50%;
    margin:-20px 0 0 -20px;
    padding:0;
    width:40px !important;
    height:40px !important;
    min-height:0 !important;
    background-image: url(../../images/loading.svg);
    background-size:40px 40px !important;
    border:none !important;
    background-repeat:no-repeat;
    background-position:center center;
}

.gform_ajax_spinner {
    position:absolute;
    left:-48px;
    top:0;
	margin-left:0px !important;
	padding:0 !important;
	border:none !important;
	width: 40px !important;
	height: 40px !important;
	
	#secondary & {
	    right:-15px;
	    top:5px;
	    width: 30px !important;
	    height: 30px !important;
	}
}

#nfprivacy-modal {
    background:$whipped_cream_200;
    
    .nfprivacy-modal-scrollmessage {
        background:$whipped_cream_200;
    }
    
    .nfprivacy-modal-body-inner {
        padding:80px 20px 20px;
    }
    
    .nfprivacy-modal-close,
    .nfprivacy-modal-confirm {
        display:inline-block;
        position:relative;
        border-radius: 0px;
        margin:0 0 20px 20px;
        padding:16px 30px;
        font-family: $lores;
        font-size:0.8em;
        font-weight:$normal;
        text-decoration:none;
        border:none;
        color:$leafy_green !important;
        text-transform:uppercase;
        letter-spacing:0.05em;
        background:$k_kelly !important;
        text-align: center;
        line-height:inherit;
        -webkit-appearance:none;
        transition:all 0.3s;
        cursor:pointer;
        
        &.nfprivacy-modal-close-top {
            position:absolute;
            top:20px;
            right:20px;
            margin:0;
        }
            
        &:before {
            display:none;
        }
    
        &:hover {
            background:$lemon_lime !important;
        }
    }
    
}



//WEBINAR ACCESS CODE
.webinar-past-form-access-code {
    
    input[type='text'] {
        width:50%;
        max-width:200px;
        padding:8px 10px;
        height:34px;
        border-radius:0;
        border:none;
        font-family:$mundial;
        font-weight:$light;
        font-size:16px;
        line-height:1em;
        border:1px solid $k_kelly;
        -webkit-appearance:none;
        letter-spacing:normal;
        color:$k_kelly;
        background:none;
        transition:border-color 0.3s;
    
        &:focus {
            border-color:$k_kelly;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    #content #footer_form {
        
        .content_section_inner {
            padding:5em 0 3em;
        }
    
        .left {
            padding:0;
        }
        
        .right {
            padding:0;
        }
    
        .custom_title {
            background:transparent url('../../images/pixel-arrow.svg') no-repeat right top;
            background-size: 68px auto;
            padding-right:70px;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}