/**********************************************************************************************/
/***** BLOCK: POSTS GRID **********************************************************************/
/**********************************************************************************************/
.content_section.posts_grid {
    
    
    .content_section_inner {
        position:relative;
        padding-top:6em;
    }
    
    .left_cont {
        padding:0;

        > :first-child {
            margin-top:0;
        }
    }
    
    .cont {
        padding:0 0 60px;
        
        > :first-child {
            margin-top:0;
        }
    }
    
    .posts_grid_wrap {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap:0;
        grid-row-gap:42px;
        margin:0;
    }
    
    .gridblock {
        display:block;
        
        > a {
            display:block;
            position:relative;
            padding-bottom:100px;
            height:100%;
            box-sizing:border-box;
            text-decoration:none;
            
            &:hover {
                
                
                .img {
                
                    .inner {
                        top:-26px;
                        right:-26px;
                        bottom:-26px;
                        left:-26px;
                    }
                    
                    &.has_h_logo {
                        
                        .overlay {
                            @include background-opacity($leafy_green_400, 0.8);
                        }
                    }
                    
                    .overlay {
                        top:-26px;
                        right:-26px;
                        bottom:-26px;
                        left:-26px;
                    }
                    
                    .h_logo {
                        opacity:1;
                    }
                }
                
                .px_wrap {
                        
                    .pixel {
                        transform:translateY(-100%);
                    }
                
                    &.btm {
                        transform:translateY(100%);
                    }
                    
                    &.group1 {
                        
                        .pixel.three {
                            transform:translateY(100%);
                        }
                    }
                    
                    &.group2 {
                        .pixel.three {
                            transform:translateY(100%);
                        }
                    }
                    
                    &.group3 {
                        
                        .pixel.one,
                        .pixel.two {
                            transform:translate(100%,0);
                        }
                    }
                    
                    &.group4 {
                        .pixel.three {
                            transform:translateY(100%);
                        }
                    }
                    
                    &.group5 {
                        .pixel.one,
                        .pixel.two {
                            transform:translate(-100%,0);
                        }
                    }
                }
                
                h4 {
                    color:$k_kelly;
                }
                
                .read_more {
                    &:before,
                    &:after {
                        bottom:10px;
                        opacity:1;
                    }
                }
            }
        }
        
        .img {
            position:relative;
            height:0;
            padding-bottom:60%;
            margin:0 0 42px;
            
            .thumb_wrap {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                height:0;
                padding-bottom:60%;
            }
            
            .inner {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                background-color:$leafy_green_400;
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                transition:all 0.3s;
                overflow:hidden;
            }
            
            .overlay {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                @include background-opacity($leafy_green_400, 0);
                transition:all 0.3s;
            }
            
            .h_logo {
                position:absolute;
                left:50%;
                top:50%;
                display:block;
                max-width:80%;
                transform:translate(-50%,-50%);
                opacity:0;
                transition:opacity 0.3s;
            }
        }
        
        .cont {
            padding-bottom:0;
        }
        
        h4 {
            margin:0 0 0.5em;
            transition:color 0.3s;
        }
        
        .desc {
            margin:0;
            font-size:1em;
            color:$whipped_cream;
        }
        
        .read_more {
            position:absolute;
            left:0;
            bottom:20px;
            
            &:hover {
                &:before,
                &:after {
                    bottom:3px;
                    opacity:0;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.posts_grid {
        
        .left_cont {
            padding-right:32px;
        }
        
        .posts_grid_wrap {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 42px;
            margin:0 -32px;
        }
        
        .gridblock {
            
            > a {
                padding-bottom:90px;
                
                &:hover {
                
                    .img .inner {
                        top:-20px;
                        right:-20px;
                        bottom:-20px;
                        left:-20px;
                    }
                
                    .img .overlay {
                        top:-20px;
                        right:-20px;
                        bottom:-20px;
                        left:-20px;
                    }
                }
            }
            
            .desc {
                font-size:0.8em;
            }
            
            .read_more {
                bottom:10px;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}