/**********************************************************************************************/
/***** SITEWIDE PAGINATION STYLES *************************************************************/
/**********************************************************************************************/
//ALM PAGINATION
.alm-btn-wrap .alm-paging {
    position:relative;
    margin:4em 0;
    
    li {
        display:inline-block;
        
        a {
            position:relative;
            display:block;
            width:auto;
            height:30px;
            margin:0 10px;
            padding:0;
            box-sizing:border-box;
            color: $whipped_cream;
            font-size:0.9em;
            line-height:30px;
            font-weight:$light;
            border:none;
            text-decoration: none;
            transition:color 0.3s;
                
            &:hover,
            &:focus {
                color: $k_kelly;
            }
            
            span {
                
            }
        }
        
        &.prev,
        &.next {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            
            a {
                margin:0;
                
                &:before {
                    position:absolute;
                    top:50%;
                    transform:translateY(-50%);
                    background:none;
                    width:6px;
                    height:6px;
                    border-top:2px solid $whipped_cream;
                    border-left:2px solid $whipped_cream;
                    content:'';
                    transition:border-color 0.3s;
                }
                
                &:hover {
                    
                    &:before {
                        border-top:2px solid $k_kelly;
                        border-left:2px solid $k_kelly;
                    }
                }
            }
        }
        
        &.prev {
            left:0;
            
            a {
                padding-left:16px;
                
                &:before {
                    left:0;
                    transform:translateY(-50%) rotate(-45deg);
                }
            }
        }
        
        &.next {
            right:0;
            
            a {
                padding-right:16px;
                
                &:before {
                    right:0;
                    transform:translateY(-50%) rotate(135deg);
                }
            }
        }
        
        &.disabled {
            display:none;
        }
        
        &.active {
            
            
            a {
                cursor: default;
                color: $k_kelly;
                //border:1px solid $k_kelly;
                text-decoration:underline;
                text-decoration-color:$k_kelly;
                text-decoration-thickness:2px;
                text-underline-offset:0.2em;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}