/**********************************************************************************************/
/***** SITEWIDE BUTTONS ***********************************************************************/
/**********************************************************************************************/
a.btn,
.btn a,
a span.btn,
div.btn,
button.btn,
.wp-block-button__link,
span.rm_link,
input[type='image'],
input[type='submit'],
input[type='button'],
button.gpnf-add-entry {
    display:inline-block;
    position:relative;
    margin:0 0 1em 0;
    padding:16px 30px;
    width:auto;
    background:$k_kelly !important;
    font-family: $lores;
    font-size: 1em;
    font-weight: $normal;
    font-style:normal;
    line-height:1em !important;
    color: $leafy_green !important;
    border:none;
    text-decoration:none !important;
    text-transform:uppercase;
    letter-spacing:0.05em;
    /*text-decoration:underline;
	text-decoration-thickness:0.12em;
	text-underline-offset:0.3em;*/
    box-sizing:border-box;
    text-align:center;
    -webkit-appearance:none;
    transition:all 0.3s;
    cursor:pointer;
    
    &:hover,
    &:focus {
        background:$lemon_lime !important;
    }
    
    &.secondary {
        background:none !important;
        padding:0 !important;
        
        &:before {
            position:absolute;
            left:0;
            bottom:-15px;
            width:100%;
            height:3px;
            background:transparent url('../../images/nav-button-underline.svg');
            background-size:100% auto;
            content:'';
            transition:opacity 0.15s, bottom 0.3s;
            opacity:0;
        }
        
        &:after {
            position:absolute;
            right:0;
            bottom:-15px;
            width:3px;
            height:3px;
            content:'';
            background:$k_kelly;
            transition:opacity 0.15s, bottom 0.3s;
            opacity:0;
        }
        
        &:hover {
            
            &:before,
            &:after {
                bottom:-8px;
                opacity:1;
            }
        }
        
        .dark & {
            color: $whipped_cream !important;
        }
    }
}

a.btn.disabled,
.btn a.disabled,
input[type='image']:disabled,
input[type='submit']:disabled,
input[type='button']:disabled,
button.btn:disabled,
button.gpnf-add-entry:disabled,
.wp-block-button__link.disabled {
    cursor:default;
    color: $whipped_cream_400 !important;
    background: $whipped_cream_200 !important;
    border-color: $whipped_cream_200 !important;
    
    &:hover {
        cursor:default;
        color: $whipped_cream_400 !important;
        background: $whipped_cream_200 !important;
        border-color: $whipped_cream_200 !important;
    }
}

.read_more {
    display:inline-block;
    position:relative;
    padding:10px 0 18px 0;
    line-height:1em;
    font-family: $lores;
    font-size:1em;
    font-weight: $normal;
    color: $whipped_cream;
    text-transform:uppercase;
    text-align:left;
    letter-spacing:0.05em;
    transition:all 0.3s;  
    text-decoration:none;
    border-radius: 0;
    opacity:1;
    
    
    &:before {
        position:absolute;
        left:0;
        bottom:3px;
        width:100%;
        height:3px;
        background:transparent url('../../images/nav-button-underline.svg');
        background-size:100% auto;
        content:'';
        transition:opacity 0.15s, bottom 0.3s;
        opacity:0;
    }

    &:after {
        position:absolute;
        right:0;
        bottom:3px;
        width:3px;
        height:3px;
        content:'';
        background:$k_kelly;
        transition:opacity 0.15s, bottom 0.3s;
        opacity:0;
    }
    
    &:hover {
    
        &:before,
        &:after {
            bottom:10px;
            opacity:1;
        }
    }
}


// GUTENBERG BUTTON STYLES
.wp-block-buttons {
    width:100%;
    max-width:1420px;
    margin-right:auto;
    margin-left:auto;

    .wp-block-button {
        margin-bottom:0;
        margin-right:20px;
        
        &:last-child {
            margin-right:0;
        }
    }
}

.wp-block-button__link {
	border-radius: 0;
}

/* POPUP MAKER (COOKIE NOTICE) ********************/
.pum-theme-default-theme {
    .pum-container {
        display:block !important;
        //left:20px !important;
        //top:auto !important;
        //bottom:20px !important;
        max-width:calc(100% - 40px) !important;
        justify-content:flex-start;
        align-items:center;
        box-shadow:none !important;
        background:$whipped_cream_200 !important;
    }
    .pum-content {
        //flex:0 0 auto;
        padding-left:60px;
        background:url('../../images/pixel-icon-action.svg') no-repeat left center;
        background-size: 28px auto;

        @media screen and (min-width: 1853px) {
            padding-left: 50px;
            background-size: 19px auto;
        }
        
        .pum-close {
            display:none !important;
        }
        p {
            color:$leafy_green !important;
            font-size:16px !important;
            
            a {
                display:inline-block;
                color:$leafy_green !important;
                text-decoration:underline;
                text-decoration-color:$leafy_green;
                text-decoration-thickness:1px;
                text-underline-offset:0.1em;
                
                &:hover {
                    color:$leafy_green !important;
                    text-decoration-color:$k_kelly;
                }
            }
        }
    }
    .pum-close {
        display:inline-block !important;
        flex:0 0 auto;
        position:relative !important;
        left:50% !important;
        margin:20px 0 0 0 !important;
        padding:16px 30px !important;
        width:auto;
        max-width:calc(100% - 40px) !important;
        background:$k_kelly !important;
        font-family: $lores !important;
        font-size: 1em !important;
        font-weight: $normal;
        font-style:normal;
        line-height:1em !important;
        color: $leafy_green !important;
        line-height:1em !important;
        border:none !important;
        text-decoration:none;
        box-shadow:none !important;
        text-shadow:none !important;
        transform:translateX(-50%) !important;
        text-transform:uppercase !important;
        letter-spacing:0.05em !important;
        box-sizing:border-box;
        text-align:center;
        -webkit-appearance:none;
        transition:all 0.3s;
        cursor:pointer;
        
        &:hover {
            background:$lemon_lime !important;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    a.btn,
    .btn a,
    a span.btn,
    div.btn,
    button.btn,
    .wp-block-button__link,
    span.rm_link,
    input[type='image'],
    input[type='submit'],
    input[type='button'],
    button.gpnf-add-entry {
        font-size: 0.8em;
    }
    
    .read_more {
        font-size:0.77em;
    }
    
    .pum-theme-default-theme {
        .pum-container {
            display:flex !important;
            max-width:none !important;
        }
        .pum-content {
            
        }
        .pum-close {
            position:static !important;
            margin:0 0 0 30px !important;
            transform:none !important;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}