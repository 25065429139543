/**********************************************************************************************/
/***** BLOCK: IMAGE FLEX GRID *****************************************************************/
/**********************************************************************************************/
.content_section.img_flex_grid {
  .content_section_inner {
    position: relative;
    padding-bottom: 6em;
    z-index: 10;

    .gridblock.hidden {
      display: none;
    }
  }

  .intro_cont_wrap {
    margin-top: 3em;

    .left_cont {
      padding: 0;

      > :first-child {
        margin-top: 0;
      }
    }

    .cont {
      > :first-child {
        margin-top: 0;
      }
    }
  }

  .img_grid_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 2em -26px;

    &.items_6,
    &.items_5,
    &.items_4,
    &.items_3,
    &.items_2 {
      .gridblock {
        flex: 0 0 calc(50% - 80px);
      }
    }

    &.left_align {
      justify-content: flex-start;
    }

    &.top_align {
      align-items: flex-start;
    }
  }

  .gridblock {
    display: block;
    margin: 0 40px 32px;

    .inner {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    h4 {
      margin: 0 0 0.5em;
    }

    .desc {
      margin: 1em 0 0;
      font-size: 0.8em;
      text-align: center;
      color: $whipped_cream;
    }
  }

  .pixels_wrap {
    z-index: -1;
  }
}

@media only screen and (max-width: 480px) {
}

@media screen and (min-width: #{$bp_small_plus1}) {
  .content_section.img_flex_grid {
    .img_grid_wrap {
      margin: 2em -32px;

      &.items_4,
      &.items_3,
      &.items_2 {
        .gridblock {
          margin-left: 40px;
          margin-right: 40px;
          flex: 0 0 calc(33.3333% - 80px);
        }
      }

      &.items_6,
      &.items_5 {
        .gridblock {
          margin-left: 20px;
          margin-right: 20px;
          flex: 0 0 calc(33.3333% - 40px);
        }
      }
    }
  }
}

@media screen and (min-width: #{$bp_mid_plus1}) {
  .content_section.img_flex_grid {
    .intro_cont_wrap {
      .left_cont {
        padding: 0 32px 0 0;
      }
    }

    .img_grid_wrap {
      margin: 2em -40px;

      &.items_6 {
        flex-wrap: nowrap;

        .gridblock {
          flex: 0 0 calc(16.667% - 40px);
          margin-right: 20px;
          margin-left: 20px;
        }
      }

      &.items_5 {
        .gridblock {
          flex: 0 0 calc(20% - 40px);
          margin-right: 20px;
          margin-left: 20px;
        }
      }

      &.items_4 {
        .gridblock {
          flex: 0 0 calc(25% - 60px);
          margin-right: 30px;
          margin-left: 30px;
        }
      }

      &.items_3 {
        .gridblock {
          flex: 0 0 calc(33.3333% - 60px);
          margin-right: 30px;
          margin-left: 30px;
        }
      }

      &.items_2 {
        .gridblock {
          flex: 0 0 calc(50% - 60px);
          margin-right: 30px;
          margin-left: 30px;
        }
      }
    }

    .gridblock {
      margin-bottom: 40px;
    }
  }
}

@media screen and (min-width: #{$bp_full_plus1}) {
}
