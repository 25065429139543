/**********************************************************************************************/
/***** FILTERS + FILTERED GRIDS ***************************************************************/
/**********************************************************************************************/

/*#insights_filter_wrap {
    display:flex;
    align-items:flex-start;
    justify-content:center;
    margin-bottom:0;
    transition:all 0.3s;
    
    #filters_clear {
        
        font-size:0.78em;
        
        &:after {
            
        }
    }
    
    #alm-filters-reset-button {
        font-size:0.78em;
        color: $coral !important;
        
        &:hover {
            color: $blue !important;
        }
        
        &.hidden {
            display:none;
        }
    }
}*/

.alm-filters-container,
#alm-filters-insightsfilter,
#alm-filters-casestudiesfilter,
#alm-filters-teamfilter {
    flex:1 0 100%;
    margin:0 0 3em;
    padding:16px;
    border:1px solid $k_kelly;
    
    .alm-filter {
        display:inline-block;
        vertical-align:top;
        margin:0;
        width:100%; //
        min-width:200px;
        box-sizing:border-box;
        
        &:first-of-type {
            //margin:0 60px 0 0;
        }
        
        .alm-filter--inner {
            display:none !important;
            margin-bottom:0;
            padding:0 0 30px;
            transition:all 0.3s;
        }
        
        &.open {
            .alm-filter--inner {
                display:block !important;
            }
            
            /*ul > li {
                display:block;
            }*/
        }
        
        ul {
            margin:0;
            padding:0;
            list-style:none;
            
            > li {
                //display:none;
                margin:0;
                padding:0;
                list-style:none;
                
                > .alm-filter--link {
                    display:block;
                    position:relative;
                    padding:8px 0 8px 28px;
                    font-family: $mundial;
                    font-weight:$light; // $normal;
                    font-size:0.8em;
                    line-height:1.375em;
                    color: $whipped_cream;
                    text-decoration:none;
                    transition:all 0.3s;
                    cursor:pointer;
                    
                    .whipped_cream_200 & {
                        color:$leafy_green_400;
                    }
                    
                    .whipped_cream_400 & {
                        color:$leafy_green;
                    }
                    
                    &:before {
                        content: '';
                        position:absolute;
                        left:0;
                        top:50%;
                        width:16px;
                        height:16px;
                        border:none;
                        box-shadow:inset 0 0 0 1px rgba(248,245,236,0.5);
                        background: transparent;
                        border-radius:0;
                        transform:translateY(-43%);
                        transition:all 0.3s;
                        
                        .whipped_cream_200 & {
                            box-shadow:inset 0 0 0 1px rgba(8,32,13,0.5);
                            color:$whipped_cream_200;
                        }
                        
                        .whipped_cream_400 & {
                            box-shadow:inset 0 0 0 1px rgba(15,44,21,0.5);
                            color:$whipped_cream_400;
                        }
                        
                        .leafy_green_400 & {
                            color:$leafy_green_400;
                        }
                    }
                    
                    &:after {
                        content: '';
                        display:none;
                        left:5px;
                        top:calc(50% - 4px);
                        width: 5px;
                        height: 8px;
                        border: solid #fff;
                        border-width: 0px 2px 2px 0;
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                                        
                    &:hover,
                    &:focus,
                    &.active {
                        text-decoration:none;
                        
                        &:before {
                            color: $whipped_cream;
                            background: $whipped_cream;
                            box-shadow:inset 0 0 0 1px rgba(248,245,236,1);
                            
                            .whipped_cream_200 & {
                                box-shadow:inset 0 0 0 1px rgba(8,32,13,1);
                                background: $leafy_green_400;
                                color:$leafy_green_400;
                            }
                            
                            .whipped_cream_400 & {
                                box-shadow:inset 0 0 0 1px rgba(15,44,21,1);
                                background: $leafy_green;
                                color:$leafy_green;
                            }
                        }
                    }
                    
                    &:focus {
                        
                        &:before {
                            box-shadow:inset 0 0 0 2px rgba(14,183,71,1);
                        }
                    }
                    
                    &.active {
                        
                        &:after {
                            display:block;
                            position: absolute;
                            border-color: $leafy_green;
                            
                            .whipped_cream_200 & {
                                border-color:$whipped_cream_200;
                            }
                            
                            .whipped_cream_400 & {
                                border-color:$whipped_cream_400;
                            }
                        }
                        
                        &:hover,
                        &:focus {
                        
                            &:before {
                                color: $leafy_green;
                                
                                .whipped_cream_200 & {
                                    color:$whipped_cream_200;
                                }
                                
                                .whipped_cream_400 & {
                                    color:$whipped_cream_400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .alm-filter--title {
        
        h3.alm-filter--toggle,
        h3 {
            position:relative;
            margin:0 0 0.25em;
            padding:10px 20px 10px 0;
            color: $whipped_cream;
            font-size:0.8em; // 1em;
            font-weight:$light; // $normal;
            line-height:1.3em;
            text-transform:none;
            cursor:pointer;
            
            .whipped_cream_200 & {
                color:$leafy_green;
            }
            
            .whipped_cream_400 & {
                color:$leafy_green;
            }
            
            &:after {
                position:absolute;
                right:0;
                top:50%;
                content:'';
                text-align:center;
                width: 14px; 
                height: 9px;
                margin:0;
                border:none;
                background:transparent url('../../images/select-caret-light.svg') no-repeat center center;
                transform:translateY(-20%) rotate(0deg);
                transform-origin:center 60%;
                transition:all 0.3s;
            }
            
            &[aria-expanded=false]:after,
            &.open:after {
                transform:translateY(-50%) rotate(180deg);
            }
        }
    }
    
    .alm-filters-edit {
        display:none;
    }

    .alm-filters--controls {
        margin-bottom: 0;

        .alm-filters--reset {
            button[type=reset] {
                display: inline-block !important;
                position: relative;
                margin: 0 0 1em;
                padding: 16px 30px;
                width: auto;
                background: #0EB747;
                font-family: lores-12, sans-serif;
                font-size: .8em;
                font-weight: 400;
                font-style: normal;
                line-height: 1em;
                color: #0F2C15;
                border: none;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: .05em;
                box-sizing: border-box;
                text-align: center;
                -webkit-appearance: none;
                transition: all .3s;
                cursor: pointer;
                border-radius: 0;
                height: 48px;
                opacity: 1;
                visibility: visible;

                &.hidden {
                    opacity: 0;
                    visibility: hidden;
                }
    
                &:hover,
                &:focus {
                    background: #DFEB8E;
                }
    
                @media screen and (max-width: 767px) {
                    padding: 0 16px;
                    height: 34px;
                    line-height: 1;
                    margin-top: .5rem;
                }
            }
        }
    }
    
    .btn__reset-filter {
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
        
        @media only screen and (max-width: 767px) {
            padding: 8px 16px;
            height: 34px;
            line-height: 1 !important;
            margin-top: .5rem;
            margin-bottom: .5rem;
            opacity: 1;
            visibility: visible;
        }
    }
}



.alm-btn-wrap {
    //opacity:0;
    //visibility:hidden;
    text-align:center;
}

//#insights_list {
.filtered_grid {
    
    .alm-paging {
        
        
        &.alm-loading {
            min-height:80px;
            background:transparent url('../../images/loading.svg') no-repeat center 20px;
        }
    }
    
    .alm-paging-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-auto-rows: auto;
        grid-column-gap: 80px;
        grid-row-gap: 80px;
        transform: translate3d(0,0,0); //make iOS render during scroll
        //margin:0 -20px;
        
        .team_grid & {
            grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
            grid-template-rows: 1fr;
            grid-auto-rows: auto;
            grid-column-gap: 32px;
            grid-row-gap: 32px;
        }
        
        .gridblock {
            display:block;
            /*z-index:1000;
            margin:0 0 50px 10.88%;
            padding:0;
            width:44.56%;
            vertical-align:top;
            box-sizing:border-box;
            
            &:nth-of-type(2n + 1) {
                margin-left:0;
            }*/
            
            > a {
                display:block;
                position:relative;
                padding-bottom:90px;
                height:100%;
                box-sizing:border-box;
            
                &:hover {
                
                    .img {
                        
                        .inner {
                            top:-26px;
                            right:-26px;
                            bottom:-26px;
                            left:-26px;
                        }
                    
                        &.has_h_logo {
                        
                            .overlay {
                                @include background-opacity($leafy_green_400, 0.8);
                            }
                        }
                    
                        .overlay {
                            top:-20px;
                            right:-20px;
                            bottom:-20px;
                            left:-20px;
                        }
                        
                        .h_logo {
                            opacity:1;
                        }
                    }
                
                    .px_wrap {
                        
                        .pixel {
                            transform:translateY(-100%);
                        }
                    
                        &.btm {
                            transform:translateY(100%);
                        }
                        
                        &.group1 {
                            
                            .pixel.three {
                                transform:translateY(100%);
                            }
                        }
                        
                        &.group2 {
                            .pixel.three {
                                transform:translateY(100%);
                            }
                        }
                        
                        &.group3 {
                            
                            .pixel.one,
                            .pixel.two {
                                transform:translate(100%,0);
                            }
                        }
                        
                        &.group4 {
                            .pixel.three {
                                transform:translateY(100%);
                            }
                        }
                        
                        &.group5 {
                            .pixel.one,
                            .pixel.two {
                                transform:translate(-100%,0);
                            }
                        }
                    }
                
                    h4 {
                        color:$k_kelly;
                    }
                
                    .read_more {
                        &:before,
                        &:after {
                            bottom:10px;
                            opacity:1;
                        }
                    }
                }
            }
        
            .img {
                position:relative;
                height:0;
                padding-bottom:60%;
                margin:0 0 42px;
            
                .thumb_wrap {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    height:0;
                    padding-bottom:60%;
                }
            
                .inner {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background-color:$leafy_green_400;
                    background-repeat:no-repeat;
                    background-position:center center;
                    background-size:cover;
                    transition:all 0.3s;
                    overflow:hidden;
                }
            
                .overlay {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    @include background-opacity($leafy_green_400, 0);
                    transition:all 0.3s;
                }
                
                .h_logo {
                    position:absolute;
                    left:50%;
                    top:50%;
                    display:block;
                    max-width:80%;
                    transform:translate(-50%,-50%);
                    opacity:0;
                    transition:opacity 0.3s;
                }
            }
        
            h4 {
                margin:0 0 0.5em;
                transition:color 0.3s;
            }
        
            .desc {
                margin:0;
                font-size:0.8em;
                color:$whipped_cream;
            }
        
            .read_more {
                position:absolute;
                left:0;
                bottom:0;
            
                &:hover {
                    &:before,
                    &:after {
                        bottom:3px;
                        opacity:0;
                    }
                }
            }
        }
        
        //CTA
        .contentupgrade-wrapper {
            grid-column: 1 / span 2;
        }
        
        .team_card {
            
            > a {
                display:block;
                position:relative;
                height:100%;
                width:100%;
                margin:0;
                padding:0;
                text-decoration:none;
                box-sizing:border-box;
                z-index:10;
            
                &:hover {
                    
                    .headshot {
                        filter: grayscale(0%);
                    }
                    
                    .icon {
                        //filter: grayscale(0%);
                
                        &.k_kelly {
            
                            svg .pixel_icon {
                                fill:$k_kelly;
                        
                                > path {
                                    fill:$k_kelly;
                                }
                            }
                        }
        
                        &.leafy_green {
            
                            svg .pixel_icon {
                                fill:$leafy_green;
                        
                                > path {
                                    fill:$leafy_green;
                                }
                            }
                        }
        
                        &.rhubarb {
            
                            svg .pixel_icon {
                                fill:$rhubarb;
                        
                                > path {
                                    fill:$rhubarb;
                                }
                            }
                        }
        
                        &.rhubarb_200 {
            
                            svg .pixel_icon {
                                fill:$rhubarb_200;
                        
                                > path {
                                    fill:$rhubarb_200;
                                }
                            }
                        }
        
                        &.rhubarb_400 {
            
                            svg .pixel_icon {
                                fill:$rhubarb_400;
                        
                                > path {
                                    fill:$rhubarb_400;
                                }
                            }
                        }
        
                        &.lemon_lime {
            
                            svg .pixel_icon {
                                fill:$lemon_lime;
                        
                                > path {
                                    fill:$lemon_lime;
                                }
                            }
                        }
        
                        &.lemon_lime_200 {
            
                            svg .pixel_icon {
                                fill:$lemon_lime_200;
                        
                                > path {
                                    fill:$lemon_lime_200;
                                }
                            }
                        }
        
                        &.lemon_lime_400 {
            
                            svg .pixel_icon {
                                fill:$lemon_lime_400;
                        
                                > path {
                                    fill:$lemon_lime_400;
                                }
                            }
                        }
        
                        &.plum {
            
                            svg .pixel_icon {
                                fill:$plum;
                        
                                > path {
                                    fill:$plum;
                                }
                            }
                        }
        
                        &.plum_200 {
            
                            svg .pixel_icon {
                                fill:$plum_200;
                        
                                > path {
                                    fill:$plum_200;
                                }
                            }
                        }
        
                        &.plum_400 {
            
                            svg .pixel_icon {
                                fill:$plum_400;
                        
                                > path {
                                    fill:$plum_400;
                                }
                            }
                        }
        
                        &.pink_salt {
            
                            svg .pixel_icon {
                                fill:$pink_salt;
                        
                                > path {
                                    fill:$pink_salt;
                                }
                            }
                        }
        
                        &.pink_salt_200 {
            
                            svg .pixel_icon {
                                fill:$pink_salt_200;
                        
                                > path {
                                    fill:$pink_salt_200;
                                }
                            }
                        }
        
                        &.pink_salt_400 {
            
                            svg .pixel_icon {
                                fill:$pink_salt_400;
                        
                                > path {
                                    fill:$pink_salt_400;
                                }
                            }
                        }
        
                        &.leafy_green_200 {
            
                            svg .pixel_icon {
                                fill:$leafy_green_200;
                        
                                > path {
                                    fill:$leafy_green_200;
                                }
                            }
                        }
        
                        &.leafy_green_400 {
            
                            svg .pixel_icon {
                                fill:$leafy_green_400;
                        
                                > path {
                                    fill:$leafy_green_400;
                                }
                            }
                        }
                    }
                    
                    h4 {
                        color:$whipped_cream;
                    }
                    
                    .job_title {
                        color:$white;
                    }
                }
            }
            
            .icon {
                position:absolute;
                right:0;
                top:32px;
                width:48%;
                transition:all 0.3s;
                //filter: grayscale(100%);
                //background:$whipped_cream_200;
                z-index:-1;
                
                svg {
                    width:100%;
                    height:auto;
                    transition:all 0.3s;
                    
                    .pixel_icon {
                        fill:$whipped_cream_200;
                        transition:all 0.3s;
                        
                        > path {
                            fill:$whipped_cream_200;
                            transition:all 0.3s;
                        }
                    }
                }
                
                /*&.k_kelly {
            
                    svg .pixel_icon {
                        fill:$k_kelly;
                        
                        > path {
                            fill:$k_kelly;
                        }
                    }
                }
        
                &.leafy_green {
            
                    svg .pixel_icon {
                        fill:$leafy_green;
                        
                        > path {
                            fill:$leafy_green;
                        }
                    }
                }
        
                &.rhubarb {
            
                    svg .pixel_icon {
                        fill:$rhubarb;
                        
                        > path {
                            fill:$rhubarb;
                        }
                    }
                }
        
                &.rhubarb_200 {
            
                    svg .pixel_icon {
                        fill:$rhubarb_200;
                        
                        > path {
                            fill:$rhubarb_200;
                        }
                    }
                }
        
                &.rhubarb_400 {
            
                    svg .pixel_icon {
                        fill:$rhubarb_400;
                        
                        > path {
                            fill:$rhubarb_400;
                        }
                    }
                }
        
                &.lemon_lime {
            
                    svg .pixel_icon {
                        fill:$lemon_lime;
                        
                        > path {
                            fill:$lemon_lime;
                        }
                    }
                }
        
                &.lemon_lime_200 {
            
                    svg .pixel_icon {
                        fill:$lemon_lime_200;
                        
                        > path {
                            fill:$lemon_lime_200;
                        }
                    }
                }
        
                &.lemon_lime_400 {
            
                    svg .pixel_icon {
                        fill:$lemon_lime_400;
                        
                        > path {
                            fill:$lemon_lime_400;
                        }
                    }
                }
        
                &.plum {
            
                    svg .pixel_icon {
                        fill:$plum;
                        
                        > path {
                            fill:$plum;
                        }
                    }
                }
        
                &.plum_200 {
            
                    svg .pixel_icon {
                        fill:$plum_200;
                        
                        > path {
                            fill:$plum_200;
                        }
                    }
                }
        
                &.plum_400 {
            
                    svg .pixel_icon {
                        fill:$plum_400;
                        
                        > path {
                            fill:$plum_400;
                        }
                    }
                }
        
                &.pink_salt {
            
                    svg .pixel_icon {
                        fill:$pink_salt;
                        
                        > path {
                            fill:$pink_salt;
                        }
                    }
                }
        
                &.pink_salt_200 {
            
                    svg .pixel_icon {
                        fill:$pink_salt_200;
                        
                        > path {
                            fill:$pink_salt_200;
                        }
                    }
                }
        
                &.pink_salt_400 {
            
                    svg .pixel_icon {
                        fill:$pink_salt_400;
                        
                        > path {
                            fill:$pink_salt_400;
                        }
                    }
                }
        
                &.leafy_green_200 {
            
                    svg .pixel_icon {
                        fill:$leafy_green_200;
                        
                        > path {
                            fill:$leafy_green_200;
                        }
                    }
                }
        
                &.leafy_green_400 {
            
                    svg .pixel_icon {
                        fill:$leafy_green_400;
                        
                        > path {
                            fill:$leafy_green_400;
                        }
                    }
                }*/
            }
            
            .headshot {
                position:relative;
                padding-right:32px;
                padding-bottom:115%;
                height:0;
                margin:0 0 0.75em;
                transition:all 0.3s;
                filter: grayscale(100%);
                
                img {
                    display:block;
                    position:absolute;
                    left:0;
                    bottom:0;
                    width:85%;
                }
            }
            
            .cont_wrap {
                text-align:left;
            }
            
            h4 {
                margin:0;
                color:$whipped_cream;
            }
            
            .job_title {
                margin:0;
                font-size:0.8em;
                font-weight:$light;
                color:$white;
                opacity:0.6;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    
    //#insights_list {
    .filtered_grid {
    
        .alm-paging-content {
            grid-template-columns: 1fr;
            grid-column-gap: 0;
            
            .team_grid & {
                grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
            }
        
            .contentupgrade-wrapper {
                grid-column: 1 / span 1;
            }
        }
    }

    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .alm-filters-container,
    #alm-filters-insightsfilter,
    #alm-filters-casestudiesfilter,
    #alm-filters-teamfilter {
        padding:0 0 0 16px;
        border:none;
    
        .alm-filter {
            padding-right:0;
            width:auto;
            min-width:0;
            
            .alm-filter--inner {
                display:block !important;
            }
            
            ul {
                
                > li {
                    //display:block;
                }
            }
        }
        
        .alm-filter--title {
        
            h3.alm-filter--toggle,
            h3 {
                font-size:1em;
                font-weight:$normal;
                cursor:default;
                
                &:after {
                    display:none;
                }
            }
        }
    }
    
    //#insights_list {
    .filtered_grid {
        padding-right:16px;
    
        .alm-paging-content {
            
            .team_grid & {
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 32px;
            }
            
            .gridblock {
            
                > a {
                    
                    &:hover {
                
                        .img .inner {
                            top:-20px;
                            right:-20px;
                            bottom:-20px;
                            left:-20px;
                        }
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .alm-filters-container,
    #alm-filters-insightsfilter,
    #alm-filters-casestudiesfilter,
    #alm-filters-teamfilter {
        padding:0;
    }
    
    .filtered_grid {
        padding-right:0;
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}