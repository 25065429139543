/**********************************************************************************************/
/***** HEADER *********************************************************************************/
/**********************************************************************************************/
#mobile_nav_toggle {
    display:block;
    position:absolute;
    right:0;
    top:46px;
    width:24px;
    height:10px;
    margin:0 auto;
    cursor: pointer;
    outline:none;
    border:none;
    background:none;
    z-index:1010;
    transition:all 0.3s;
    
    &.open {
        top:44px;

        &:focus{
            span{
                background: #0EB747;
            }
        }
    }
    
    .inner {
        position:absolute;
        right:0;
        top:0px;
        width:24px;
        height:20px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }
    &:focus{
        span{
            background: #0EB747;
        }
    }
}
#mobile_nav_toggle span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $whipped_cream_200;
    border-radius:0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
#mobile_nav_toggle.open span {
    background: $whipped_cream_200;
}
#mobile_nav_toggle span:nth-child(1) {
    top: 0px;
}
#mobile_nav_toggle span:nth-child(2) {
    top: 7px;
}
#mobile_nav_toggle.open span:nth-child(1) {
    top: 5px;
    transform: rotate(45deg);
}
#mobile_nav_toggle.open span:nth-child(2) {
    top: 5px;
    transform: rotate(-45deg);
}

#mobile_nav_tray {
    display:none;
    position:fixed;
    top:0;
    bottom:0;
    width:100%;
    height:100vh;
    padding:120px 32px 32px;
    box-sizing:border-box;
    overflow:scroll;
    z-index:990;
    left: 0;
    @include background-opacity($leafy_green_400, 1);
    
    .mobile_nav_open & {
        
    }
    
    .inner {
        height:100%;
        overflow:scroll;
    }
}

.site-header {
    position:relative;
    left:0;
    top:0;
    width:100%;
    background:$leafy_green_400;
    height:$header_h_mobile;
    border-bottom:1px solid rgba(255,253,246,0.1);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    z-index:1000;
    
    .home & {
        
    }
    
    .mobile_nav_open & {
        background:none;
    }
    
    .landingpage-noheader & {
        display:none;
    }
    
    .grid {
        height:100%;
        
        > div {
            height:100%;
        }
    }
    
    .grid-pad {
        padding-top:0;
    }
    
    &.sticky {
        position:fixed;
    }
    
    &.in {
        top:0;
    }
}

.header_inner {
    position:relative;
    width:calc(100% - 52px);
    max-width:1420px;
    height:100%;
    margin:0 auto;
    box-sizing: border-box;
}

.site-title {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    margin:0;
    height:100%;
    z-index:1000;
    vertical-align:top;
    
    .sticky & {
        
        .mobile_nav_open & {
            
        }
        
        > a {
            > .logomark {
                display:block;
            }
            > .logotype {
                //flex:0 0 100%;
            }
        }
    }
    
    .mobile_nav_open & {
        
    }
    
    > a {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        position:relative;
        width:100%;
        padding-top:12px;
        z-index: 999;

        img {
            display:block;
            width:100%;
        }
        
        .logomark {
            display:block;
            flex:0 0 37px;
            margin-right:14px;
        }
        
        .logotype {
            display:block;
            flex:0 0 171px;
            
            &.anim {
                margin-top:4px;
                flex:0 0 175px;
            }
        }
        
        &:hover {
            text-decoration:none;
        }
    }
}

.header_right {
    margin:0;
    padding:0;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:nowrap;
    height:100%;
    //z-index:1000;
    transition:all 0.3s linear;
}

@media only screen and (max-width: #{$bp_mid}) {

    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (max-width: 360px) {
    
    .site-title {
    
        > a {
            
            .logomark {
                flex:0 0 29px;
                margin-right:11px;
            }
        
            .logotype {
                flex:0 0 141px;
                
                &.anim {
                    margin-top:3px;
                    flex:0 0 144px;
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    .site-header {
        position:static;
        top:-#{$header_h + 1};
        height:$header_h;
        border:none;
        
        &.sticky {
            border-bottom:1px solid rgba(255,253,246,0.1);
        }
    }
    
    .header_inner {
        width:calc(100% - 64px);
    }
    
    .site-title {
    
        > a {
            padding:0;
            
            .logomark {
                display:none;
                flex:0 0 43px;
            }
        
            .logotype {
                flex:0 0 191px;
                
                &.anim {
                    margin-top:5px;
                    flex:0 0 195px;
                }
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    #mobile_nav_toggle {
        display:none;
    }
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}