/**********************************************************************************************/
/***** BLOCK: ICON GRID ***********************************************************************/
/**********************************************************************************************/
.content_section.icon_grid {
    position:relative;
    z-index:10;
    
    .content_section_inner {
        position:relative;
        padding-top:5em;
        padding-bottom:0;
        padding-left:0;
        padding-right:0;
        z-index:11;
    }
    
    //EDIT 3/22/23: FLIP MAIN BLOCK BG COLOR AND PSEUDO BG COLOR, IN ORDER TO CONSISTENTLY ALIGN PSEUDO BG FROM TOP VS BOTTOM
    &.bg_whipped_cream_200,
    &.bg_whipped_cream_400,
    &.bg_leafy_green,
    &.bg_leafy_green_400 {
    
        .content_section_inner:before {
            position:absolute;
            //left:-32px;
            left:0;
            top:0;
            height:300px;
            //width:calc(100% + 64px);
            width:100%;
            content:'';
            z-index:-1;
        }
        
        .icongrid:before {
            position:absolute;
            left:0;
            top:0;
            height:500px;
            width:100%;
            content:'';
            z-index:-1;
        }
    }
    
    &.whipped_cream_200 {
    
        .content_section_inner:before,
        .icongrid:before,
        .intro {
            background:$whipped_cream_200;
        }
    }
    
    &.whipped_cream_400 {
    
        .content_section_inner:before,
        .icongrid:before,
        .intro {
            background:$whipped_cream_400;
        }
    }
    
    &.leafy_green {
    
        .content_section_inner:before,
        .icongrid:before,
        .intro {
            background:$leafy_green;
        }
    }
    
    &.leafy_green_400 {
    
        .content_section_inner:before,
        .icongrid:before,
        .intro {
            background:$leafy_green_400;
        }
    }
    
    &.bg_whipped_cream_200 {
    
        .content_section_inner {
            background:$whipped_cream_200;
        }
    }
    
    &.bg_whipped_cream_400 {
    
        .content_section_inner {
            background:$whipped_cream_400;
        }
    }
    
    &.bg_leafy_green {
    
        .content_section_inner {
            background:$leafy_green;
        }
    }
    
    &.bg_leafy_green_400 {
    
        .content_section_inner {
            background:$leafy_green_400;
        }
    }
    
    /*&.bg_whipped_cream_200,
    &.bg_whipped_cream_400,
    &.bg_leafy_green,
    &.bg_leafy_green_400 {
    
        .content_section_inner:before {
            position:absolute;
            //left:-32px;
            left:0;
            bottom:0;
            height:calc(59% + 0px);
            //width:calc(100% + 64px);
            width:100%;
            content:'';
            z-index:-1;
        }
    }
    
    &.bg_whipped_cream_200 {
    
        .content_section_inner:before {
            background:$whipped_cream_200;
        }
    }
    
    &.bg_whipped_cream_400 {
    
        .content_section_inner:before {
            background:$whipped_cream_400;
        }
    }
    
    &.bg_leafy_green {
    
        .content_section_inner:before {
            background:$leafy_green;
        }
    }
    
    &.bg_leafy_green_400 {
    
        .content_section_inner:before {
            background:$leafy_green_400;
        }
    }*/
    
    &.has_pixels {
        
        .content_section_inner {
            padding-top:12em;
            padding-bottom:8em;
        }
    }
    
    .intro {
        padding:0 26px;
        
        .left_cont {
            padding:0;

            > :first-child {
                margin-top:0;
            }
        }
    
        .cont {
            padding:0;
                    
            > :first-child {
                margin-top:0;
            }
        }
    }
    
    .icongrid {
        position:relative;
        padding:0 26px;
    }
    
    .grid_wrap {
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: auto;
        grid-column-gap: 0;
        grid-row-gap: 42px;
        width:100%;
        max-width:none;
        margin:0 auto;
        padding:3em 0 0;
        z-index:12;
    }
    
    .gridblock {
        position:relative;
        display:block;
        border:1px solid rgba(15,44,21,0.3);
        background:$whipped_cream_200;
        text-decoration:none;
        transition:all 0.3s;
        
        &:before {
            display:none;
            padding-bottom:100%;
            content:'';
        }
        
        .inner {
            position:static;
            left:0;
            top:0;
            width:100%;
            height:100%;
            padding:48px 32px 38px;
            box-sizing:border-box;
        }
        
        h4 {
            margin:0 0 0.5em;
            color:$leafy_green;
        }
        
        p {
            margin:0;
            font-size:1em;
            color:$leafy_green;
        }
        
        &.ct {
            
            
            &:hover {
                background:$lemon_lime_200;
                
                .icon {
                    
                    svg {
                        
                        .pixel_icon {
                            fill:$k_kelly_400;
                            
                            > path {
                                fill:$k_kelly_400;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .icon {
        margin:0 0 30px;
        height:80px;
        
        svg {
            height:100%;
            width:auto;
            
            .pixel_icon {
                transition:all 0.3s;
                
                > path {
                    transition:all 0.3s;
                }
            }
        }
        
        &.anim {
            margin:0 0 10px;
            height:100px;
            
            svg {
                width:auto !important;
            }
        }
        
        &.k_kelly {
            
            svg .pixel_icon {
                fill:$k_kelly;
                
                > path {
                    fill:$k_kelly;
                }
            }
        }
        
        &.leafy_green {
            
            svg .pixel_icon {
                fill:$leafy_green;
                
                > path {
                    fill:$leafy_green;
                }
            }
        }
        
        &.rhubarb {
            
            svg .pixel_icon {
                fill:$rhubarb;
                
                > path {
                    fill:$rhubarb;
                }
            }
        }
        
        &.rhubarb_200 {
            
            svg .pixel_icon {
                fill:$rhubarb_200;
                
                > path {
                    fill:$rhubarb_200;
                }
            }
        }
        
        &.rhubarb_400 {
            
            svg .pixel_icon {
                fill:$rhubarb_400;
                
                > path {
                    fill:$rhubarb_400;
                }
            }
        }
        
        &.lemon_lime {
            
            svg .pixel_icon {
                fill:$lemon_lime;
                
                > path {
                    fill:$lemon_lime;
                }
            }
        }
        
        &.lemon_lime_200 {
            
            svg .pixel_icon {
                fill:$lemon_lime_200;
                
                > path {
                    fill:$lemon_lime_200;
                }
            }
        }
        
        &.lemon_lime_400 {
            
            svg .pixel_icon {
                fill:$lemon_lime_400;
                
                > path {
                    fill:$lemon_lime_400;
                }
            }
        }
        
        &.plum {
            
            svg .pixel_icon {
                fill:$plum;
                
                > path {
                    fill:$plum;
                }
            }
        }
        
        &.plum_200 {
            
            svg .pixel_icon {
                fill:$plum_200;
                
                > path {
                    fill:$plum_200;
                }
            }
        }
        
        &.plum_400 {
            
            svg .pixel_icon {
                fill:$plum_400;
                
                > path {
                    fill:$plum_400;
                }
            }
        }
        
        &.pink_salt {
            
            svg .pixel_icon {
                fill:$pink_salt;
                
                > path {
                    fill:$pink_salt;
                }
            }
        }
        
        &.pink_salt_200 {
            
            svg .pixel_icon {
                fill:$pink_salt_200;
                
                > path {
                    fill:$pink_salt_200;
                }
            }
        }
        
        &.pink_salt_400 {
            
            svg .pixel_icon {
                fill:$pink_salt_400;
                
                > path {
                    fill:$pink_salt_400;
                }
            }
        }
        
        &.leafy_green_200 {
            
            svg .pixel_icon {
                fill:$leafy_green_200;
                
                > path {
                    fill:$leafy_green_200;
                }
            }
        }
        
        &.leafy_green_400 {
            
            svg .pixel_icon {
                fill:$leafy_green_400;
                
                > path {
                    fill:$leafy_green_400;
                }
            }
        }
    }
    
    .post_grid_cta {
        
        .btn {
            margin:3em 0 0;
        }
    }
    
    .pixels_wrap {
        z-index:-1;
    }
    
}

@media only screen and (max-width: 480px) {
    
    .content_section.icon_grid {
        
        &.bg_whipped_cream_200,
        &.bg_whipped_cream_400,
        &.bg_leafy_green,
        &.bg_leafy_green_400 {
    
            .content_section_inner:before {
                //height:550px;
            }
        }
        
        .gridblock {
    
            &:before {
                display:none;
            }
        
            .inner {
                position:static;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.icon_grid {
        
        &.bg_bg_whipped_cream_200,
        &.bg_whipped_cream_400,
        &.bg_leafy_green,
        &.bg_leafy_green_400 {
    
            .content_section_inner:before {
                //height:600px;
            }
            
            .icongrid:before {
                height:250px;
            }
        }
        
        .intro {
            padding:0;
        
            .left_cont {
                padding-right:32px;
                
            }
    
            .cont {
                    
                
            }
        }
        
        .icongrid {
            padding:0;
        }
        
        .grid_wrap {
            grid-template-columns: repeat(2, calc(50% - 45px));
            grid-column-gap: 90px;
            grid-row-gap: 90px;
            max-width:none;
        }
        
        .gridblock {
            &:before {
                display:none;
            }
        
            .inner {
                position:static;
                left:0;
                top:0;
                width:100%;
                height:100%;
                padding:48px 38px 38px;
                box-sizing:border-box;
            }
            
            p {
                font-size:0.8em;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .content_section.icon_grid {
        
        .grid_wrap {
            grid-template-columns: repeat(3, calc(33.3333% - 40px));
            grid-column-gap: 60px;
            max-width:none;
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    .content_section.icon_grid {
        
        .grid_wrap {
            grid-template-columns: repeat(3, calc(33.3333% - 60px));
            grid-column-gap: 90px;
        }
    }
    
}