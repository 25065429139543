/**********************************************************************************************/
/***** BLOCK: GRAPHIC METRICS GRID ************************************************************/
/**********************************************************************************************/
.content_section.graphic_metrics_grid {
    
    
    .content_section_inner {
        position:relative;
        padding-bottom:6em;
        z-index:10;
    }
    
    .intro_cont_wrap {
        margin-top:6em;
        margin-bottom:4em;
                
        .left_cont {

            > :first-child {
                margin-top:0;
            }
        }
    
        .cont {
                    
            > :first-child {
                margin-top:0;
            }
        }
    }
    
    .lg_graphic {
        display:block;
        width:100%;
        margin:0 0 6em;
        
        &.sbs {
            
        }
    }
    
    .m_grid_wrap {
        display: flex;
        justify-content:flex-start;
        align-items:flex-start;
        flex-wrap:wrap;
        margin:0 -16px;
        
        &.items_5,
        &.items_4,
        &.items_3,
        &.items_2 {
            .gridblock {
                flex:0 0 calc(48% - 32px);
            }
        }
        
        &.sbs {
            
        }
    }
    
    .gridblock {
        display:block;
        margin:0 16px 3em; 
        
        .inner {
            //padding:0 30% 0 10%;
            padding:0 5% 0 0;
        }
        
        .lg {
            margin:0 0 0.25em;
            font-family:$ppfragment;
            font-size:4em;
            line-height:1.15em;
            font-weight:$normal;
            color:$k_kelly;
            
            &.midlong {
                font-size:3.25em;
            }
            
            &.long {
                font-size:2.5em;
            }
        }
        
        h4 {
            margin:0 0 0.5em;
        }
        
        .summary {
            margin:1em 0 0;
            font-size:0.8em;
            color:$whipped_cream;
        }
    }
    
    .pixels_wrap {
        z-index:-1;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.graphic_metrics_grid {
        
        .lg_graphic {
            
            &.sbs {
                margin-bottom:0;
            }
        }
        
        .m_grid_wrap {
            
            &.items_5 {
                .gridblock {
                    flex:0 0 calc(20% - 40px);
                }
            }
        
            &.items_4 {
                .gridblock {
                    flex:0 0 calc(25% - 40px);
                }
            }
        
            &.items_3 {
                .gridblock {
                    flex:0 0 calc(33.3333% - 40px);
                }
            }
        
            &.items_2 {
                .gridblock {
                    flex:0 0 calc(50% - 40px);
                }
            }
            
            &.sbs {
                margin:0;
                
                &.items_5 {
                    .gridblock {
                        flex:0 0 calc(20% - 64px);
                    }
                }
        
                &.items_4 {
                    .gridblock {
                        flex:0 0 calc(25% - 64px);
                    }
                }
        
                &.items_3 {
                    .gridblock {
                        flex:0 0 calc(33.3333% - 64px);
                    }
                }
        
                &.items_2 {
                    .gridblock {
                        flex:0 0 calc(50% - 64px);
                    }
                }
                
                .gridblock {
                    margin-left:64px;
                    margin-right:0;
                    
                    .inner {
                        padding:0;
                    }
                    
                    .lg {
                        font-size:2.2em;
            
                        &.midlong {
                            font-size:2.2em;
                        }
            
                        &.long {
                            font-size:2.2em;
                        }
                    }
                }
            }
        }
        
        .gridblock {
            margin-right:20px;
            margin-left:20px;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}