/**********************************************************************************************/
/***** BLOCK: HERO ****************************************************************************/
/**********************************************************************************************/
.content_section.hero {
  position: relative;
  z-index: 10;

  .content_section_inner {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.has_pixels {
    .content_section_inner {
      padding-top: 4em;
      padding-bottom: 0;
    }
  }

  &.metrics {
    //background:$leafy_green;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.untreated {
      .content_section_inner {
        padding-top: 14em;
        padding-bottom: 14em;

        &:before {
          filter: none;
        }
      }
    }

    &.has_bg {
    }

    .content_section_inner {
      padding-top: 8em;
      padding-bottom: 8em;
      padding-right: $mobile_lr_padding;
      padding-left: $mobile_lr_padding;
      z-index: 12;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        filter: grayscale(100%);
        z-index: 13;
      }
    }

    .overlay {
      display: block;
    }
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include background-opacity($leafy_green_400, 0.6);
    z-index: 13;

    &.light {
      @include background-opacity($leafy_green_400, 0.8);
    }

    &.none {
      @include background-opacity($leafy_green_400, 0);
    }
  }

  .grid {
    position: relative;
    z-index: 14;

    &.valign_btm {
      display: block;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  .metrics_wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    > div,
    > a {
      display: block;
      flex: 0 0 calc(33.333% - 40px);
      margin: 15px;
      text-align: center;
    }

    > a {
      position: relative;

      &:hover {
        .read_more {
          &:before,
          &:after {
            bottom: 10px;
            opacity: 1;
          }
        }
      }
    }

    span {
      display: block;
      text-align: center;

      &.lg {
        margin: 0 0 0.25em;
        font-family: $ppfragment;
        font-size: 4em;
        line-height: 1.15em;
        font-weight: $normal;
        color: $k_kelly;

        &.midlong {
          font-size: 3.25em;
        }

        &.long {
          font-size: 2.5em;
        }
      }

      &.sm {
        padding: 0;
        font-family: $mundial;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: $light;
        color: $whipped_cream;
      }

      &.read_more {
        display: inline-block;
        margin-top: 1.25em;

        &:hover {
          &:before,
          &:after {
            bottom: 3px;
            opacity: 0;
          }
        }
      }
    }
  }

  .cont {
    padding: 0;

    > :first-child,
    h2,
    h4 {
      margin-top: 0;
    }

    .btn {
      margin-top: 1.5em;
    }
  }

  .img {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .pixels_wrap {
    z-index: 15;
  }
}

@media only screen and (max-width: 480px) {
  .content_section.hero {
    .metrics_wrap {
      > div,
      > a {
        flex: 0 0 70%;
      }
    }
  }
}

@media screen and (min-width: #{$bp_small_plus1}) {
  .content_section.hero {
    .grid {
      &.valign_btm {
        display: flex;
      }
    }

    &.metrics {
      .content_section_inner {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .cont {
      padding: 2em 40px 2em 0;

      .btn {
        margin-top: 2em;
      }
    }
  }
}

@media screen and (min-width: #{$bp_mid_plus1}) {
  .content_section.hero {
    .metrics_wrap {
      justify-content: space-between;

      > div,
      > a {
        flex: 0 0 20%;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: #{$bp_small_plus1}) and (max-width: 1200px) {
  //very specific breakpoint, for aligning and stacking grid columns on tablet, small desktop

  .content_section.hero {
    &.is_block {
      .grid {
        &.valign_btm {
          display: block;
        }
      }

      .cont {
        width: 66.66%;
        margin-left: 33.33%;
      }

      .img {
        width: 66.66%;
        margin-left: 33.33%;
      }
    }
  }
}

@media screen and (min-width: #{$bp_full_plus1}) {
}
