/**********************************************************************************************/
/***** WIDGETS ********************************************************************************/
/**********************************************************************************************/
#secondary {
    
    
    .widget {
        margin:0 0 100px;
        padding:0;
        border:none;
        
        //GATED, WEBINAR CONTENT
        .widget-smartcta {
            
            .panel__inner {
                
            }
            
            .widget-title {
                margin:0 0 30px;
            }
            
            .panel__image {
                margin:0;
                overflow:hidden;
                background:none;
                                
                a {
                    display:block;
                    position:relative;
                    overflow:hidden;
                    
                    &:hover {
                        
                        img {
                            
                        }
                    }
                }
                
                img {
                    display:block;
                    width:100%;
                }
            }
            
            .panel__content {
                background:$k_kelly;
                margin:0;
                padding:15px;
                font-size:14px !important;
                line-height:22px !important;
                font-weight:$normal !important;
                
                p {
                    margin:0;
                }
            }
            
            .entry-summary {
                margin:0.75em 0 0;
                
                strong,b {
                    font-weight:$bold;
                }
            }
            
            .btn {
                margin:2em 0 0;
            }
        }
        
        &.widget_rp4wp_related_posts_widget {
            padding:0;
            border:none;
        }
    }
    
    .widget-title {
        font-size:1em;
        font-weight:$light;
        color:$k_kelly;
        margin:0 0 0.75em;
        
        a {
            color:$k_kelly;
            
            &:hover {
                color:$k_kelly;
            }
        }
    }
    
    
}

.content_section.posts_grid.rp4wp-related-posts { //RELATED POSTS
    
    
    .content_section_inner {
        padding-top:6em;
    }
    
    .intro {
        padding:0 0 4em;
    }
    
    .widget-title {
        margin:0;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}