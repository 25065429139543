/**********************************************************************************************/
/***** TITLE BAR ******************************************************************************/
/**********************************************************************************************/
#titlebar {
    
    .mark {
        display:none;
        margin-top:15px;
    }
    
    .cont {
        padding:0;
    }
    
    .single-post &,
    .single-resources &,
    .single-project &,
    .single-award &,
    .single-callout &,
    .single-whitepaper &,
    .single-webinar &,
    .single-podcast &,
    .single-ebook & {
        
        h1 {
            @extend h2;
            color:$whipped_cream;
            margin:0 0 0.25em;
        }
    }
    
    .single-case-study & {
        
        .cont {
            padding:0 4em 0 0;
        }
    }
    
    .author & {
        
        h1 {
            margin:0 0 0.25em;
        }
        
        .meta {
            
            span {
                font-size:1em;
                
                &.pronouns {
                    @extend h6;
                    display:inline-block;
                    vertical-align:middle;
                    font-size:90%;
                    margin:5px 0 0 32px;
                    color:$k_kelly;
                }
            }
        }
    }
    
    .eyebrow {
        display:block;
        @extend h6;
        margin:0.5em 0 1.75em;
        color:$k_kelly;
    }
    
    .meta {
        margin:0 0 0;
        
        a,
        span {
            display:inline-block;
            vertical-align:middle;
            font-size:0.8em;
            font-weight:$light;
            color:$whipped_cream;
            
            &.divider {
                width:6px;
                height:6px;
                margin:0 15px;
                background:$k_kelly;
            }
        }
        
        a:hover {
            color:$k_kelly;
        }
    }
    
    .subheading {
        margin-bottom:4em;
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {
    
    #titlebar {
        .mark {
            display:block;
        }
    }
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}