/**********************************************************************************************/
/***** FOOTER *********************************************************************************/
/**********************************************************************************************/
#colophon.site-footer {
    background:$leafy_green_400;
    
    .footer_inner {
        position:relative;
        display:block;
        justify-content:flex-start;
        align-items:center;
        width:100%;
        max-width:1420px;
        height:100%;
        margin:0 auto;
        padding:48px 26px 60px;
        box-sizing: border-box;
    }
    
    .logo {
        margin:0 0 48px;
        
        > a {
            display:inline-block;
        }
        
        .logomark {
            width:86px;
        }
        
        img {
            display:block;
            width:100%;
        }
    }
    
    #menu-footer-menu {
        display:block;
        margin:0;
        padding:0;
        
        li {
            display:block;
            margin:0 0 15px;
            
            a {
                display:block;
                padding:0;
                font-family:$lores;
                font-size:0.75em;
                color: $whipped_cream_200;
                text-decoration:none;
                text-transform:uppercase;
                letter-spacing:0.05em;
                opacity:0.5;
                transition:all 0.3s;
                
                &:hover {
                    opacity:1;
                }
            }
        }
    }
    
    p {
        font-family:$lores;
        font-size:0.75em;
        line-height:1.1em;
        text-transform:uppercase;
        letter-spacing:0.05em;
        color:$whipped_cream_200;
        opacity:0.5;
        
        &.copyright {
            margin:0 0 20px;
            opacity:1;
            
            a {
                padding:0;
                color: $whipped_cream_200;
                text-decoration:none;
                opacity:0.5;
                transition:all 0.3s;
                
                &:hover {
                    opacity:1;
                }
            }
        }
        
        a {
            color:$whipped_cream_200;
            opacity:0.5;
            transition:all 0.3s;
            
            &:hover {
                opacity:1;
            }
        }
    }
    
    .social_cont {
        display:inline-block;
        margin:0 0 44px;
        vertical-align:middle;
    }
    
    .social {
        display:inline-block;
        position:relative;
        width:26px;
        height:30px;
        margin:0 24px 0 0;
        background: none;
        text-indent:-9999px;
        vertical-align:middle;
        transition:all 0.3s;
        color: $whipped_cream_200;
        
        &.tw {
            width:28px;
        }
        
        &.ln {
            width:28px;
        }
        
        &.yt {
            width:25px;
        }
        
        &.gh {
            width:26px;
        }
        
        &.dp {
            width:23px;
        }
        
        span {
            display:block;
            text-align:left;
        }
        
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:50%;
            width:100%;
            height:auto;
            margin:0;
            transition: all 0.3s;
            transform:translate(-50%,-50%);
            
            .shape {
                transition: fill 0.3s;
                fill:$whipped_cream_200;
            }
        }
        
        &:hover {
            svg {
                .shape {
                    fill:$k_kelly;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    #colophon.site-footer {
        
        .footer_inner {
            display:flex;
            width:calc(100% - 64px);
            padding:60px 0 70px;
        }
    
        .logo {
            margin:0;
        }
        
        #menu-footer-menu {
            display:inline-block;
            flex:0 0 auto;
            
            li {
                display:inline-block;
                vertical-align:middle;
                margin:0 28px 0 0;
            
                a {
                    padding:0;
                    font-size:0.6em;
                }
            }
        }
        
        p {
            font-size:0.6em;
            
            &.copyright {
                display:inline-block;
                vertical-align:middle;
                margin:0 28px 0 0;
            }
        }
        
        .social_cont {
            margin:0 0 14px;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}