/**********************************************************************************************/
/***** TYPOGRAPHY *****************************************************************************/
/**********************************************************************************************/
* {
    ::-moz-selection {
        background: rgba(14,183,71,0.7);
        color:$leafy_green;
    }
    ::selection {
        background: rgba(14,183,71,0.7);
        color:$leafy_green;
    }
}

hr,
.wp-block-separator {
    height: 1px;
    background: $k_kelly;
    border: none;
    margin: 32px 0;
}

a {
	color: $leafy_green;
	text-decoration:none;
	transition:all 0.3s;
	
	&:hover {
	    //color: $k_kelly;
	}
	
	.entry-content &,
	.wysiwyg & {
	    text-decoration:underline;
	    text-decoration-color:$leafy_green;
        text-decoration-thickness:2px;
        text-underline-offset:0.1em;
        
        &:hover {
            text-decoration-color:$k_kelly;
        }
	}
}

h1,h2,h3,h4,h5,h6 {
    margin:0 0 0.5em;
    font-family: $mundial;
    font-weight:$normal;
    line-height:1.4em;
    color: $leafy_green;
}
h1,
p.heading {
    margin-bottom:0.3em;
    font-family: $ppfragment;
    font-size: 2.625em; /* 42px */
    line-height:1.15em;
    letter-spacing: -0.01em;
    
    &.mid_long {
        
    }

    &.long {
        
    }
}

h2 {
    margin-bottom:0.86em;
    margin-top:1.82em;
    font-family: $ppfragment;
    font-size: 1.75em; /* 28px */
    line-height:1.15em;
    letter-spacing: -0.01em;
    
    &.arrowed {
        background:transparent url('../../images/pixel-arrow.svg') no-repeat right top;
        background-size: 68px auto;
    }
}

h3 {
    margin-bottom:0.8em;
    margin-top:2.35em;
    font-size: 1.875em; /* 30px */
    line-height:1.4em;
    letter-spacing: -0.01em;
}

h4 {
    margin-bottom:0.92em;
    margin-top:3.07em;
    font-size: 1.625em; /* 26px */
    line-height:1.154em;
}

h5 {
    margin-bottom:1.33em;
    margin-top:1.33em;
    font-size: 1.125em; /* 18px */
    line-height:1.18em;
}
h6 {
    margin-bottom:0.89em;
    margin-top:0.89em;
    font-family: $lores;
    font-size: 1.125em; /* 18px */
    line-height:1em;
    text-transform:uppercase;
}

sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.55em;
    line-height: 0;
    font-size: 60%;
}
sub { 
    top: 0.55em; 
}

.entry-content {
    
    //naked formatted content lists
    ul,
    ol {
        margin: 0 0 1.2em 20px;
        list-style:decimal;
    }
    > ul {
        padding:0;
        list-style:disc;
    }
    > ul li,
    > ol li {
        display: list-item; 
        margin-bottom:0.25em;
        font-size:1em;
    }
    
    //nested
    > ul ul,
    > ul ol,
    > ol ul,
    > ol ol {
        margin-top:1.2em;
    }
    
    > ol li {
        list-style: decimal;
    }
        
    > ul li {
        position:relative;
        
        &::marker {
            font-size:60%;
        }
    } 
}

.wysiwyg {
    p {
        font-size:1em;
        
        &.gform_title {
            margin:3em 0 0.5em !important;
        }
        
        .btn {
            margin-bottom:0 !important;
        }
    }
    ul,
    ol {
        margin: 0 0 1.2em 20px;
        list-style:decimal;
    }
    ul {
        padding:0;
        list-style:disc;
    }
    ul li,
    ol li {
        display: list-item; 
        margin-bottom:0.25em;
        font-size:1em;
    }
    
    //nested
    ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin-top:1.2em;
    }
    
    ol li {
        list-style: decimal;
    }
        
    ul li {
        position:relative;
        //list-style:none;
        
        &::marker {
            font-size:60%;
        }
    }
    
    a {
        
    }
    
    &.plast {
        
        
    }
}

p {
    margin-bottom:1.2em;
    line-height:1.4em;
    font-weight:$light;
    
    strong, b {
        font-weight:$bold;
    }
    
    &.gform_title {
        margin:3em 0 0.5em !important;
    }
    
    &.eyebrow {
        @extend h6;
        margin:0 0 1.3em;
        color:$k_kelly;
    }
}

strong, b {
    font-weight: $bold;
}
i, em {
    font-style:italic;
}

blockquote {
    position:relative;
    font-family:$mundial;
    font-size:1.625em; /* 26px */
    line-height:1.4em;
    font-weight:$normal;
    margin:0;
    padding:2.8em 0 0.25em 1em;
    width:100%;
    border:none;
    border-left:7px solid $k_kelly;
    color: $leafy_green;
    text-align:left;
    box-sizing:border-box;
    
    &.wp-block-quote {
        margin-top:1.67em;
        margin-bottom:1.67em;
    }
    
    &:before {
        position:absolute;
        left:1em;
        top:0;
        width:32px;
        height:20px;
        //background:transparent url('../../images/pq-mark.svg') no-repeat left top;
        //background-size:100% auto;
        background:none;
        content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 20'%3e%3cg fill='%230EB747' %3e%3cpolygon points='32 6.5 32 0 25.5 0 25.5 6.4 19.1 6.4 19.1 19.3 32 19.3 32 12.8 25.6 12.8 25.6 6.5'%3e%3c/polygon%3e%3cpolygon points='6.4 6.4 0 6.4 0 19.3 12.9 19.3 12.9 12.8 6.5 12.8 6.5 6.5 12.9 6.5 12.9 0 6.4 0'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
    }
    
    .csection.whipped_cream_400 & {
        border-left:7px solid $k_kelly_400;
        
        &:before {
            content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 20'%3e%3cg fill='%230B9239' %3e%3cpolygon points='32 6.5 32 0 25.5 0 25.5 6.4 19.1 6.4 19.1 19.3 32 19.3 32 12.8 25.6 12.8 25.6 6.5'%3e%3c/polygon%3e%3cpolygon points='6.4 6.4 0 6.4 0 19.3 12.9 19.3 12.9 12.8 6.5 12.8 6.5 6.5 12.9 6.5 12.9 0 6.4 0'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
        }
    }
    
    p {
        margin:0;
        font-size:100%;
    }
    
    .wysiwyg & {
        margin:1.5em 0;
    }
}
cite,
p.cite {
    display:block;
    @extend p;
    font-family:$mundial;
    font-size:1em;
    line-height:1.5em;
    font-weight:$normal;
    text-align:left;
    margin:1.5em 0 0 !important;
}

figcaption,
.footnote,
caption {
    text-align:left;
    font-size:12px; //0.6em - using px vs em to limit how small these get on mobile
    font-family: $mundial;
    font-weight:$light;
    line-height:1.4em;
    color:$leafy_green;
    
    a {
        color:$leafy_green;
        text-decoration:underline;
        text-decoration-thickness:1px;
        text-underline-offset:0.1em;
        text-decoration-color:$leafy_green;
        
        &:hover {
            text-decoration-color:$k_kelly;
        }
    }
}

caption,
figcaption {
    margin-top:5px;
}

//preformatted
code,
pre {
    font-family:monospace !important;
}

img{
	vertical-align: bottom;
	
	.wysiwyg & {
	    max-width:100%;
	    height:auto;
	}
}
.screen-reader-text {
    clip:rect(1px, 1px, 1px, 1px);
    border:0;
    -webkit-clip-path:inset(50%);
    clip-path:inset(50%);
    width:1px;
    height:1px;
    margin:-1px;
    overflow:hidden;
    position:absolute !important;
    font-family: $mundial;
    font-size:0.8em;
    font-weight: $normal;
    color: $k_kelly;
    z-index:10000;
    opacity:0;
    transition:opacity 0.2s;
    word-wrap:normal !important;
}
.site-navigation .screen-reader-text {
    left:auto;
    right:10px;
}
.screen-reader-text:focus {
    clip:auto;
    opacity:1;
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    h1,
    p.heading {
        font-size: 4.0em; /* 80px */
    
        &.mid_long {
        
        }

        &.long {
        
        }
    }

    h2 {
        font-size: 2.2em; /* 44px */
    }

    h3 {
        font-size: 1.7em; /* 34px */
    }

    h4 {
        font-size: 1.3em; /* 26px */
    }

    h5 {
        font-size: 1.1em; /* 22px */
    }
    h6 {
        font-size: 0.9em; /* 18px */
    }
    
    blockquote {
        font-size:1.7em; /* 34px */
        padding-left:1em;
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}