/**********************************************************************************************/
/***** BLOCK: CONTENT IMAGE SECTION ***********************************************************/
/**********************************************************************************************/
.content_section.c_img_section {
    
    &.two_img {
        .img_wrap {
            padding-right:0;
            flex-wrap:wrap;
        }
        
        .img {
            z-index:10;
            
            &.one {
                margin-bottom:32px;
            }
        }
    }
    
    .content_section_inner {
        position:relative;
        padding:0;
    }
    
    .img_wrap {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        height:100%;
        padding: 0 6.42% 0 0;
    }
    
    .img {
        position:relative;
        flex:0 0 auto;
        padding:12em 0;
        align-self:stretch;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        
        &.one {
            flex:0 0 100%;
            
            &.full {
                flex:0 0 100%;
            }
        }
        
        &.two {
            flex:0 0 100%;
            margin-left:0;
        }
        
        .pixels_wrap {
            //z-index:-1;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.c_img_section {
    
        &.two_img {
        
            .img {
                                
                &.one {
                    margin-bottom:0;
                }
            }
        }
        
        .img_wrap {
            padding: 0 4.255% 0 0;
        }
    
        .img {
            
            &.one {
                flex:0 0 41.67%;
            }
        
            &.two {
                flex:0 0 calc(58.33% - 32px);
                margin-left:32px;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}