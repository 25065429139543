/**********************************************************************************************/
/***** GUTENBERG RESETS / STANDARD NAKED BLOCKS ***********************************************/
/**********************************************************************************************/

/* remove top margin from first heading element */
#content {
    .wp-block-columns {
        
        .wp-block-column {
        
            :first-child {
                margin-top:0;
            }
            
            &:not(:first-child) {
                margin-left:5em;
            }
        }
    }
    
    .full-width-block {
        position: relative;
        width: 100vw;
        padding-left: 32px;
        padding-right: 32px;
        margin-left: -50vw;
        left: 50%;
        
        .wp-block-group__inner-container {
            width:calc(83.333% - 64px);
            max-width:calc(1420px - 15.1%);
            //max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
        
        .wp-block-media-text .wp-block-media-text__content {
            padding: 0;
        }
    }
    
    .wp-block-table {
        width:calc(100% - 64px);
        max-width:1420px;
        margin:0 auto;
        
        &.accents {
            
            > table {
                
                &:before {
                    position:absolute;
                    bottom:-55px;
                    left:-55px;
                    width:55px;
                    height:55px;
                    background:$k_kelly;
                    content:'';
                }
                &:after {
                    position:absolute;
                    bottom:-67px;
                    left:-67px;
                    width:12px;
                    height:12px;
                    background:$k_kelly;
                    content:'';
                }
            }
        }
        
        > table {
            width:84.9%;
            margin:2em auto;
            
            &:before,
            &:after {
                display:none;
            }
        }
        
        .single-post &,
        .single-resources &,
        .single-project &,
        .single-award &,
        .single-callout &,
        .single-whitepaper &,
        .single-webinar &,
        .single-podcast &,
        .single-ebook &,
        .page-template-default & {
            background:$whipped_cream_200;
        }
    }
    
    .wp-block-code {
        width:calc(100% - 64px);
        max-width:1420px;
        margin:0 auto;
        
        > code {
            width:84.9%;
            margin:0 auto;
            padding:2em 0;
        }
        
        .single-post &,
        .single-resources &,
        .single-project &,
        .single-award &,
        .single-callout &,
        .single-whitepaper &,
        .single-webinar &,
        .single-podcast &,
        .single-ebook &,
        .page-template-default & {
            background:$whipped_cream_200;
        }
    }
    
    .wp-block-urvanov-syntax-highlighter-code-block {
        width:calc(100% - 64px);
        max-width:1420px;
        margin:0 auto !important;
        padding:3em 0 !important;
        
        > .urvanov-syntax-highlighter-syntax {
            width:84.9%;
            margin:0 auto !important;
            padding:0 !important;
        }
        
        table {
            
            thead tr th:first-child,
            tbody tr td:first-child {
                width: auto;
                min-width: none;
                max-width: none;
                word-break:auto;
                text-align:left;
            }
        }
        
        .single-post &,
        .single-resources &,
        .single-project &,
        .single-award &,
        .single-callout &,
        .single-whitepaper &,
        .single-webinar &,
        .single-podcast &,
        .single-ebook &,
        .page-template-default & {
            background:$whipped_cream_200;
        }
    }
    
}

#content article .entry-content {
    
    > .content_section:last-of-type {
        //margin-bottom:2em;
    }
    
    > :last-child {
        //margin-bottom:2em;
    }
    
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ol,
    > ul,
    > hr,
    > span,
    > a,
    > .wp-block-quote,
    > dl,
    > .wp-block-preformatted {
        width:calc(83.333% - 64px);
        max-width:calc(1420px - 15.1%);
        margin-right:auto;
        margin-left:auto;
        
        .is_legacy & {
            width:100%;
            margin-right:0;
            margin-left:0;
        }
    }
    
    > ol,
    > ul {
        padding-left:20px;
        
        .is_legacy & {
            padding-left:20px;
        }
    }
    
    .page-template-default .is_legacy & {
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a,
        > p a,
        > span a,
        > .wp-block-quote,
        > dl,
        > .wp-block-table,
        > .wp-block-preformatted {
            color: $leafy_green;
        }
        
        > a,
        > p a,
        > span a {
            text-decoration-color:$leafy_green;
            
            &:hover {
                text-decoration-color:$k_kelly;
            }
        }
    }
    
    .page-template-default &,
    .page-template-default .dark &,
    .page-template-default .is_legacy.dark & {
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a,
        > p a,
        > span a,
        > .wp-block-quote,
        > dl,
        > .wp-block-table,
        > .wp-block-preformatted {
            color: $whipped_cream;
        }
        
        > a,
        > p a,
        > span a {
            text-decoration-color:$whipped_cream;
            
            &:hover {
                text-decoration-color:$k_kelly;
            }
        }
    }
    
    > .wp-block-columns {
        display:flex;
        flex-wrap:nowrap;
        width:calc(100% - 64px);
        max-width:1420px;
        margin:0 auto;
        padding:60px 0;
        background:$whipped_cream_200;
        
        .wp-block-column {
            
            &:first-child {
                padding-right:10%;
            }
            
            &:not(:first-child) {
                margin-left:20px;
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #content {
        .wp-block-columns {
            
            
            .wp-block-column {
                flex:0 0 100% !important;
        
                :first-child {
                    margin-top:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
            
            &.reverse_col_mobile {
                
                .wp-block-column {
                    &:last-child {
                        order:-1;
                    }
                }
            }
        }
    }
    
    #content article .entry-content {
    
    
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a,
        > .wp-block-quote,
        > dl,
        > .wp-block-table,
        > .wp-block-preformatted {
            width:calc(100% - 52px);
            margin-right:auto;
            margin-left:auto;
        }
    
        //MAX WIDTH FOR READABILITY, NAKED ELEMENTS
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a {
            padding-right:0;
        }
    
        .wp-block-columns {
            display:block;
            width:calc(100% - 52px);
            padding:0 0 40px 0;
        
            .wp-block-column {
                
            
                &:first-child {
                    padding-right:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #content {
        .wp-block-columns {
            .wp-block-column {
        
                :first-child {
                    margin-top:0;
                }
            
                &:not(:first-child) {
                    margin-left:2em;
                }
            }
        }
    }
    
    #content article .entry-content {
        
        .wp-block-columns {
            display:block;
            width:calc(100% - 64px);
            padding:0 0 60px 0;
        
            .wp-block-column {
                
            
                &:first-child {
                    margin-bottom:40px;
                    padding-right:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}