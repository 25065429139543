/**********************************************************************************************/
/***** BLOCK: TEAM GRID ***********************************************************************/
/**********************************************************************************************/
.content_section.team_grid {
    
    .content_section_inner {
        position:relative;
        padding-bottom:0;
    }
    
    .grid {
        
        &.intro {
            padding:0 0 3em;
            
            h2 {
                margin-top:0;
            }
        }
        
        &.team_wrap {
            padding:0;
        }
    }
    
    .eyebrow {
        @extend h6;
        margin:0 0 1.3em;
        color:$k_kelly !important;
    }
    
    .cta_wrap {
        display:block;
        margin-top:3em;
    }
    
    .cta_cont {
        padding:0;
        
        > :first-child {
            margin-top:0;
        }
        
        .btn {
            margin-top:1em;
        }
    }
    
    .cta_img {
        position:relative;
        margin-right:-26px;
        margin-left:-26px;
        
        img {
            display:block;
            width:100%;
        }
    }
    
    .pixels_wrap {
        z-index:10;
        
        .pixel.five {
            z-index:11;
        }
    }
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.team_grid {
        
        .cta_wrap {
            display:flex;
            justify-content:flex-start;
            align-items:center;
        }
        
        .cta_cont {
            padding:0 8.333% 0 0;
        
            .btn {
                margin-top:1em;
            }
        }
        
        .cta_img {
            margin:0;
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}