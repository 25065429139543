/**********************************************************************************************/
/***** BLOCK: TESTIMONIAL *********************************************************************/
/**********************************************************************************************/
.content_section.testimonial {
    
    
    .content_section_inner {
        position:relative;
        padding-top:7em;
        padding-bottom:7em;
        z-index:10;
        
        .is_legacy & {
            padding-top:4em;
            padding-bottom:4em;
        }
        
        &.has_pixels {
            padding-bottom:9em !important;
        }
    }
    
    //satisfy WCAG accesibility color contrast reqs
    &.whipped_cream_400 {
        
        .test_wrap {
            position:relative;
            padding-right:0;
        
            &:before {
                content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 20'%3e%3cg fill='%230B9239' %3e%3cpolygon points='32 6.5 32 0 25.5 0 25.5 6.4 19.1 6.4 19.1 19.3 32 19.3 32 12.8 25.6 12.8 25.6 6.5'%3e%3c/polygon%3e%3cpolygon points='6.4 6.4 0 6.4 0 19.3 12.9 19.3 12.9 12.8 6.5 12.8 6.5 6.5 12.9 6.5 12.9 0 6.4 0'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
            }
        }
    }
    
    .test_wrap {
        position:relative;
        padding-right:0;
        
        &:before {
            position:absolute;
            left:0;
            top:-3em;
            width:32px;
            height:20px;
            background:none;
            background-size:100% auto;
            content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 20'%3e%3cg fill='%230EB747' %3e%3cpolygon points='32 6.5 32 0 25.5 0 25.5 6.4 19.1 6.4 19.1 19.3 32 19.3 32 12.8 25.6 12.8 25.6 6.5'%3e%3c/polygon%3e%3cpolygon points='6.4 6.4 0 6.4 0 19.3 12.9 19.3 12.9 12.8 6.5 12.8 6.5 6.5 12.9 6.5 12.9 0 6.4 0'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
        }
        
        .is_legacy & {
            margin-left:0;
            padding-right:0;
            width:100%;
        }
    }
    
    .eyebrow {
        @extend h6;
        margin:0 0 2em;
        color:$leafy_green;
    }
    
    blockquote {
        position:relative;
        padding:0;
        border:none;
    
        &:before {
            display:none;
        }
    }
    
    .cite {
        margin:2em 0 0;
        font-size: 1.15em;
        line-height:1.3em;
        font-weight:$light;
    }
    
    .pixels_wrap {
        z-index:-1;
        
        .is_legacy & {
            display:none;
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: #{$bp_small_plus1}) {

    .content_section.testimonial {
        
        .content_section_inner {
            padding-top:6em;
            padding-top:8em;
        }
    
        .test_wrap {
        
            &:before {
                left:calc((8.333% + 32px) * -1);
                top:0;
            }
        }
    }
    
}

@media screen and (min-width: #{$bp_mid_plus1}) {
    
    .content_section.testimonial {
        
        .test_wrap {
            padding-right:40px;
        }
    }
    
}

@media screen and (min-width: #{$bp_full_plus1}) {
    
    
    
}